import React, { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { INoteWithKey } from "../../api/models/note";
import { IUser } from "../../api/models/user";
import { api } from "../../api/prep";
import NotesCard from "../../components/Cards/NotesCard/NotesCard";
import { selectedUserSetAction } from "../../store/actions";
import { UserState } from "../../store/reducers/userReducer";
import { OrNull } from "../../typings";
import "./index.scss";
import Card from "react-bootstrap/Card";
import { Button, Col, InputGroup, Row, Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import send from "../../assets/ic_send.svg";
import { handleFormInputChange } from "../../utils/form";
import firebase from "../../index";
import i18n from "i18next";

interface IDiaryProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

const feedStyles: CSSProperties = {
	flexGrow: 1,
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap",
	flexDirection: "column"
}

const inputStyles: CSSProperties = {
	flexGrow: 1,
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap",
	flexDirection: "column",
	width: "78%",
	marginLeft: "24px"
}

const userDetailsStyles: CSSProperties = {
	display: "flex",
	flex: 1,
	flexDirection: "column"
}

const rightBtnStyle: CSSProperties = {
	border: "none"
}

const Notes: React.ComponentType<IDiaryProps> = (props: IDiaryProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [user, setUser] = useState<OrNull<IUser>>(props.user);
	const [notes, setNotes] = useState<INoteWithKey[]>([]);
	const [newNote, setNewNote] = useState<string>("");
	const [enabled, setEnabled] = useState<boolean>(true);

	useEffect(() => {
		// console.log("props.user:: ", props.user);
		// console.log("useEffect::userId:: ", userId);
		// User might be null if user directly navigated to this url. Query user if that happens.
		if (!props.user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				if (usr) {
					api.getProviderNotes(usr.id).then(res => {
						const sorted = res.sort((a,b) =>  b.updatedAt.getTime() - a.updatedAt.getTime() );
						setNotes(sorted);
					});
				}
			});
		} else {
			api.getProviderNotes(userId).then(res => {
				const sorted = res.sort((a,b) =>  b.updatedAt.getTime() - a.updatedAt.getTime() );
				setNotes(sorted);
			});
		}
	}, []); // eslint-disable-line

	const handleNewNoteInput = handleFormInputChange(setNewNote);

	const saveNote = async () => {
		if (newNote.length > 0 && enabled) {
			const currUser = firebase.auth().currentUser;
			if (userId && currUser && currUser.email) {
				setEnabled(false);
				await api.saveProviderNote(userId, newNote, currUser.email)
					.then(() => {
						console.log("Saved!");
						setEnabled(true);
						setNewNote("");

						// This should just listen for updates but... 3am... effort
						api.getProviderNotes(userId).then(res => {
							const sorted = res.sort((a,b) =>  b.updatedAt.getTime() - a.updatedAt.getTime() );
							setNotes(sorted);
						});
					})
					.catch((e) => {
						setEnabled(true);
						console.error("Error saving provider note:: ", e);
					});
			}
		}
	};

	return(
		<>
			<div className="feedStyles">
				<div id="noteInputCard" style={inputStyles}>
					<Card id="noteInputContainer" body={true}>
						<Row>
							<Col>
							<InputGroup>
								<Form.Control as="textarea" rows={4} placeholder={i18n.t("Type some notes about this patient...")} value={newNote}
									onChange={handleNewNoteInput}
								/>
								<InputGroup.Append>
									<Button className="btnHover" style={rightBtnStyle} onClick={saveNote}><ReactSVG src={send} /></Button>
								</InputGroup.Append>
							</InputGroup>
							</Col>
						</Row>
					</Card>
				</div>
				<div style={feedStyles}>
					{notes && notes.map((note, index) => {
						return !note ? <div > </div> :  <NotesCard note={note} key={index}/>
					})}
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Notes);