export interface Colors {
	primary: string
	primaryLight: string
	primaryDark: string
	secondary: string
	secondaryLight: string
	secondaryDark: string
	gray: string,
	lightPink: string
}

export const ListaColors: Colors = {
	primary: "#1C1F34",
	primaryLight: "#5c6bc0",
	primaryDark: "#26418f",
	secondary: "#ffbdbe",
	secondaryLight: "rgba(102, 187, 106, 0.5)",
	secondaryDark: "#c35b61",
	gray: "#6C757D",
	lightPink: "#ffe5e5"
};