import * as React from "react";
import { ComponentType } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { getSessionAuth, nukeSessionLogout } from "../api/sessions";
import { ROUTES } from "../config/routes";
import firebase from "../index";
import { urlRefSetAction } from "../store/actions";
import { UrlRefState } from "../store/reducers/urlRefReducer";

export interface IProtectedRoute extends RouteProps {
	component: ComponentType
	path: any
	exact: any
	urlRefSetAction: typeof urlRefSetAction
	urlRef: UrlRefState
}


/**
 * Guard main application content from un-authed clients.
 * @param {IProtectedRoute} props
 * @returns {any}
 * @constructor
 */
const ProtectedRoute = (props: IProtectedRoute): JSX.Element => { // eslint-disable-line no-undef
	const { component: ProtectedComponent, ...rest } = props;
	const isLoggedIn: boolean = !!getSessionAuth().token;

	if (!isLoggedIn && !window.location.href.includes("login")) {
		props.urlRefSetAction(window.location.href);
		console.warn("User not logged in, nuking session:: (ProtectedRoute)");
		nukeSessionLogout();
	}

	const renderComponent = (componentProps: any) => {
		if (isLoggedIn) {
			return <ProtectedComponent {...componentProps} />;
		}

		return <Redirect to={ROUTES.LOGIN.path} />;
	};

	return <Route {...rest} render={renderComponent} />;
};

const mapStateToProps = ({ urlRef }) => ({ urlRef });
export default connect(mapStateToProps, {
	urlRefSetAction
})(ProtectedRoute);
