import React, { useState } from "react";
import { Button, Form, Spinner, Modal } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { IUpdate } from "../../../api/models/updates";
import { api } from "../../../api/prep";
import upload from "../../../assets/ic_upload.svg";
import "./index.scss";
import { handleFormInputChange, tagify, toUint8Array } from "../../../utils/form";
import { useEffect } from "react";
import ResourceSection from "../../ResourceSection/ResourceSection";
import i18n from "../../../i18n/config";
import i18next from "i18next";

interface UploadResourceProps {
	type: string,
	show: boolean,
	onHide: () => void
	edit?: IUpdate
}

const UploadResource: React.ComponentType<UploadResourceProps> = (props: UploadResourceProps): JSX.Element => {

	// form input
	const [fileName, setFileName] = useState(<ReactSVG src={upload}/>);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [body, setBody] = useState("");
	const [section, setSection] = useState("");
	// file is the file itself, for media posts, the body will be set to a reference to the file on FB
	const [file, setFile] = useState<File | undefined>(undefined);
	const [icon, setIcon] = useState<File | undefined>(undefined);
	const [tags, setTags] = useState("");
	const [errors, setErrors] = useState({
		title: "",
		file: "",
		body: "",
		description: "",
		section: ""
	});
	const [loading, setLoading] = useState(false);

	const handleTitleInput = handleFormInputChange(setTitle);
	const handleDescriptionInput = handleFormInputChange(setDescription);
	const handleBodyInput = handleFormInputChange(setBody);
	const handleTagsInput = handleFormInputChange(setTags);

	const verb = props.edit ? i18n.t("Edit") : i18n.t("Create")

	const getTitle = (t: string): string => {
		switch (t) {
			case "audio": {
				return verb + i18n.t(" an Audio/Podcast Post");
			}
			case "video": {
				return verb + i18n.t(" a Video Post");
			}
			case "photo": {
				return verb + i18n.t(" an Image Post");
			}
			case "link": {
				return verb + i18n.t(" a Link Post");
			}
			case "text": {
				return verb + i18n.t(" a Text Post");
			}
			default: {
				console.log("User did not select a resource type to upload.");
				return "";
			}
		}
	};

	useEffect(() => {
		if (props.edit) {
			setTitle(props.edit.title);
			setDescription(props.edit.description || "");
			setBody(props.edit.content);
		}
	}, []);

	const validateFields = (type: string): boolean => {
		// title and body are required, others not
		const tempErrors = {
			title: "",
			file: "",
			body: "",
			description: "",
			section: ""
		};
		let isError = false;

		if (!title) {
			tempErrors.title = i18n.t("Title is required.");
			isError = true;
		} 

		if (["photo", "video", "audio"].includes(type) && !file && !props.edit) {
			tempErrors.file = i18n.t("File resource is required.");
			isError = true;
		}

		if (["text", "link"].includes(type) && !body) {
			tempErrors.body = i18n.t("Main content for the resource is required.");
			isError = true;
		}

		if (!description) {
			tempErrors.description = i18n.t("Description for the resource is required.");
			isError = true;
		}

		if (!section) {
			tempErrors.section = i18n.t("Section is required.");
			isError = true;
		}

		setErrors(tempErrors);
		return isError;
	};

	const getInput = (t: string): JSX.Element => {
		switch (t) {
			case "audio":
				return (
					<>
						<Form.Group>
							<Form.Label>{i18n.t("Description")}</Form.Label>
							<Form.Control id="descriptionInput" as="textarea" defaultValue={props.edit ? props.edit.description : ""}
								onChange={handleDescriptionInput} />
							{ errors.description && <p className="error-message"> { errors.description } </p> }
						</Form.Group>
						<Form.Group>
							<Form.Label>{i18n.t("Add Audio file")}  </Form.Label>
							<Form.File id="fileSelect" accept="audio/*,video/*,image/*"
								onClick={() => setBody("")}
								onChange={(e) => {
									setFileName(<span>"{e.target.files[0].name}" uploaded</span>);
									setFile(e.target.files[0])
								}}/>
							{ errors.file && <p className="error-message"> { errors.file } </p> }
						</Form.Group>
					</>
				);
			case "video":
				return (
					<>
						<Form.Group>
							<Form.Label>{i18n.t("Description")}</Form.Label>
							<Form.Control id="descriptionInput" as="textarea" defaultValue={props.edit ? props.edit.description : ""}
														onChange={handleDescriptionInput} />
							{ errors.description && <p className="error-message"> { errors.description } </p> }
						</Form.Group>
						<Form.Group>
							<Form.Label>{i18n.t("Add Video")}</Form.Label>
							<div id="contentUpload">
								<Form.File id="fileSelect" accept="audio/*,video/*,image/*"
									onClick={() => setBody("")}
									onChange={(e) => {
										setFileName(<span>"{e.target.files[0].name}" uploaded</span>);
										setFile(e.target.files[0])
									}}/>
							</div>
							{ errors.file && <p className="error-message"> { errors.file } </p> }
						</Form.Group>
					</>
				);
			case "photo": {
				return (
					<>
						<Form.Group>
							<Form.Label>{i18n.t("Description")}</Form.Label>
							<Form.Control id="descriptionInput" as="textarea" defaultValue={props.edit ? props.edit.description : ""}
														onChange={handleDescriptionInput} />
							{ errors.description && <p className="error-message"> { errors.description } </p> }
						</Form.Group>
						<Form.Group>
							<Form.Label>{ props.edit ?  i18n.t("Replace Media") : i18n.t("Add Media") }</Form.Label>
							<div id="fileUpload">
								<label className="text-center" htmlFor="fileSelect">
									{fileName}
									{
										// TO DO: on upload, reset "fileName"
									}
									<Form.File id="fileSelect" accept="audio/*,video/*,image/*"
										onClick={() => setBody("")}
										onChange={(e) => {
											setFileName(<span>"{e.target.files[0].name}" uploaded</span>);
											setFile(e.target.files[0])
										}}/>
								</label>
							</div>
							{ errors.file && <p className="error-message"> { errors.file } </p> }
							{ file && <Form.Label> Media Preview </Form.Label> }
							{ file && <img className="preview-image" src={URL.createObjectURL(file)} width="250" /> }
						</Form.Group>
					</>
				);
			}
			case "link": {
				return (
					<>
						<Form.Group>
							<Form.Label>{i18n.t("Description")}</Form.Label>
							<Form.Control id="descriptionInput" as="textarea" defaultValue={props.edit ? props.edit.description : ""}
														onChange={handleDescriptionInput} />
							{ errors.description && <p className="error-message"> { errors.description } </p> }
						</Form.Group>
						<Form.Group>
							<Form.Label>{i18n.t("Body")}</Form.Label>
							<div id="contentUpload">
								<Form.Control id="linkUpload" as="textarea" placeholder={i18n.t("Type link here to send to all users")} rows={2}
															defaultValue={props.edit ? props.edit.content : ""} onChange={handleBodyInput}/>
							</div>
							{ errors.body && <p className="error-message"> { errors.body } </p> }
						</Form.Group>
					</>
				);
			}
			case "text": {
				return (
					<>
						<Form.Group>
							<Form.Label>{i18n.t("Description")}</Form.Label>
							<Form.Control id="descriptionInput" as="textarea" defaultValue={props.edit ? props.edit.description : ""}
														onChange={handleDescriptionInput} />
							{ errors.description && <p className="error-message"> { errors.description } </p> }
						</Form.Group>
						<Form.Group>
							<Form.Label>{i18n.t("Body")}</Form.Label>
							<div id="contentUpload">
								<Form.Control id="textUpload" as="textarea" placeholder={i18n.t("Type text here to send to all users")} rows={2}
															defaultValue={props.edit ? props.edit.content : ""} onChange={handleBodyInput} />
							</div>
							{ errors.body && <p className="error-message"> { errors.body } </p> }
						</Form.Group>
					</>

				);
			}
			default: {
				console.log("User did not select a resource type to upload.");
				return <div></div>;
			}
		}
	};

	const onSubmit = () => {
		const isError = validateFields(props.type);
		if (!isError) {
			setLoading(true);
			if (props.edit) {
				api.editUpdate(props.edit.id, props.type, title, body, section, description, icon, file).then(
					() => { setLoading(false); props.onHide(); }
				).catch(
					() => {
						setLoading(false);
					}
				);
			} else {
				api.postUpdate(props.type, title, body, section, description, icon, file).then(
					() => { setLoading(false); props.onHide(); }
				).catch(
					() => {
						setLoading(false);
					}
				);
				// props.onHide();
			}
		}
	}

	const onSectionChange = (sectionId) => {
		setSection(sectionId);
	}

	return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal">
				<Form >
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{getTitle(props.type)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="discoverModal">
							<Form.Group>
								<Form.Label> {i18n.t("Title")} </Form.Label>
								<Form.Control id="titleInput" defaultValue={props.edit ? props.edit.title : ""} onChange={handleTitleInput}/>
								{ errors.title && <p className="error-message"> { errors.title } </p> }
							</Form.Group>
							<hr/>
							<Form.Group>
								<Form.Label> {i18n.t("Select section:")} </Form.Label>
								<ResourceSection sectionId={props.edit ? props.edit.section : ""} onChange={onSectionChange}/>
								{ errors.section && <p className="error-message"> { errors.section } </p> }
							</Form.Group>
							<hr/>
							{getInput(props.type)}
							{ props.type !== "photo" && <Form.Group>
								<Form.Label>{props.edit ? (props.edit.image ? i18n.t("Replace Icon Image") : i18n.t("Add Icon Image")) : i18n.t("Add Icon Image")}</Form.Label>
								<Form.File id="iconSelect" accept="image/*"
									onChange={(e) => {
										setIcon(e.target.files[0])
									}}/>
							</Form.Group> }
					</Modal.Body>
					<Modal.Footer>
						{ loading ? <div id="resourceSpinner">
								<Spinner animation="border" role="status" />
							</div> : <>
								<Button onClick={onSubmit}>{i18n.t("Upload")}</Button>
								<Button onClick={props.onHide} className="cancelBtn">{i18n.t("Cancel")}</Button>
							</> }
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
}

export default UploadResource;