import React, { CSSProperties} from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IClinic } from "../../../api/models/clinics";
import "./index.scss";
import i18n from "../../../i18n/config"
import { ReactSVG } from "react-svg";
import edit from "../../../assets/ic_edit.svg";
import deleteIcon from "../../../assets/ic_delete.svg";
import ManageClinic from "../../Modals/ManageClinic/ManageClinic";



interface IClinicCardProps {
	clinic: IClinic,
    onAction: () => void
    customKey: string,
    refClinicList: IClinic[]
}

export const clinicNameStyles: CSSProperties = {
	fontSize: "16px",
	fontWeight: 500,
	marginBottom: 0
}

export const clinicIdStyle: CSSProperties = {
	color: "rgba(0, 0, 0, 0.6)",
	fontSize: "12.8px"
}

export const clinicCodeStyle: CSSProperties = {
	color: "rgba(0, 0, 0, 0.6)",
	fontWeight: "bold"
}

const disabled: CSSProperties = {
	fontSize: "16pt",
	fontWeight: 500,
	color: "rgb(241, 123, 119)",
	lineHeight: 1,
	/*marginBottom: 0,
	marginTop: 0,*/
	background: "#DDDDDD",
	justifyContent: "flex-start"
}

const left: CSSProperties = {
	alignSelf: "flex-start"
}

const ClinicCard: React.ComponentType<IClinicCardProps> = (props: IClinicCardProps) => {
	const history = useHistory();
	// const [totalAdherence, setTotalAdherence] = useState<number>(0);

    const [editModalShow, setEditModalShow] = React.useState(false);
    const [deleteModalShow, setDeleteModalShow] = React.useState(false);


	const handleClick = (uId: string) => {
		// props.selectedUserSetAction(props.user);
		// history.push(ROUTES.FEED.path.replace(":userId", uId));
	};

	return (
        <>
            <Card key={props.customKey} className={!props.clinic.disabled? "clinicEnabledCard" : "clinicDisabledCard"} onClick={() => handleClick(props.clinic.id)}>
                <Card.Body className="clinic-body px-1">
                    <Container>
                        <Row className="enabled">
                            <Col>
                                {
                                    props.clinic.name ?
                                        <p style={clinicNameStyles}>{props.clinic.name}</p> :
                                        <p style={clinicNameStyles}>{i18n.t("No Set Name")}</p>
                                }
                                <p style={clinicIdStyle} className={props.clinic.disabled ? "disabled-id": ""}>{props.clinic.id}</p>
                                <p style={clinicIdStyle}>{i18n.t("Code")}: <b>{props.clinic.code ? props.clinic.code : i18n.t("Not Set")}</b></p>
                                { props.clinic.disabled && <p className="disabled-label">{i18n.t("Disabled")}</p>}
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
                { !props.clinic.disabled && 
                <>
                    <Card.Footer className="clinic-footer">
                        <div className="crud row">
                            <ReactSVG src={edit} onClick={() => {
                                setEditModalShow(true)
                                console.log("Edit Clinic Modal...")
                            }}/>
                            <ReactSVG src={deleteIcon} onClick={() => {
                                setDeleteModalShow(true)
                                console.log("Delete Clinic Modal...")
                            }}/>
                        </div>
                    </Card.Footer>
                </>
                }
            </Card>
            { editModalShow ? <ManageClinic show={editModalShow} onHide={() => { setEditModalShow(false); props.onAction()}} edit={props.clinic} refClinicList={props.refClinicList}/> : null}
            { deleteModalShow ? <ManageClinic show={deleteModalShow} onHide={() => { setDeleteModalShow(false); props.onAction()}} delete={props.clinic} refClinicList={props.refClinicList}/> : null}
        </>
    )
}

export default connect(null, {})(ClinicCard);
