import React, {useEffect, useState} from "react";
import { Button, Spinner } from "react-bootstrap";

interface SpinnerButtonProps {
	loading: boolean,
    text: string,
    loadingText?: string,
    message?: string,
    patients?: number,
    onClick: () => void
}

const SpinnerButton: React.FC<SpinnerButtonProps> = ({loading, text, loadingText, message, patients, onClick}): JSX.Element => { // eslint-disable-line no-undef
	
    const [buttonText, setButtonText] = useState<string>("");

    function getButtonText(){
        if(loading && loadingText){
            return loadingText
        } else {
            return text
        }
    }

    useEffect(() =>  {
        // Just Render
        setButtonText(getButtonText())
    },[loading])

	return (
		<Button onClick={onClick}  disabled={loading || message?.length === 0 || patients === 0}>
            { loading &&
                <>
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />{" "}
                </> 
            }
            {buttonText}
        </Button>
	);
};

export default SpinnerButton;