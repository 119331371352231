import React, { CSSProperties } from "react";
import { Button } from "react-bootstrap";
import { ListaColors } from "../../styles/colors";

const button: CSSProperties = {
	width: "30%",
	alignSelf: "center",
	marginTop: "2rem",
	backgroundColor: ListaColors.primary,
	borderColor: ListaColors.primaryDark
};

interface PrimaryButtonProps {
	onClick?: () => void;
	style?: CSSProperties;
	className?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({children, onClick, style, className}): JSX.Element => { // eslint-disable-line no-undef
	const mergedStyles = {
		...button,
		...style
	};

	return (
		<Button className={className} style={mergedStyles} onClick={onClick}>
			{children}
		</Button>
	);
};

export default PrimaryButton;