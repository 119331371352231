import { IFeedReminder } from "../../../components/Cards/UserEngagementCard/UserEngagementCard";
import { IReminder } from "../../feedGenerator";
import { IReminderResponse } from "./reminderResponse";

export interface IReminderNotification {
    key: Date
    reminder: IReminder
    response: IReminderResponse | null
    pillsRemaining: number | null
}

export const isReminderNotification = (reminder: IFeedReminder): reminder is IReminderNotification  => {
    return ("response" in reminder);
}