import React, { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IUsage } from "../../api/models/usage";
import { IUser } from "../../api/models/user";
import { api } from "../../api/prep";
import DiaryCard from "../../components/Cards/DiaryCard/DiaryCard";
import UsageCard from "../../components/Cards/UsageCard/UsageCard";
import { userIdStyle, userNameStyles } from "../../components/Cards/UserCard/UserCard";
import { selectedUserSetAction } from "../../store/actions";
import { UserState } from "../../store/reducers/userReducer";
import { OrNull } from "../../typings";
import "./index.scss";

interface IUsageProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

const feedStyles: CSSProperties = {
	flexGrow: 1,
	display: "flex",
	flexWrap: "wrap",
	flexDirection: "column"
}

const userDetailsStyles: CSSProperties = {
	display: "flex",
	flex: 1,
	flexDirection: "column"
}

const Usage: React.ComponentType<IUsageProps> = (props: IUsageProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [user, setUser] = useState<OrNull<IUser>>(props.user);
	const [usages, setUsages] = useState<IUsage[]>([]);

	useEffect(() => {
		// User might be null if user directly navigated to this url. Query user if that happens.
		// console.log("user:: ", user);
		if (!user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// console.log("usr:: ", usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				if (usr) {
					api.getUsages(usr.id).then( res => {
						setUsages(res);
					});
				}
			});
		} else {
			api.getUsages(user.id).then( res => {
				setUsages(res);
			});
		}
	}, []); // eslint-disable-line

	return(
		<div className="feedStyles usage">
			<div style={feedStyles}>
				{usages.map((usage, index) => {
					return usage === undefined ? <></> :  <UsageCard usage={usage} key={index}/>
				})}
			</div>
		</div>
	)
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Usage);