import { Timestamp } from "@firebase/firestore-types";
import { IReminder } from "../reminder";

export interface IRefillReminder {
    id: string
    medicationId: string
    stale: boolean
    type: string
    pickupDate: Timestamp
    pillCount: number
    displayAsCountdown: boolean
    createdAt: Timestamp
    updatedAt: Timestamp
}

export const isRefillReminder = (reminder: IReminder): reminder is IRefillReminder  => {
    return reminder.type === "refill";
}