import firebase from "firebase";
import { Dispatch, SetStateAction } from "react";
import { OrNull } from "../typings";
import Random from "java-random";
import { IPrivateMessageUserEntity, IPrivateUserEntity } from "../api/models/message";
import { IUser } from "../api/models/user";
import { useEffect } from "react";
import { Timestamp } from "@firebase/firestore-types";
import { IReminderResponse } from "../api/models/reminders/reminderResponse";
import { IReminder } from "../api/models/reminder";
import { IMedicationReminder, isMedicationReminder } from "../api/models/reminders/medicationReminder";
import { INoteWithKey } from "../api/models/note";
import { IReminderNotification } from "../api/models/reminders/reminderNotification";
import i18n from "../i18n/config";


export type StateSetter = Dispatch<SetStateAction<any>>;

export const getInputValue = (id: string): OrNull<string> => {
	return (document.querySelector(id) as HTMLInputElement).value;
};

export const getMonthName = (date: Date): string => {
	const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];
	return months[date.getMonth()];
};

export const getChatCardDateTime = (date: Date): string => {
	const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const time = date.toLocaleTimeString(); // ex: 9:54:03 PM
	// This is to strip off the seconds from the string. This way the Date object handles timezones for us.
	const timeString = `${time.substr(0, time.length - 6)} ${time.substr(time.length - 2, time.length)}`;
	return `${months[date.getMonth()]} ${date.getDate()}, ${timeString}`;
}

export const getChatBreakDate = (date: Date): string => {
	const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];
	return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export const normalizeDate = (date: Date): Date => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const compareDatesByDate = (a: Date, b: Date): number => {
	const aFlat = normalizeDate(a);
	const bFlat = normalizeDate(b);

	return bFlat.getMilliseconds() - aFlat.getMilliseconds();
}

export const getDiaryCardDate = (date: Date): string => {
	const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];
	const monthName = months[date.getMonth()]
	return `${monthName} ${date.getDate()}, ${date.getFullYear()}`;
}

export const getDiaryCardDateNumerals = (date: Date): string => {
	const months: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
	const monthName = months[date.getMonth()]
	return `${date.getFullYear()}-${monthName}-${date.getDate()}`;
}

export const getNotesCardDateTime = (date: Date): string => {
	const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];
	const monthName = months[date.getMonth()]
	const time = date.toLocaleTimeString();
	const timeString = `${time.substr(0, time.length - 6)} ${time.substr(time.length - 2, time.length)}`;
	return `${monthName} ${date.getDate()}, ${date.getFullYear()} @ ${timeString}`;
}

// Get day difference between a date and another date, with date1 being most recent date
export const getDayDiff = (date1: Date, date2: Date): number => {
	const differenceInTime = date1?.getTime() - date2?.getTime();

	// Adding one to include current day
	return Math.round(differenceInTime / (1000 * 3600 * 24)) + 1;
}

// Returns numerical identifier of each day of week, Sunday - Saturday
export const getAllDaysOfWeek = (): number[]  => {
	return [0, 1, 2, 3, 4, 5, 6];
}

export const getFeedDateTime = (date: Date): string => {
	const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
	const monthName = months[date.getMonth()]
	const time = date.toLocaleTimeString();
	const timeString = `${time.substr(0, time.length - 6)} ${time.substr(time.length - 2, time.length)}`;
	return `${monthName} ${date.getDate()}, ${date.getFullYear()} ${timeString}`;
}

/**
 * Returns feed data entries grouped by date. 
 */
export const dateGroupBy = (notifications: (INoteWithKey | IReminderNotification)[]) => {
	const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];

	if(notifications !== undefined) {
		return notifications
			.sort((a, b) => {
				return b.key.getTime() - a.key.getTime();
			})
			.reduce((grouping: { [k: string]: any }, item) => {
				if(item !== undefined) {
					const currentToDate = new Date();
					const itemToDate = item?.key;
	
					const itemDate = `${String(months[itemToDate.getMonth()])} ${String(itemToDate.getDate())}, ${String(itemToDate.getFullYear())}`;
					const itemDateShortened = itemDate.substring(0, itemDate.length - 6);
	
					// Create date properties
					if (item.key.getFullYear() === currentToDate.getFullYear()) {
						if (!grouping.hasOwnProperty(itemDateShortened)) {
							grouping[itemDate.substring(0, itemDate.length - 6)] = [];
						}
					} else {
						if (!grouping.hasOwnProperty(itemDate)) {
							grouping[itemDate] = [];
						}
					}
	
					// Push data entries
					if (item.key.getFullYear() !== currentToDate.getFullYear()) {
						grouping[itemDate].push(item);
					} else {
						grouping[itemDateShortened].push(item);
					}
					return grouping;
				}
				return grouping;
		}, {});
	}
	return [];
}

// See if two dates objects match date
export const datesAreSame = (date1: Date, date2: Date): boolean => {
	return  date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate();
}

export const lastSeen = (d: Timestamp): string => {
	const now = new Date().getTime();
	const date = d.toMillis();
	const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];

	const difference = Math.floor((now - date) / (60000));
 
	if (difference < 2) {
		return i18n.t("1 minute ago");
	} else if (difference < 60) {
		return `${difference}` + i18n.t("minutes ago");
	} else if (difference < 120) {
		return i18n.t("an hour ago");
	} else if (difference < 1440) {
		return `${Math.floor(difference / 60)} ` + i18n.t("hours ago");
	} else {
		return `${months[d.toDate().getMonth()]} ${d.toDate().getDate()}, ${d.toDate().getFullYear()}`;
	}
};

/**
 * Returns array of days from today, backwards. If today is the 20th, will return [1...20]
 * @param date: up to date
 */
export const daysUpTo = (date: Date): number[] => {
	const days: number[] = [];
	for (let i = 0; i < date.getDate(); i++) {
		days.push(i + 1);
	}
	return days;
}

/**
 * Returns array of days from last 3 weeks (as in specs) up to today, backwards.
 * @param date: up to date
 * @param setDaysWithMonthNames: function to create another array with the month names
 */
export const lastThreeWeeks = (date: Date, setDaysWithMonthNames?): string[] => {
	const threeWeek = new Date();
	threeWeek.setDate(date.getDate() - 20);
	const days: string[] = [];
	const monthDays: string[] = [];
	const fullDate: string[] = [];

	for (let i = threeWeek; i <= date; threeWeek.setDate(threeWeek.getDate() + 1)) {
		days.push(`${i.getDate()}`);
		if (setDaysWithMonthNames) {
			monthDays.push(`${getMonthName(i)} ${i.getDate()}`)
			fullDate.push(`${getDiaryCardDateNumerals(i)}`)

		}
	}

	if (setDaysWithMonthNames) {
		setDaysWithMonthNames(monthDays);
	}
	// return days;
	return fullDate;

}

export const allTimeAdherence = (date: Date, createdDate: Date, setDaysWithMonthNames?): string[] => {
	const allTime = new Date();
	// const daysSinceJoiningApp = getDayDiff(allTime, createdDate);
	allTime.setDate(date.getDate() + 1);
	const days: string[] = [];
	const monthDays: string[] = [];
	const fullDate: string[] = [];
	for (let i = createdDate; i <= allTime; i.setDate(i.getDate() + 1)) {
		days.push(`${i.getDate()}`);
		if (setDaysWithMonthNames) {
			monthDays.push(`${getMonthName(i)} ${i.getDate()}`)
			fullDate.push(`${getDiaryCardDateNumerals(i)}`)


		}
	}

	if (setDaysWithMonthNames) {
		setDaysWithMonthNames(monthDays);
	}
	// return days;
	return fullDate;
}

//
export const sliceChartData = (dateRange: number, allData: any[], rangeSetter?: StateSetter, fullRangeNames?: string[]) => {
	const rangeNames: any[] = !!fullRangeNames ? fullRangeNames : allData;

	switch (dateRange) {
		case 4: 
			if (!!rangeSetter) {
				rangeSetter(`${String(rangeNames[0])} - ${String(rangeNames[rangeNames.length-1])}`)
			}
			return allData;

		case 3:
			if (!!rangeSetter) {
				rangeSetter(`${String(rangeNames[rangeNames.length-7])} - ${String(rangeNames[rangeNames.length-1])}`)
			}
			return allData.slice(rangeNames.length-7, allData.length);
		case 2:
			if (!!rangeSetter) {
				rangeSetter(`${String(rangeNames[7])} - ${String(rangeNames[13])}`)
			}
			return allData.slice(7,14);
		case 1:
			if (!!rangeSetter) {
				rangeSetter(`${String(rangeNames[rangeNames.length - 14])} - ${String(rangeNames[rangeNames.length-7])}`)
			}
			return allData.slice(rangeNames.length - 14, rangeNames.length-6);
		case 0:
			if (!!rangeSetter) {
				rangeSetter(`${String(rangeNames[rangeNames.length-7])} - ${String(rangeNames[rangeNames.length-1])}`)
			}
			return allData.slice(rangeNames.length-7, rangeNames.length);
		default:
			return allData;
	}
}

export const sliceChartDates = (dateRange: number, fullRangeNames: string[]) => {

	switch (dateRange) {
		case 0:
			return fullRangeNames.slice(14, fullRangeNames.length);
		case 1:
			return fullRangeNames.slice(7,14);
		case 2:
			return fullRangeNames.slice(0,7);
		case 3:
			return fullRangeNames;
		case 4:
			return fullRangeNames;
		default:
			return fullRangeNames;
	}
}

const chartGreen = "#66BB6A";
const chartYellow = "#FFA726";
const chartRed = "#F44336";

// returns the color of a chart
export const chartColors = (adherence: number[]) => {
	const middleThreshold: number = 66;
	const lowThreshold: number = 33;

	const output: string[] = [];
	for (const value of adherence) {
		output.push(value < middleThreshold ? value > lowThreshold ? chartYellow : chartRed : chartGreen)
	}
	return output;
}

/**
 * Hash function that maintains compatibility with Java's String.hashCode function
 * @param string String to compute hash value for
 * @return number Hash of string input
 */
export const hashCode = (string: string): number => {
	let h = 0;
	for (let i = 0; i < string.length; i++) {
		// `| 0` forces result to be a 32bit int
		// eslint-disable-next-line no-bitwise
		h = Math.imul(31, h) + string.charCodeAt(i) | 0;
	}

	return h;
}

export const nameNumber = (firstName: OrNull<string>, lastName: OrNull<string>): number => {
	const first: string = firstName ? firstName : "";
	const last: string = lastName ? lastName : "";
	const fullName = `${first} ${last}`;
	const rng = new Random(hashCode(fullName));
	const nextInt: number = rng.nextInt(899);
	return 100 + nextInt;
}

export const initials = (firstName: OrNull<string>, lastName: OrNull<string>): string => {
	if (firstName && lastName) {
		 return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;
	} else if (firstName) {
		return firstName.substring(0, 2);
	} else if (lastName) {
		return lastName.substring(0, 2);
	} else {
		return "HB"; // default
	}
}

export const getDisplayName = (firstName: OrNull<string>, lastName: OrNull<string>): string => {
	if (!firstName || !lastName) {
		return "N/A"
	}
	return `${initials(firstName, lastName)}${nameNumber(firstName, lastName)}`
}

export const createPrivateUserEntity = (user: IUser): IPrivateUserEntity => {
	const dispName: string = getDisplayName(user.firstName || "", user.lastName || "");
	const pui: IPrivateUserEntity = {
		id: user.id,
		displayName: dispName,
		profileUrl: user.photoUrl ? user.photoUrl : "",
		clinicID: user.clinicID ? user.clinicID : ""
	}
	return pui;
}

export const createMessageUserEntity = (user: IUser, id: string): IPrivateMessageUserEntity => {
	const dispName: string = getDisplayName(user.firstName || "", user.lastName || "");
	const pui: IPrivateMessageUserEntity = {
		id,
		displayName: dispName,
		profileUrl: user.photoUrl ? user.photoUrl : ""
	}
	return pui;
}

export const getByteArray = (file: File): Promise<Uint8Array> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = () => {
			const arrayBuffer = reader.result
			if (arrayBuffer && arrayBuffer instanceof ArrayBuffer) {
				const bytes = new Uint8Array(arrayBuffer);
				resolve(bytes); // successful
			}
		}
		reader.onerror = reject; // call reject if error
	})
}

export const generateThumbnail = (file: File, maxHeight: number = 300): Promise<string> => {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.src = URL.createObjectURL(file);
		image.onload = () => {
			const width = image.width;
			const height = image.height;

			const newWidth = width * (maxHeight / height);
			const newHeight = maxHeight;

			const canvas = document.createElement("canvas");
			canvas.width = newWidth;
			canvas.height = newHeight;

			const context = canvas.getContext("2d");
			context?.drawImage(image, 0, 0, newWidth, newHeight);

			const dataUrl = canvas.toDataURL("image/jpeg");
			resolve(dataUrl.split(",")[1]);
		};
		image.onerror = reject;
	});
}

/* tslint:disable-next-line */
export const flattenObject = (datum) => {
	const flatDatum = {};
	Object.keys(datum).forEach((key) => {
		if (typeof datum[key] === "object" && datum[key] !== null) {
			Object.assign(flatDatum, flattenObject(datum[key]));
		}
		else {
			flatDatum[key] = datum[key];
		}
	})
	return flatDatum;
}

// ##### Key Utils #####

// Given a PKCS8 key, return the same key in PKCS1 format
export const formatRsa = (pkcs8Key: string) => {
	if (pkcs8Key.substring(0, 31) === "-----BEGIN RSA PRIVATE KEY-----") {
		return pkcs8Key;
	}
	return "-----BEGIN RSA PRIVATE KEY-----\n"+
		pkcs8Key.replace(/.{1,64}/g, line => {return line + "\n"}) +
	"-----END RSA PRIVATE KEY-----"
}

export const getCookie = (cookieName: string) => {
	const name = cookieName + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

// convert file to a base64 string
export const encodeImage = async (file: File) => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			if (typeof reader.result === "string") {
				resolve(reader.result);
			}
			reject();
		}
		reader.readAsDataURL(file);
	})
};

// upload image to firebase storage, image should be base64 encoded and encrypted
export const uploadMessageFile = async (file: File, userId: string) => {
	// Create a root reference
	const storageRef = firebase.storage().ref();

	// Create a reference to 'images/mountains.jpg'
	const ref = storageRef.child(`users/${userId}/ask_expert/test.jpg`);
	// const ref = storageRef.child(`users/${userId}/ask_expert/${file.name}`);

	const fileString: string = await encodeImage(file);
	// 'file' comes from the Blob or File API
	return ref.putString(fileString, "data_url");
}

// ##### URL utils #####

export const LOCAL_URL = "localhost:3000/"
export const PROD_URL = "maslista-e0bbf.web.app/"
export const PROD_URL_ALT = "maslista.ehg-tech.com/"

// return the url after the baseUrl
export const getUrlRoute = (url: string) => {
	if (url.includes(LOCAL_URL)) {
		const split = url.split(LOCAL_URL);
		if (split[1]) {
			return split[1];
		}
	}
	else if (url.includes(PROD_URL)) {
		const split = url.split(PROD_URL);
		if (split[1]) {
			return split[1];
		}
	}
	else if (url.includes(PROD_URL_ALT)) {
		const split = url.split(PROD_URL_ALT);
		if (split[1]) {
			return split[1];
		}
	} else {
		const split = url.split(".com");
		if (split[1]) {
			return split[1];
		}
	}
	return "/";
}

// Load a local script
export const useScript = url => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        return () => {
        document.body.removeChild(script);
        };
    }, [url]);
};

// Reminders

export const  countReminderResponsesConfirmed = (prepResponses: IReminderResponse[]): number => {
	let confirmedCount = 0;
	if (prepResponses !== []) {
	  prepResponses.forEach((r) => {
		if (r.status === "confirmed") {
			confirmedCount++;
		}
	  });
	}
	return confirmedCount;
}

export const getPrepReminders = (reminders: IReminder[]): IMedicationReminder[] => {
	const prepReminders: IMedicationReminder[] = [];

	reminders?.forEach((r) => { 
		if(isMedicationReminder(r) && r.medicationId === "default-medication-1") {
			prepReminders.push(r);
		}
	});	

	return prepReminders;
}

export const filterResponsesAfterDate = (responses: IReminderResponse[], daysBack: number): IReminderResponse[] => {
	const today = new Date();

	const date = new Date(today.setDate(today.getDate() - daysBack));
	const basedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

	return responses.sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime())
		.filter((r) => {
			return r.key.toDate().getTime() >= basedDate.getTime();
		});

}

// String functions

export const totitleCase = (s: string | undefined) => {
	if(s !== undefined) {
		return s.charAt(0).toUpperCase() + s.slice(1);
	} else {
		return "User";
	}
}