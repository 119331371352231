import { IUserSetAction } from "../actions";
import { SELECTED_USER_SET } from "../actions/types";
import { createReducer } from "../util";
import { OrNull } from "../../typings";
import { IUser } from "../../api/models/user";


export type UserState = OrNull<IUser>;
export const initialUserState: UserState = null;


/**
 * Sets the value of the currently selected user object.
 * @param {UserState} state
 * @param {IUserSetAction} action
 * @returns {UserState}
 */
const selectedUserSetReducer = (state: UserState, action: IUserSetAction): UserState =>
	action.payload;

/**
 * Main reducer mapping.
 */
export default createReducer(initialUserState, {
	[SELECTED_USER_SET]: selectedUserSetReducer
});