import Chart from "chart.js/dist/chart";
import React, { ComponentType, CSSProperties, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { IAdherence } from "../../../api/models/adherence";
import { IUser } from "../../../api/models/user";
import { ListaColors } from "../../../styles/colors";
import { OrNull } from "../../../typings";
import { allTimeAdherence, chartColors, lastThreeWeeks, sliceChartData, sliceChartDates } from "../../../utils";
import i18n from "../../../i18n/config";

interface IAdherenceCardProps {
	adherence: IAdherence[]
	user: OrNull<IUser>
	height?: string
}

const userEngagementCardStyle: CSSProperties = {
	display: "flex",
	flex: 1
}

const chartPagingStyle: CSSProperties = {
	display: "flex",
	flex: 1,
	justifyContent: "space-between"
}

const generateChart = (ctx, data, chartDays) => {
	// console.log("generateChart::data:: ", data);
	return new Chart(ctx, {
		type: "bar",
		data,
		options: {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					callbacks: {
						label: (tooltipItem, chartData) => {
							return String(tooltipItem.formattedValue).substring(0,5) + "%";
						},
						title: (tooltipItem, chartData) => {
							return chartDays[tooltipItem[0].dataIndex]
						}
					},
					displayColors: false
				}
			},
			title: {
				display: false
			},
			scales: {
				x: {
					grid: {
						color: "rgba(0, 0, 0, 0)"
					},
					display: true,
					ticks: {
						font: {
							family: "-apple-system, BlinkMacSystemFont, sans-serif",
							size: "16",
							weight: "500"
						},
						color: "black"
					}
				},
				y: {
					display: true,
					max: 100,
					min: 0,
					title: {
						display: false,
						text: "Adherence"
					},
					ticks: {
						color: "black",
						font: {
							family: "-apple-system, BlinkMacSystemFont, sans-serif"
						}
					}
				}
			}
		}
	});
};

const AdherenceCard: ComponentType<IAdherenceCardProps> = (props) => {

	const canvasStyles: CSSProperties = {
		position: "relative",
		width: "100%",
		height: props.height ?? "100%",
		overflow: "auto"
	}

	const rangeNames = [i18n.t("This Week"), i18n.t("Last Week"), i18n.t("Two Weeks Ago"), i18n.t("Past Three Weeks"), i18n.t("All Time")];
	const [dateRange, setDateRange] = useState<number>(3);
	const [rangeTitle, setRangeTitle] = useState<string[]>([]);

	const prevPage = () => {
		setDateRange(dateRange > 0 ? dateRange - 1 : rangeNames.length - 1)
	}
	const nextPage = () => {
		setDateRange(dateRange < rangeNames.length - 1 ? dateRange + 1 : 0)
	}

	useEffect(() => {
		// chart.js 3.x.x requires that you delete all previous chart instances before creating new ones.
		Chart.helpers.each(Chart.instances, (instance) => {
			instance.destroy();
		});

		if(rangeNames[dateRange] == "All Time") {
			const today: Date = new Date();
			let createdAt: Date = new Date();

			if(props.user != null && props.user.createdAt != null){
				createdAt = new Date(props.user.createdAt.seconds * 1000);
			}
			
			let fullDateRange: string[] = [];
			const setFullDateRange = (newRange: string[]) => {
				fullDateRange = newRange;
			}

			const allDays = allTimeAdherence(today, createdAt, setFullDateRange);

			const canvas = document.querySelector("#adherenceChart") as HTMLCanvasElement;
			const ctx: OrNull<CanvasRenderingContext2D> = canvas.getContext("2d");
			const forward: number[] = [];

			props.adherence.forEach((w) => {
				forward.push(w.adherencePercentage);
			});

			const chartData = forward.reverse();
			console.log("chartData:: ", chartData);

			const days = sliceChartData(dateRange, allDays, setRangeTitle, fullDateRange);
			const slicedData = sliceChartData(dateRange, chartData);
			const slicedDates = sliceChartDates(dateRange, fullDateRange);

			const data = {
				labels: days,
				datasets: [{
					label: "Adherence",
					backgroundColor: chartColors(slicedData),
					borderColor: ListaColors.secondaryDark,
					borderRadius: 8,
					borderSkipped: "bottom",
					data: slicedData
				}]
			};

			if (ctx && props.adherence && props.user) {
				generateChart(ctx, data, slicedDates);
			}

		} else {
			let fullDateRange: string[] = [];
			const setFullDateRange = (newRange: string[]) => {
				fullDateRange = newRange;
			}

			const today: Date = new Date();
			const allDays = lastThreeWeeks(today, setFullDateRange);

			const canvas = document.querySelector("#adherenceChart") as HTMLCanvasElement;
			const ctx: OrNull<CanvasRenderingContext2D> = canvas.getContext("2d");
			const forward: number[] = [];

			props.adherence.forEach((w) => {
				forward.push(w.adherencePercentage);
			});

			const chartData = forward.reverse();
			console.log("chartData:: ", chartData);

			const days = sliceChartData(dateRange, allDays, setRangeTitle, fullDateRange);
			const slicedData = sliceChartData(dateRange, chartData);
			const slicedDates = sliceChartDates(dateRange, fullDateRange);

			const data = {
				labels: days,
				datasets: [{
					label: "Adherence",
					backgroundColor: chartColors(slicedData),
					borderColor: ListaColors.secondaryDark,
					borderRadius: 8,
					borderSkipped: "bottom",
					data: slicedData
				}]
			};

			if (ctx && props.adherence && props.user) {
				generateChart(ctx, data, slicedDates);
			}

		}

		

		
	}, [props.user, props.adherence, dateRange]);

	// TODO: This needs to list the events off the user
	return (
		<Card id="userAdherence" className={"shadow-sm"} body={true} style={userEngagementCardStyle}>
			<div id="chart-paging" style={chartPagingStyle}>
				<span>
					{rangeTitle}
				</span>
				<Pagination>
					<Pagination.Prev onClick={prevPage}/>
					<Pagination.Item>{rangeNames[dateRange] || dateRange}</Pagination.Item>
					<Pagination.Next onClick={nextPage}/>
				</Pagination>
			</div>
			<div style={canvasStyles}>
				<canvas id="adherenceChart" />
			</div>
		</Card>
	);
};

export default AdherenceCard;