import { IPublicUserSetAction } from "../actions";
import { PUBLIC_USER_SET } from "../actions/types";
import { createReducer } from "../util";
import { OrNull } from "../../typings";
import { IPrivateUserEntity } from "../../api/models/message";


export type PublicUserState = OrNull<IPrivateUserEntity>;
export const initialPublicUserState: PublicUserState = null;


/**
 * Sets the value of the currently logged in user.
 * @param {PublicUserState} state
 * @param {IPublicUserSetAction} action
 * @returns {PublicUserState}
 */
const publicUserEntitySetReducer = (state: PublicUserState, action: IPublicUserSetAction): PublicUserState =>
	action.payload;

/**
 * Main reducer mapping.
 */
export default createReducer(initialPublicUserState, {
	[PUBLIC_USER_SET]:  publicUserEntitySetReducer
});