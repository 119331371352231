import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Spinner, Col} from "react-bootstrap";
import { api } from "../../api/prep";
import { IClinic } from "../../api/models/clinics";
import "./index.scss";
import ClinicCard from "../../components/Cards/ClinicCard/ClinicCard";
import ManageClinic from "../../components/Modals/ManageClinic/ManageClinic";
import i18n from "../../i18n/config";

const Clinics: React.ComponentType = (): JSX.Element => {
    const [clinics, setClinics] = useState<any[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loadClinics = () =>{
        api.getClinics()
            .then((currClinics: IClinic[])=>{
                setClinics(currClinics)
                setLoading(false)
            }).catch((error) => {
				console.error(i18n.t("Error getting clinics "), error);
			});
    }
    useEffect(() =>  {
        setLoading(true);
        loadClinics();
	}, []);

    return (
        <>
            <div className="clinicContainer">
				<Card body={true} className="clinicPageHeaderCardStyle">
                    <div className="modal-wrapper">
                        <div className="item">
							<button className="btn btn-outline-primary rounded-sm" onClick={() => {
								setModalShow(true);
							}}>{i18n.t("Create Clinic")} </button>
						</div>
                    </div>
                </Card>
                <div className="clinicStyles">
                    <div id="clinicSpinner" hidden={!loading}>
                        <Spinner animation="border" role="status" />
                    </div>
                    <Row>
                    {clinics.slice(0).map((clinic, index) => {
                        if (clinic) {
                            return (
                            <>
                                <Col md={6}>
                                    <ClinicCard customKey={clinic.id} clinic={clinic} refClinicList={clinics} onAction={()=>{loadClinics();}} />
                                </Col>   
                            </>);
                        }
                        return <div key={index}> </div>;
                    })}
                    </Row>
                </div>
                { modalShow && <ManageClinic show={modalShow} onHide={() => { setModalShow(false); loadClinics();}} refClinicList={clinics} /> }
            </div>
        </>
    );
}

export default Clinics;