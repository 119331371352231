import React, { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { api } from "../../api/prep";
import { Spinner } from "react-bootstrap";
import { SideNavItem } from "../../config/navigation";
import { UserState } from "../../store/reducers/userReducer";
import DisableUser from "../Modals/DisableUser/DisableUser";
import ErrorMessageDisableUser from "../Modals/ErrorDisableUser/ErrorMessageDisableUser";
import LoadingModal from "../Modals/Loading/LoadingModal";
import "./index.scss"

import DeleteUser from "../Modals/DeleteUser/DeleteUser";

import { hookstate, useHookstate, useState as useGlobalState } from "@hookstate/core";
import globalState from "../../utils/globalState";
import i18next from "i18next";
import { PublicUserState } from "../../store/reducers/publicUserReducer";

interface ISideNavProps {
	items: SideNavItem[],
	path: string,
	user: UserState,
	publicUser: PublicUserState
}

/**
 * Branded header component.
 * @returns JSX.Element
 * @constructor
 */
export const SideNav: React.ComponentType<ISideNavProps> = (props: ISideNavProps) => {
	const history = useHistory();

	// const global = useGlobalState(globalState);
	// const {lang} = global.get();
	// const [test, setTest] = useState<string>("es");

	const [modalShow, setModalShow] = React.useState(false);

	const [loading, setLoading] = useState<boolean>(false);

	const [disableError, setDisableError] = React.useState(false);

	const [loadingModal, setLoadingModal] = React.useState(false);

	const [userDisabled, setUserDisabled] = React.useState(false);

	const [modalDeleteShow, setModalDeleteShow] = React.useState(false);


	// useEffect(() => {
	// 	console.log("loading!")
	// 	setTest(lang);
	// }, [loading, lang, test])

	const navigate = (title: string, path?: string, onClick?: ((clinicID?) => Promise<any>)) => {
		setLoading(true);
		if (!!path) {
			if (path.includes(":userId") && props.user) {
				path = path.replace(":userId", props.user.id);
				setLoading(false);
			}
			history.push(path);
		} else if (!!onClick) {
			if(props.publicUser?.role === "super_admin"){
				onClick("")
			} else{
				onClick(props.publicUser?.clinicID);
			} 
			setLoading(false)
		}
	};

	const  disableUser = () => {
		if (props.user && props.user.id) {
			setModalShow(false)
			setLoadingModal(true);
			api.disableUser(props.user.id, setDisableError, setLoadingModal, setUserDisabled)
			const timer = setTimeout(() => {
				history.push("/");
				console.log("The action happenend.");
			}, 3000);   			
			return () => clearTimeout(timer);
		}
		return () => console.log("No user path available, could not disable user.")
	};

	function isUserDisabled() {

		if (props.user?.enabled != undefined) {
			if (props.user.enabled == false) {
				return true;
			}
		}

		return false;

	}

	const deleteUser = () => {
		if (props.user && props.user.id) {
			setModalDeleteShow(false)
			history.push("/");
			api.disableUser(props.user.id, setDisableError, setLoadingModal, setUserDisabled);
			return api.deleteUser(props.user.id);
		}
		return () => console.log("No user path available, could not disable user.")
	};

	return (
		<div className="sideNavContainer">
			{props.items.map((item, index) => {
				// handle disable user
				if ((item.title === "Disable User" || item.title === "Deshabilitar usuario") && item.color) {
					if(isUserDisabled()) {
						const style = {display: "none"} as CSSProperties;
						return <div style={style} className="sideNavItem" key={index} onClick={() => setModalShow(true) /*prev: disableUser*/ }>{item.icon} <p className="sideNavText">{item.title}</p></div>
					} else {
						const style = {color:`${item.color}`, fill:`${item.color}`, borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px", borderTop: "1px solid rgba(0, 0, 0, 0.12)", marginTop: "14px", paddingBottom: "2px"} as CSSProperties;
						return <div style={style} className="sideNavItem" key={index} onClick={() => setModalShow(true) /*prev: disableUser*/ }>{item.icon} <p className="sideNavText">{item.title}</p></div>
					}
				}
				if(item.title === "Delete User" || item.title === "Eliminar Usuario" ) {
					const style = {color:"#B00020", fill:"#B00020"} as CSSProperties;
					return <div style={style} className="sideNavItem" onClick={() => setModalDeleteShow(true)}> {item.icon} <p className="sideNavText">{item.title}</p></div>

				}
				if (item.title === "Sign out" && item.color) {
					const style = {color:`${item.color}`, fill:`${item.color}`, borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px"} as CSSProperties;
					return <div style={style} className="sideNavItem" key={index} onClick={() => navigate(item.title, item.path, item.onClick)}>{item.icon} <p className="sideNavText">{item.title}</p></div>
				}
				// handle colored text
				if (item.color) {
					const style = {color:`${item.color}`, fill:`${item.color}`} as CSSProperties;
					return <div style={style} className="sideNavItem" key={index} onClick={() => navigate(item.title, item.path, item.onClick)}>{item.icon} <p className="sideNavText">{item.title}</p></div>
				}
				// handle highlighted current tab
				if (item.path === props.path) {
					const style = {color:"#7076c9", fill:"#7076c9", backgroundColor:"#e7e8f6"} as CSSProperties;
					if (item.title === "Adherence" || item.title === "Users") {
						style.borderTopLeftRadius = "16px";
						style.borderTopRightRadius = "16px";
					}
					return <div style={style} className="sideNavItem" key={index} onClick={() => navigate(item.title, item.path, item.onClick)}>{item.icon} <p className="sideNavText">{item.title}</p></div>
				}
				// handle top rounded corners on Adherence
				if (item.title === "Adherence" ||  item.title === "Users") {
					return <div className="sideNavItem roundedBordersTop" key={index} onClick={() => navigate(item.title, item.path, item.onClick)}>{item.icon} <p className="sideNavText">{item.title}</p></div>
				}

				if (item.title === "Export All Data") {
					return <div className="sideNavItem" key={index} onClick={() => navigate(item.title, item.path, item.onClick)}>
						{item.icon}
						<p className="sideNavText">{item.title}</p>
						<div className="sidebar-action-spinner" hidden={!loading[item.title]}>
							<Spinner animation="border" role="status" size="sm" />
						</div>
					</div>
				}

				return !item.roles?.length || item.roles.includes(props.publicUser?.role || "") ? (<div className="sideNavItem" key={index} onClick={() => navigate(item.title, item.path, item.onClick)}>{item.icon} <p className="sideNavText">{item.title}</p></div>): (null)
			})}

			<DisableUser show={modalShow} onHide={() => setModalShow(false)} disable={disableUser} />
			
			<ErrorMessageDisableUser show={disableError} onHide={() => setDisableError(false)}/>

			<LoadingModal show={loadingModal}/>

			<DeleteUser show={modalDeleteShow} onHide={() => {setModalDeleteShow(false)}} delete={deleteUser} />

		</div>
	);
};


const mapStateToProps = ({ user, publicUser }) => ({ user, publicUser });
export default connect(mapStateToProps)(SideNav);