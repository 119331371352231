import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import { api } from "../../../api/prep";
import i18n from "../../../i18n/config";

const paragraphStyles = {
    padding: 0,
    margin: 0
}

const headerStyles = { //
    paddingBottom: "9px",
    fontSize: "20px",
    lineHeight: "24px"
}

const cancelStyles = {
    background: "#ffffff",
    borderRadius: "none",
    border: "none",
    color: "#6C757D",
    paddingLeft: 0,
    paddingRight: 0
}

const disableStyles = {
    background: "#ffffff",
    borderRadius: "none",
    border: "none",
    color: "#DC3545",
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: "16px"
}

const DeleteUser = (props): JSX.Element => {

    // const delUser = (id: string) => {
    // 	// console.log("id::", props.resource);
    // 	api.deleteUser(id).then(res => {
	// 			// console.log(`The following resource has been deleted: ${JSON.stringify(props.resource)}`);
	// 			props.onHide();
	// 		});

    // }
    
    return (
        <div id="dis-user">
            <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header style={headerStyles} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {i18n.t("Delete User?")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={paragraphStyles}>
                {i18n.t("Doing this will permanently delete this user from the platform. Are you sure you want to do this?")}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.delete} style={disableStyles}>{i18n.t("Delete User")}</Button>
                <Button onClick={props.onHide} style={cancelStyles}>Cancel</Button>
            </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteUser;