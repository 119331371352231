// Admin
export const ADMIN_SET: string = "ADMIN_SET";

// Auth
export const AUTH_SET: string = "AUTH_SET";

//Lang
export const LANG_SET: string = "LANG_SET";

// Error
export const ERROR_LOGIN_SET: string = "ERROR_LOGIN_SET";

// URL Ref
export const URL_REF_SET: string = "URL_REF_SET";

// User
export const SELECTED_USER_SET: string = "SELECTED_USER_SET";
export const PUBLIC_USER_SET: string = "PUBLIC_USER_SET";


