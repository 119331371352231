import { ILangPayload } from "../actions";
import {LANG_SET} from "../actions/types";
import { createReducer } from "../index";


export interface ILangState {
	lang: string
}

export const initialLangState: ILangState = {
	lang: "en"
};

/**
 * Handle setting lang state.
 * @param {IAuthState} state
 * @param {IAuthPayload} action
 * @returns {IAuthState}
 */
const langSetReducer = (state: ILangState, action: ILangPayload): ILangState => action.payload;


/**
 * Main reducer mapping.
 */
export default createReducer(initialLangState, {
	[LANG_SET]: langSetReducer
});