import React, { ComponentType, CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import { IDiary } from "../../../api/models/diary";
import calendar from "../../../assets/ic_calendar.svg";
import clipboard from "../../../assets/ic_clipboard.svg";
import paper from "../../../assets/ic_paper.svg";
import { getDiaryCardDate } from "../../../utils";
import "./index.scss";
import i18n from "../../../i18n/config";

interface IUpdateCardProps {
	diary: IDiary
}

const imageStyle: CSSProperties = {
	maxWidth: "inherit",

}

function translateSecondaryEffects(effects: string[]){
	let translatedEffects : string[] = [];

	effects.forEach((effect) => translatedEffects.push(i18n.t(effect)))

	return translatedEffects;
}

const DiaryCard: ComponentType<IUpdateCardProps> = (props) => {

	const date = props.diary.createdAt.toDate()

	const secondaryEffects = (): string => {
		if (props.diary.secondaryEffectsIds && props.diary.secondaryEffectsIds.length > 0) {
			return translateSecondaryEffects(props.diary.secondaryEffectsIds).join(", ");
		}
		return "No secondary effects listed";
	}

	// TODO: This needs to list the events off the user
	return (
		<>
		<Card id="update" className="displayCardContainer" body={true}>
			<Row>
				<Col xs={"auto"}>
					<ReactSVG src={calendar} className="calendar"/>
					<ReactSVG src={clipboard} className="clipboard"/>
					<ReactSVG src={paper} className="paper"/>
				</Col>
				<Col className="align-self-center update-content diary-box">
					<h5>{getDiaryCardDate(date)}</h5>
					{props.diary.customSecondaryEffect && <h5>{props.diary.customSecondaryEffect}</h5>}
					<h5>{props.diary.text ? props.diary.text : i18n.t("No description.")}</h5>
					<p>{secondaryEffects()}</p>
				</Col>
			</Row>
		</Card>
		</>
	);
};

export default DiaryCard;