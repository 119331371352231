import { Timestamp } from "@firebase/firestore-types";

export interface IMedicationReminder {
    id: string
    medicationId: string
    type: string
    schedule: string
    messageType: string
    message: string
    followUpIntervalInMinutes: number
    followUpCount: number
    followUpMessage: string
    createdAt: Timestamp
    updatedAt: Timestamp
}

export const isMedicationReminder = (reminder: any): reminder is IMedicationReminder  => {
    return reminder.type === "medication";
}