import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Spinner, Col} from "react-bootstrap";
import { api } from "../../api/prep";
import "./index.scss";
import i18n from "../../i18n/config";
import ManageGroups from "../../components/Modals/ManageGroups/ManageGroups";
import { IGroupMessages } from "../../api/models/groupMessages";
import GroupMessagesCard from "../../components/Cards/GroupMessagesCard/GroupMessagesCard";
import { PublicUserState } from "../../store/reducers/publicUserReducer";
import { connect } from "react-redux";

interface IGroupsProps {
	publicUser: PublicUserState,
}

const Groups: React.ComponentType<IGroupsProps> = (props: IGroupsProps): JSX.Element => {
    const [groupMessages, setGroupMessages] = useState<any[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loadClinics = () =>{
        api.getGroupMessages()
            .then((currGroupMessages: IGroupMessages[])=>{
                if(props.publicUser?.role === "clinic_admin"){
                    for(let i = 0; i < currGroupMessages.length; i++){
                        if(currGroupMessages[i].clinicId === props.publicUser?.clinicID){
                            groupMessages.push(currGroupMessages[i]);
                        }
                    }
                    setLoading(false)
                } else {
                    setGroupMessages(currGroupMessages)
                    setLoading(false)
                }
            }).catch((error) => {
				console.error(i18n.t("Error getting clinics "), error);
			});
    }
    useEffect(() =>  {
        setLoading(true);
        loadClinics();
	}, []);

    const sortMessagesByRecentAscending = (a, b): number => {
        const lastA = a.createdAt;
        const lastB = b.createdAt;
        
        if (lastA < lastB) {
            return 1;
        }
        if (lastA > lastB) {
            return -1;
        }
        return 0;
    };

    return (
        <>
            <div className="clinicContainer">
				<Card body={true} className="groupMessagesPageHeaderCardStyle">
                    <div className="modal-wrapper">
                        <div className="item">
							<button className="btn btn-outline-primary rounded-sm" onClick={() => {
								setModalShow(true);
							}}>{i18n.t("Send a Group Message")} </button>
						</div>
                    </div>
                </Card>
                <div className="clinicStyles">
                    <div id="clinicSpinner" hidden={!loading}>
                        <Spinner animation="border" role="status" />
                    </div>
                    <Row>
                    {groupMessages.sort(sortMessagesByRecentAscending).slice(0).map((groupMessage, index) => {
                        if (groupMessage) {
                            return (
                            <>
                                <Row>
                                    <GroupMessagesCard customKey={groupMessage.id} groupMessage={groupMessage} refClinicList={groupMessages} message={groupMessage.message}
                                    patientList={groupMessage.patientList} gender={groupMessage.gender} adherence={groupMessage.adherence} 
                                    createdAt={groupMessage.createdAt} createdBy={groupMessage.createdBy} status = {groupMessage.status}
                                    adminName = {groupMessage.adminName} onAction={()=>{loadClinics();}} />
                                </Row>   
                            </>);
                        }
                        return <div key={index}> </div>;
                    })}
                    </Row>
                </div>
                { modalShow && <ManageGroups show={modalShow} onHide={() => { setModalShow(false);}}/> }
            </div>
            
        </>
    );
}

// export default Groups;

const mapStateToProps = ({ publicUser }) => ({ publicUser });
export default connect(mapStateToProps, {})(Groups);


