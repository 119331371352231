import * as React from "react";
import {Redirect, Route, RouteProps} from "react-router"
import { PublicUserState } from "../store/reducers/publicUserReducer";
import { ROUTES } from "../config/routes";
import { connect } from "react-redux";
import { UserState } from "../store/reducers/userReducer";


export interface IRoleRoute extends RouteProps {
	path: any
	exact: any
    roles: string[]
	publicUser: PublicUserState
    user: UserState
    children: any
}
const userIsAllowed = (userClinicId, publicUserClinicId, url) => {
    if(url.match(/(\/users\/)/g)){
        if(userClinicId && userClinicId === publicUserClinicId){
            return true
        }else{
            return false
        }
    }else{
        return true
    }
}


const RoleRoute = (props: IRoleRoute): JSX.Element => { // eslint-disable-line no-undef
    console.log("Inside of RoleRoute: ", props.publicUser?.role)
    return (!props.roles.length || 
            props.roles.includes(props.publicUser?.role || "")) &&
            (props.publicUser?.role !== "clinic_admin" || userIsAllowed(props.user?.clinicID, props.publicUser.clinicID, window.location.pathname)) ? (
        <Route {...props} > {props.children} </Route>
    ) : (
        <Redirect to={ROUTES.MAIN.path}/>
    )
};

const mapStateToProps = ({publicUser, user}) => ({publicUser, user})
export default connect(mapStateToProps,{})(RoleRoute);