import React, { CSSProperties } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { IMessage } from "../../../api/models/message";
import { getChatCardDateTime } from "../../../utils";
import { chatCardStyle, chatDateStyle } from "../ChatCard/ChatCard";


interface IChatImageCardProps {
	message: IMessage,
	isStaffMessage: boolean
	isActiveStaffMessage: boolean
}

const imageStyle: CSSProperties = {
	borderRadius: "16px",
	height: "-webkit-fill-available",
	maxHeight: "300px"
}

const zeroPadding: CSSProperties = {
	padding: 0
}

const leftMarginOffset: CSSProperties = {
	marginLeft: "15px",
	maxHeight: "300px",
	marginBottom: "24px"
}

const rightMarginOffset: CSSProperties = {
	marginRight: "15px",
	maxHeight: "300px",
	marginBottom: "24px"
}

const ChatImageCard: React.ComponentType<IChatImageCardProps> = (props: IChatImageCardProps): JSX.Element => { // eslint-disable-line no-undef

	const handleClick = () => {
		// TODO: I think message.content is actually a link to a base64 encoded array of bits? So this needs to piece
		//  the bit array back into an image, base64 that then window.open() will let them open the image
		window.open(`data:image/*;base64,${props.message.content}`);
	}

	const leftCard = (): JSX.Element => { // eslint-disable-line no-undef
		return (
			<Container onClick={handleClick} style={leftMarginOffset}>
				<Row>
					<Card style={chatCardStyle}>
						<Container style={zeroPadding}>
							<Row>
								<Col style={zeroPadding} className="align-self-center">
									<img className="clickable" style={imageStyle} alt="user submitted image" src={`data:image/*;base64,${props.message.thumbnail ?? ""}`} />
								</Col>
							</Row>
						</Container>
					</Card>
				</Row>
				<Row style={chatDateStyle}>
					{getChatCardDateTime(props.message.createdAt.toDate())}
				</Row>
			</Container>
		)
	};

	const rightCard = (): JSX.Element => { // eslint-disable-line no-undef
		return (
			<Container onClick={handleClick} style={rightMarginOffset}>
				<Row>
					<Card style={chatCardStyle}>
						<Container style={zeroPadding}>
							<Row>
								<Col style={zeroPadding}>
									<img className="clickable" style={imageStyle} alt="user submitted image" src={`data:image/*;base64,${props.message.thumbnail ?? ""}`} />
								</Col>
							</Row>
						</Container>
					</Card>
				</Row>
				<Row className="justify-content-end" style={chatDateStyle}>
					{getChatCardDateTime(props.message.createdAt.toDate())}
				</Row>
			</Container>
		)
	};

	return props.isStaffMessage ? rightCard() : leftCard();
}

export default ChatImageCard;