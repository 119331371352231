import React, { CSSProperties, useEffect, useState } from "react";
import { IAdherence } from "../../api/models/adherence";
import { IUser } from "../../api/models/user";
import { api } from "../../api/prep";
import AdherenceCard from "../../components/Cards/AdherenceCard/AdherenceCard";
import { UserState } from "../../store/reducers/userReducer";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { OrNull } from "../../typings";
import "./index.scss";
import { selectedUserSetAction } from "../../store/actions";
import TotalAdherence from "../../components/TotalAdherence/TotalAdherence";
import Card from "react-bootstrap/Card";
import i18n from "../../i18n/config";
import ProfileCard from "../../components/Cards/ProfileCard/ProfileCard";

interface IProfileProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

const Profile: React.ComponentType<IProfileProps> = (props: IProfileProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [user, setUser] = useState<OrNull<IUser>>(props.user);
	const [userData, setUserData] = useState<IUser[]>([]);

	useEffect(() => {
		// User might be null if user directly navigated to this url. Query user if that happens.
		if (!props.user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				
			});
		} 
	}, []); // eslint-disable-line

	return(
		<div className="adherenceStyles">
            <ProfileCard 
            user={user}
            bigText={true}
            />
		</div>
	)
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Profile);