import React, {CSSProperties, useEffect, useState} from "react";
import { Img } from "react-image";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { IUser } from "../../api/models/user";
import arrow from "../../assets/header_arrow.svg";
import logo from "../../assets/logo.png";
import app_logo from "../../assets/app-logo.png";
import { ListaColors } from "../../styles/colors";
import { APP_NAME } from "../../utils/globals";
import "./index.scss";
import i18n from "../../i18n/config";
import { langSet } from "../../store/actions";
import { ILangState } from "../../store/reducers/langReducer";
import {store} from "../../store";
// import {globalState} from "../../App";
import {useState as useGlobalState} from "@hookstate/core";
import globalState from "../../utils/globalState";


interface IHeaderProps {
	isUser: boolean
	user: IUser
	// lang: ILangState
	// langSet: typeof langSet
}

const headerStyle: CSSProperties = {
	width: "100%",
	height: "64px",
	backgroundColor: ListaColors.primary,
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.075)",
	top: "0",
	left: "0",
	display: "flex",
	alignItems: "center"
};

const logoContainerStyle: CSSProperties = {
	display: "flex",
	alignItems: "center",
	width: "100%"
}

const logoStyle: CSSProperties = {
	height: "54px",
	marginLeft: "0.5rem"
};

const arrowContainerStyle: CSSProperties = {
	display: "flex",
	width: "100%"
}

const arrowStyle: CSSProperties = {
	marginRight: "1rem",
	marginLeft: "1rem",
	cursor: "pointer"
};

const logoText: CSSProperties = {
	fontSize: "20px",
	color: "#FFFFFF",
	margin: "0 0.5rem"
}

const nameStyle: CSSProperties = {
	fontWeight: "bold"
}

const languageSwitcher: CSSProperties = {
	marginLeft: "auto",
	// padding: "0.5rem",
	display: "flex"
}

/**
 * Branded header component.
 * @returns JSX.Element
 * @constructor
 */
export const Header: React.ComponentType<IHeaderProps> = (props: IHeaderProps) => {
	const history = useHistory();
	const name = props.user ? `${String(props.user.firstName)} ${String(props.user.lastName)}` : i18n.t("App User");
	const userId = props.user ? ` - ${props.user.id}` : "N/A";
	// const state = useHookstate(globalState);
	const global = useGlobalState(globalState);
	const [test, setTest] = useState<string>("es")
	var disabled = "";

	const changeLang = (lng) => {
		// i18n.changeLanguage(lng).then();
		// props.langSet(lng);
		// console.log(lng);

		// langSet(lng);
		// store.dispatch(langSet(lng));
		// global.lang.set(lng);
		// setTest(lng);

		// i18n.changeLanguage(lng).then();


		// appLang = lng;

		// state.set(lng);

	}

	// useEffect(() =>  console.log("test"), [test]);

	if(props.user != undefined) {
		if (props.user.enabled != undefined) {
			disabled = props.user.enabled ? "" : i18n.t("(User Disabled)");
		} 
	}

	const navToHome = () => {history.push("/")};

	return (
		<div id="header" style={headerStyle}>
			{!props.isUser && <div id="planViewLogo" style={logoContainerStyle}>
				<Img src={app_logo} style={logoStyle}/>
				<p style={logoText}>{APP_NAME}</p>
				{/*<div id="languageSwitch" style={languageSwitcher}>*/}
				{/*	<div>*/}
				{/*		<span style={logoText}>{i18n.t("Language")}: </span>*/}
				{/*	</div>*/}
				{/*	<button style={logoText} onClick={() => changeLang("es")}>es</button>*/}
				{/*	<span style={logoText}> | </span>*/}
				{/*	<button style={logoText} onClick={() => changeLang("en")}>en</button>*/}
				{/*</div>*/}
			</div>}

			{
				props.isUser && <div>
					<div id="planViewLogo" style={arrowContainerStyle}>
						<ReactSVG src={arrow} style={arrowStyle} onClick={navToHome}/>
						<p style={logoText}>
							<span style={nameStyle}>{name}</span>
							{userId}
							<span>{disabled}</span>
						</p>
						{/*<div id="languageSwitch" style={languageSwitcher}>*/}
						{/*	<div>*/}
						{/*		<span style={logoText}>Language: </span>*/}
						{/*	</div>*/}
						{/*	<button style={logoText} onClick={() => changeLang("es")}>es</button>*/}
						{/*	<span style={logoText}> | </span>*/}
						{/*	<button style={logoText} onClick={() => changeLang("en")}>en</button>*/}
						{/*</div>*/}
					</div>
				</div>

			}
		</div>
	);
};


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {})(Header);