// crypto.tsx
import {
	aesDecrypt,
	aesEncrypt,
	base64ToArrayBuffer,
	stringToArrayBuffer,
} from "./platform-cryptor";

export interface MapEntry {
	key: string,
	value: any
}

const invalidFieldName = RegExp("[/*\[\]]");

// fixme need to store in cache
let storageKey: CryptoKey;

/// Ensure that we have a proper storage key set in this context
export const checkInitialization = () => {
	if (!storageKey) {
		throw Error("The crypto context has no storage key");
	}
};

export const setStorageKey = async (key: CryptoKey) => {
	storageKey = key;
};

const setStorageKeyFromString = async (key: string) => {
	storageKey = await crypto.subtle.importKey(
		"raw",
		stringToArrayBuffer(atob(key)),
		"AES-CBC",
		true,
		["encrypt", "decrypt"]
	) as CryptoKey;
};

// TODO: This may need to be used later, but for now the mobile app handles this.
const generatePublicKeyFromPrivateRSA = async (key: string) => {
	return await crypto.subtle.importKey(
		"raw",
		stringToArrayBuffer(atob(key)),
		{
			name: "RSA-OAEP",
			hash: "SHA-256"
		},
		true,
		["encrypt", "decrypt"]
	) as CryptoKey;
};

/// SHA-512 hash a field name for storing
export const hash = async (fieldName: string) => {
	const data = new TextEncoder().encode(fieldName);
	const hashBuffer = await crypto.subtle.digest("SHA-512", data);
	const hashArray = Array.from(new Uint16Array(hashBuffer)); // convert buffer to byte array
	const hashHex = toHexString(hashArray) // hashArray.map(b => b.toString().padStart(2, "0")).join(""); // convert bytes to 16-bit string
	return hashArray.toString();
};

function toHexString(byteArray) {
	return Array.from(byteArray, function(byte) {
		// @ts-ignore
		return ('0' + (byte & 0xFF).toString(16)).slice(-2);
	}).join('')
}

/// Encrypt the value of an entity using AES-256
export const encryptValue = (value: any) => {
	checkInitialization();
	return aesEncrypt(storageKey, String(value));
};

/// Decrypt the value of an entity
export const decryptValue = async (cypherText: ArrayBuffer) => {
	checkInitialization();
	return aesDecrypt(storageKey, cypherText);
};

/// Encode a key-value field to be stored in FB
// Should return a single key-value pair, in dart this is a MapEntry, doesn't seem to be a corresponding type in TS
export const encode = async (fieldName: string, value: any) => {
	if (value === null || value === undefined) {
		console.error("Value to encode is null!");
		throw Error("NullValueError");
	}

	const hashedFieldName = await hash(fieldName);
	if (invalidFieldName.test(hashedFieldName)) {
		console.error(`Hashed field name ("${fieldName}", hashed=${hashedFieldName}) contains an illicit character("~*/[]")`);
		throw Error("Invalid Character in field name.");
	}

	const hashedFieldValue = await encryptValue(value);
	return { [hashedFieldName]: hashedFieldValue };
};

/// Decode a key-value pair from a FB document
export const decode = (fieldName: string, document: Record<string, any>) => {
	return hash(fieldName).then(key => {
		const encryptedValue = document[key];
		if (encryptedValue === null || encryptedValue === undefined) {
			throw Error("Value is undefined.");
		}

		return decryptValue(encryptedValue);
	}).catch(err => {
		throw Error(`Error in decoding field "${fieldName}":: ${err}`);
	});
};

// Derive an AES key and IV from the colon-separated string.
export const aesStringToKey = (keyAndIv: string) => {
	const splitKey = keyAndIv.split(":");

	if (splitKey.length !== 2) {
		throw Error("aesStringToKey:: AES key and IV not decoded properly");
	}

	const keyString = base64ToArrayBuffer(splitKey[0]);
	const ivString = base64ToArrayBuffer(splitKey[1]);

	return [keyString , ivString];
}

// Derive an IV, value, and  from the colon-separated string.
export const dataStringToKey = (keyAndIv: string) => {
	const splitKey = keyAndIv.split(":");

	if (splitKey.length !== 3) {
		throw Error("aesStringToKey:: data not decoded properly");
	}

	const ivString = base64ToArrayBuffer(splitKey[0]);
	const macString = base64ToArrayBuffer(splitKey[1]);
	const cypherString = base64ToArrayBuffer(splitKey[2]);

	return [ivString , macString, cypherString];
}

export const pemToBinary = (pem: string) => {

	// remove newlines
	const splitPem = pem.split("\n");
	let encodedString = "";
	splitPem.forEach(line => {
		encodedString += line.trim();
	});

	return Buffer.from(encodedString, 'base64'); // stringToArrayBuffer(atob(encodedString));
};

// Generate an AES key for testing
export const generateTestAesKey = async () => {
	const iv = crypto.getRandomValues(new Uint8Array(16));
	const key = await crypto.subtle.generateKey(
		{
			name: "AES-GCM",
			length: 256
		},
		true,
		["encrypt", "decrypt"]
	);

	const keyString = await crypto.subtle.exportKey("raw", key);
	const ivString = iv.buffer;
	// return [keyString , ivString];
	return `${keyString}:${ivString}`;
};

export const atobHex = (input) => {
	let output = "";
	for (let i = input.length - 1; i >= 0; i--) {
		output = `% ${input.charCodeAt(i).toString(16) + output}`
	}
	return output;
}

export const btoaHex = (input) => {
	let output = "";
	for (let i = 0; i < input.length; i+=2) {
		output += String.fromCharCode(parseInt(output.substr(i, 2), 16))
	}
	const finalOut = decodeURIComponent(escape(output))
	return finalOut;
}
