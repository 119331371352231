import { ROUTES } from "../config/routes";
import firebase from "../index";

export interface IAuthKeys {
	isTemp: boolean
	token: null | string
}

export interface IAuthKeysUpdates {
	isTemp?: boolean
	token?: null | string
}

export const initialSessionAuthState: IAuthKeysUpdates = {
	isTemp: false,
	token: null
};

export const AUTH_SESSION_STORAGE_KEY: string = "session";


/**
 * Retrieve session auth credentials from storage.
 * @returns {IAuthKeys}
 */
export const getSessionAuth = (): IAuthKeys => {
	const key = sessionStorage.getItem(AUTH_SESSION_STORAGE_KEY) || localStorage.getItem(AUTH_SESSION_STORAGE_KEY) || null;
	const session: IAuthKeys | null = key ? JSON.parse(key) as IAuthKeys : null;

	return session && session.token
		? session
		: { token: null, isTemp: false };
};

/**
 * Update the session auth object.
 * @param updates
 * @returns {IAuthKeys}
 */
export const updateSessionAuth = (updates: IAuthKeysUpdates): IAuthKeys => {
	const currentAuthObj = getSessionAuth();
	const newAuthObj: IAuthKeys = { ...currentAuthObj, ...updates };

	if (newAuthObj.isTemp) {
		// @ts-ignore
		return sessionStorage.setItem(AUTH_SESSION_STORAGE_KEY, JSON.stringify(newAuthObj)) || newAuthObj;
	} else {
		// @ts-ignore
		return localStorage.setItem(AUTH_SESSION_STORAGE_KEY, JSON.stringify(newAuthObj)) || newAuthObj;
	}
};

/**
 * Completely remove the session auth object.
 */
export const clearSessionAuth = () => {
	localStorage.removeItem(AUTH_SESSION_STORAGE_KEY);
	document.cookie = "physical=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	return sessionStorage.removeItem(AUTH_SESSION_STORAGE_KEY);
};

/**
 * Clear session and redirect to login.
 */
export const nukeSessionLogout = () => {
	firebase.auth().signOut()
		.then(() => {
			clearSessionAuth();
			window.location.href = window.location.origin.concat(ROUTES.LOGIN.path);
		});
};

/**
 * Clear all session data and redirect to Login page with an error message.
 * @param {Response} res
 * @param {string} errorMsg
 * @returns {Promise<Response>}
 */
export const nukeSessionResponse = (res: Response, errorMsg?: string): Response => {
	clearSessionAuth();
	if (errorMsg) {
		throw Error(errorMsg);
	}

	return res;
};