import React from "react";
import { ReactSVG } from "react-svg";
import { api } from "../api/prep";
import adherence from "../assets/ic_adherence.svg"
import profile from "../assets/ic_profile.svg"
import chat from "../assets/ic_chat.svg"
import disableUser from "../assets/ic_disable_user.svg"
import exportData from "../assets/ic_export.svg"
import feed from "../assets/ic_feed.svg"
import diary from "../assets/ic_diary.svg"
import usage from "../assets/ic_usage.svg"
import notes from "../assets/ic_notes.svg"
import signOut from "../assets/ic_sign_out.svg"
import groups from "../assets/ic_send.svg"
import updates from "../assets/ic_updates.svg"
import users from "../assets/ic_users.svg"
import { ROUTES } from "./routes";
import {exportData as dataExport} from "../api/export";
import i18n from "../i18n/config";
import { APP_ROLES } from "./roles";
import { PublicUserState } from "../store/reducers/publicUserReducer";
import { toast } from "react-toastify";

export interface SideNavItem {
	icon: JSX.Element | null // eslint-disable-line no-undef
	title: string
	path?: string
	onClick?: (string) => Promise<any>
	color?: string
	roles?: string[]
	publicUser?: PublicUserState
}

export const MainSideNavigation: SideNavItem[] = [
	
	{
		icon: <ReactSVG src={users} />,
		title: i18n.t("Users"),
		path: ROUTES.MAIN.path,
		roles: ROUTES.MAIN.hasRole || []

	},
	{
		icon: <ReactSVG src={users} />,
		title: i18n.t("Clinics"),
		path: ROUTES.CLINICS.path,
		roles: ROUTES.CLINICS.hasRole || []

	}, 
	{
		icon: <ReactSVG src={groups} />,
		title: i18n.t("Clinic Notifications"),
		path: ROUTES.CLINIC_MESSAGES.path,
		roles: ROUTES.CLINIC_MESSAGES.hasRole || []

	},{
		icon: <ReactSVG src={groups} />,
		title: i18n.t("Group Messages"),
		path: ROUTES.GROUPS.path,
		roles: ROUTES.GROUPS.hasRole || []

	}, {
		icon: <ReactSVG src={updates} />,
		title: i18n.t("Resources"),
		path: ROUTES.UPDATES.path,
		roles: ROUTES.UPDATES.hasRole || []

	}, {
		icon: <ReactSVG src={exportData} />,
		title: i18n.t("Export All Data"),
		onClick: async (clinicID?) => {
			toast.info(i18n.t("Processing Data Export"), {
				position: toast.POSITION.BOTTOM_RIGHT
			});

			try {

			const baseFields = ["id", "userId", "createdAt", "updatedAt"]
			const getAllData = (userData: any[], fields: string[], collectionName: string) => Promise.all(userData.map((usr) => api.getData(usr.id, fields, collectionName, true, true))).then((results) => results.reduce((a,b) => a.concat(b)).filter(item => item));
			const userFields = ["firstName", "lastName", "email", "phone",
				"birthdate", "gender"];
			let userData;
			if(clinicID){
				userData = await api.getUsersByClinic(clinicID);
				// userData = "";
			}else{
				userData = await api.getUsers();
			}

			const userAdh: any[]=[];
			const userCreatedAt: any[]=[];


			for(let i = 0; i < userData.length; i++){
				api.getTotalAdherence(userData[i].id).then((adh: number) => {
					userAdh.push({"userId": userData[i].id, "adherence": adh.toString()});
				});

				userCreatedAt.push(userData[i].createdAt ? {"userId": userData[i].id, "createdAt": userData[i].createdAt.toDate().toString().substring(0, 15)} 
					: {"userId": userData[i].id, "createdAt": ""});
			}



			const medicationFields = baseFields.concat(["name"]);
			const medicationData = await getAllData(userData, medicationFields, "medications");
			const reminderFields = baseFields.concat(["type", "scheduledAt", "doctorName", "notes", "medicationId",
				"pickupDate", "messageType", "message"])
			const reminderData = await getAllData(userData, reminderFields, "reminders");
			const notesFields = baseFields.concat(["text", "secondaryEffectsIds", "date"]);
			const notesData = await getAllData(userData, notesFields, "notes");
			const providersFields = baseFields.concat(["name"]);
			const providersData = await getAllData(userData, providersFields, "providers");

			const newFields = userFields.concat(["medicationName", "myPrEPNotes", "secondaryEffects", 
				"pharmacyName", "remindersSetByPatient", "Patient Creation Date", "Adherence (%)"]);

			let newDatas = [{}];
			
			// Fetch medication's name
			userData.forEach(user => {
				let newData = {};
				let newMedData = { "medicationName": [] as string[]};
				let newTextData = { "myPrEPNotes": [] as string[] };
				let newSecondaryData = { "secondaryEffects": [] as string[] };
				let newCustomData = { "customSecondaryEffect": [] as string[] };
				let newNameData = { "pharmacyName": "" };
				let adherenceData = { "Adherence (%)": "" };
				let userCreationData = { "Patient Creation Date": "" };
				let newTypeData = { "remindersSetByPatient": [] as string[] };

				medicationData.find((data, ind) => {
					if (data.userId == user.id) {
						newMedData.medicationName.push(data.name);
					} 
				});

				userAdh.find((data, ind) => {
					if (data.userId == user.id) {
						adherenceData = { "Adherence (%)": data.adherence };
					} 
				});

				userCreatedAt.find((data, ind) => {
					if (data.userId == user.id) {
						userCreationData = { "Patient Creation Date": data.createdAt };
					} 
				});

			
				notesData.find((data, ind) => {
					if (data.userId == user.id) {
						newTextData.myPrEPNotes.push(data.text);
					}  
				});


				notesData.find((data, ind) => {
					if (data.userId == user.id) {
						newSecondaryData.secondaryEffects.push(data.secondaryEffectsIds);
					} 
				});

				notesData.find((data, ind) => {
					if (data.userId == user.id) {
						newCustomData.customSecondaryEffect.push(data.customSecondaryEffect);
					}  
				});

				providersData.find((data, ind) => {
					if (data.userId == user.id) {
						newNameData = { "pharmacyName": data.name };
					} 
				});

				reminderData.find((data, ind) => {
					if (data.userId == user.id) {
						newTypeData.remindersSetByPatient.push(data.type);
					} 
				});


				newData = {...user, ...newMedData, ...newTextData, ...newSecondaryData, ...newNameData,
					 ...newTypeData, ...userCreationData, ...adherenceData}

				newDatas.push(newData);

			});

			let currDate = new Date(Date.now()).toString().split(",")[0];
			dataExport((`Data Export ${currDate.substring(0, 15).replace(/\//g, "-")}`), newFields, newDatas);

			toast.success(i18n.t("Data Export Completed"), {
				position: toast.POSITION.BOTTOM_RIGHT
			});
			
			return Promise.resolve();

		} catch (err) {
			toast.error(i18n.t("Error occurred while exporting data."), {
				position: toast.POSITION.BOTTOM_RIGHT
			});
		}

		},
		roles: [APP_ROLES.SUPER_ADMIN, APP_ROLES.CLINIC_ADMIN]


	}, {
		icon: <ReactSVG id="signOutIcon" style={{fill: "#B00020 !important"}} src={signOut} />,
		title: i18n.t("Sign out"),
		color: "#B00020",
		onClick: () => {
			return api.logout();
		}
	}
];

export const UserSideNavigation: SideNavItem[] = [
	{
		icon: <ReactSVG src={adherence} />,
		title: i18n.t("Adherence"),
		path: ROUTES.ADHERENCE.path,
	},
	 {
		icon: <ReactSVG src={feed} />,
		title: i18n.t("Feed"),
		path: ROUTES.FEED.path,
	}, {
		icon: <ReactSVG src={chat} />,
		title: i18n.t("Chat"),
		path: ROUTES.CHAT.path,
	}, {
		icon: <ReactSVG src={notes} />,
		title: i18n.t("Provider Notes"),
		path: ROUTES.NOTES.path,
	}, {
		icon: <ReactSVG src={diary} />,
		title: i18n.t("My PrEP Notes"),
		path: ROUTES.DIARY.path,
	}, {
		icon: <ReactSVG src={usage} />,
		title: i18n.t("Usage log"),
		path: ROUTES.USAGE.path,
	},
	{
		icon: <ReactSVG src={groups} />,
		title: i18n.t("Appointments"),
		path: ROUTES.APPOINTMENT.path,
	},
	{
		icon: <ReactSVG src={profile} />,
		title: i18n.t("Profile"),
		path: ROUTES.PROFILE.path,
	}, {
		icon: <ReactSVG src={disableUser} />,
		title: i18n.t("Disable User"),
		color: "#B00020",
		// TODO: OnClick or Path for this, idk yet
	}, {
		icon: <ReactSVG src={disableUser} />,
		title: i18n.t("Delete User"),
		color: "#B00020",
		// TODO: OnClick or Path for this, idk yet
	}
];