import React, { CSSProperties } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import { IMessage } from "../../../api/models/message";
import profile from "../../../assets/ic_profile.svg";
import staffActive from "../../../assets/ic_staff_green.svg";
import staffInactive from "../../../assets/ic_staff_pink.svg";
import koala from "../../../assets/message_koala.svg";
import { ListaColors } from "../../../styles/colors";
import { getChatCardDateTime } from "../../../utils";

interface IChatCardProps {
	message: IMessage,
	isStaffMessage: boolean
	isBotMessage?: boolean
	isActiveStaffMessage: boolean
}

export const chatCardStyle: CSSProperties = {
	borderRadius: "16px",
	maxWidth: "600px",
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.075)"
}

const staffChatStyle: CSSProperties = {
	...chatCardStyle,
	/*backgroundColor: ListaColors.secondaryLight,*/
	maxWidth: "600px"
}

const activeUserStyle: CSSProperties = {
	backgroundColor: ListaColors.secondaryLight,
	...chatCardStyle,
}

const inactiveUserStyle: CSSProperties = {
	backgroundColor: ListaColors.lightPink,
	...chatCardStyle
}

const botChatStyle: CSSProperties = {
	...chatCardStyle,
	backgroundColor: "#5C6BC0",
	color: "#FFFFFF",
	boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.075)"
}

const staffNameStyle: CSSProperties = {
	marginRight: "56px",
	fontWeight: 700
}

const staffAvatarStyle: CSSProperties = {
	transform: "translateY(-20px)",
}

export const chatDateStyle: CSSProperties = {
	color: "rgba(0,0,0, 0.38)"
}

const ChatCard: React.ComponentType<IChatCardProps> = (props: IChatCardProps): JSX.Element => { // eslint-disable-line no-undef

	const leftCard = (): JSX.Element => { // eslint-disable-line no-undef
		return (
			<Container>
				<Row>
					<Card body={true} style={chatCardStyle}>
						<Container>
							<Row>
								<Col xs={"auto"} >
									<ReactSVG src={profile} />
								</Col>
								<Col className="align-self-center">
									{props.message.content}
								</Col>
							</Row>
						</Container>
					</Card>
				</Row>
				<Row style={chatDateStyle}>
					{getChatCardDateTime(props.message.createdAt.toDate())}
				</Row>
			</Container>
		)
	};

	const rightCard = (): JSX.Element => { // eslint-disable-line no-undef
		return (
			<Container>
				<Row>
					<Card body={true} style={props.isActiveStaffMessage ? activeUserStyle : inactiveUserStyle }>
						<Container>
							<Row>
								<Col className="text-right" style={staffNameStyle}>{props.message.from.displayName || "Admin"}</Col>
							</Row>
							<Row>
								<Col>
									{props.message.content}
								</Col>
								<Col xs={"auto"}>
									<ReactSVG src={props.isActiveStaffMessage ? staffActive : staffInactive} className="float-right" style={staffAvatarStyle} />
								</Col>
							</Row>
						</Container>
					</Card>
				</Row>
				<Row className="justify-content-end" style={chatDateStyle}>
					{getChatCardDateTime(props.message.createdAt.toDate())}
				</Row>
			</Container>
		)
	};

	const botCard = (): JSX.Element => { // eslint-disable-line no-undef
		return (
			<Container>
				<Row>
					<Card body={true} style={botChatStyle}>
						<Container>
							<Row>
								<Col>
									{props.message.content}
								</Col>
								<ReactSVG src={koala} className="float-right koala-svg" />
							</Row>
						</Container>
					</Card>
				</Row>
				<Row className="justify-content-end" style={chatDateStyle}>
					{getChatCardDateTime(props.message.createdAt.toDate())}
				</Row>
			</Container>
		)
	};

	return props.isBotMessage ? botCard() : props.isStaffMessage ? rightCard() : leftCard();
}

export default ChatCard;