import { DocumentData } from "@firebase/firestore-types";
import { bytesToBase64 } from "byte-base64";
import { HippoEncrypto } from "../web-hipaapotamus/src";
import { ROUTES } from "../config/routes";
import firebase, { db } from "../index";
import { countReminderResponsesConfirmed, datesAreSame, filterResponsesAfterDate, formatRsa, generateThumbnail, getByteArray, getDayDiff, getPrepReminders } from "../utils";
import { generateReminderNotifications } from "./feedGenerator";
import { IAdherence } from "./models/adherence";
import { IDiary } from "./models/diary";
import { IMedication } from "./models/medication";
import { IMessage, IPrivateMessageUserEntity, IPrivateUserEntity } from "./models/message";
import { INote, INoteWithKey, mapAsINoteWithDate } from "./models/note";
import { IReminderNotification } from "./models/reminders/reminderNotification";
import { IUpdate, IUpdateClinic, IUpdatePost } from "./models/updates";
import { ISection, ISectionPost } from "./models/sections";
import { IUsage } from "./models/usage";
import { IUser } from "./models/user";
import { IWeight } from "./models/weight";
import { getSessionAuth, nukeSessionLogout } from "./sessions";
import { IReminderResponse } from "./models/reminders/reminderResponse";
import { IMedicationReminder } from "./models/reminders/medicationReminder";
import { IClinic } from "./models/clinics"
import React from "react";
import { QueryDocumentSnapshot } from "@firebase/firestore-types";
import { IGroupMessages } from "./models/groupMessages";
import { IAppointmentReminder } from "./models/reminders/appointmentReminder";
import { IClinicMessages } from "./models/clinicMessages";


class API {
	private mfaSecret = "";

	public constructor() {
		const auth = getSessionAuth();
		this.mfaSecret = auth.token ? auth.token : "";
	}

	/* *****************************************************
	 *
	 *	GENERIC
	 *
	 ***/

	// Get data from a user's collection. If `withId` argument is true, it maps to
	// an object with the firebase document's ID as a property.
	public async getData(id: string, unhashedFields: string[], collectionName: string, withId: boolean  = false,
											 withUserId: boolean = false): Promise<any[]> {

		// TODO: Hit FB for users
		const hippoEncrypto = new HippoEncrypto();
		await hippoEncrypto.loadRSAPrivateKey(this.mfaSecret);
		const documentData: DocumentData[] = [];
		let decodedDocumentData;
		const userData = await db.collection(`users/${id}/${collectionName}`).get();

		userData.forEach(doc => {
			let data = {...doc.data()}
			if (withId) {
				data.id = doc.id
			}
			if (withUserId) {
				data.userId = id
			}
			documentData.push(data);
		});

		const userSecure = await db.doc(`users/${id}/secure/encryptedStorageKey`).get();
		const userKey = userSecure.data();
		if (userKey) {
			decodedDocumentData = await hippoEncrypto.decodeFields(documentData, userKey.key, unhashedFields).catch(error => {
				console.error(error);
			})
		}
		else {
			console.log("No user key.");
		}
		return Promise.resolve(decodedDocumentData);
	}

	/* *****************************************************
	 *
	 *	LOGIN
	 *
	 ***/

	public login(email: string, password: string, persist?: boolean): Promise<firebase.auth.UserCredential> {
		if (persist) {
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
				return firebase.auth().signInWithEmailAndPassword(email, password);
			})
		}
		return firebase.auth().signInWithEmailAndPassword(email, password);
	}

	public logout() {
		return firebase.auth().signOut()
			.then(() => {
				nukeSessionLogout();
				window.location.href = window.location.origin.concat(ROUTES.LOGIN.path);
		});
	}

	public saveKey(key) {
		this.mfaSecret = formatRsa(key);
	}

	public forgotPassword(email: string): Promise<void> {
		return firebase.auth().sendPasswordResetEmail(email);
	}

	/* *****************************************************
	 *
	 *	USERS
	 *
	 ***/

	public async getUsers() {

		const decodedUsers: any[] = [];
		const encodedUsers: [DocumentData, string][] = [];
		const usersAndIvs: [DocumentData, string][] = [];
		const hippoEncrypto = new HippoEncrypto();

		// load RSA key into hippo
		return hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then( async () => {

			// get encoded users from firebase
			const querySnapshot = await db.collection("users").get();
			querySnapshot.forEach(doc => {
				encodedUsers.push([doc.data(), doc.id]);
			});
			// get user's key from firebase
			for (const user of encodedUsers) {
				const [userData, id] = user;
				const subQuerySnapshot = await db.collection(`users/${id}/secure`).get();
				const userEvents = await db.collection(`users/${id}/events`).orderBy("createdAt", "desc").limit(1).get();
				if (userEvents.docs.length) {
					userData.updatedAt = userEvents.docs[0].data().createdAt;
				}
				// obviously
				subQuerySnapshot.forEach(secureDoc => {
					const key = secureDoc.data().key;
					if (key) {
						usersAndIvs.push([{...userData, id}, key]);
					}
				});
			}
			for (const user of usersAndIvs) {
				const [userData, key] = user;
				const decodedUser = await hippoEncrypto.decodeUserShallow(userData, key).catch((error) => {
					console.error("decodeUserShallow:: ", error);
					return null;
				});
				if (decodedUser) {
					decodedUsers.push(decodedUser);
				}
			}
			return decodedUsers;
		});
	}


	public async getUsersByClinic(clinicId: string) {

		const decodedUsers: any[] = [];
		const encodedUsers: [DocumentData, string][] = [];
		const usersAndIvs: [DocumentData, string][] = [];
		const hippoEncrypto = new HippoEncrypto();

		// load RSA key into hippo
		return hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then( async () => {

			// get encoded users from firebase
			const querySnapshot = await db.collection("users").where("clinicID", "==", clinicId).get();
			querySnapshot.forEach(doc => {
				encodedUsers.push([doc.data(), doc.id]);
			});
			// get user's key from firebase
			for (const user of encodedUsers) {
				const [userData, id] = user;
				const subQuerySnapshot = await db.collection(`users/${id}/secure`).get();
				const userEvents = await db.collection(`users/${id}/events`).orderBy("createdAt", "desc").limit(1).get();
				if (userEvents.docs.length) {
					userData.updatedAt = userEvents.docs[0].data().createdAt;
				}
				// obviously
				subQuerySnapshot.forEach(secureDoc => {
					const key = secureDoc.data().key;
					if (key) {
						usersAndIvs.push([{...userData, id}, key]);
					}
				});
			}
			for (const user of usersAndIvs) {
				const [userData, key] = user;
				const decodedUser = await hippoEncrypto.decodeUserShallow(userData, key).catch((error) => {
					console.error("decodeUserShallow:: ", error);
					return null;
				});
				if (decodedUser) {
					decodedUsers.push(decodedUser);
				}
			}
			return decodedUsers;
		});
	}

	public async getAllEncodedUsers(userRequesting?: string) {

		const encodedUsers: [DocumentData, string][] = [];
		const hippoEncrypto = new HippoEncrypto();

		// load RSA key into hippo
		return hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then( async () => {

			// get encoded users from firebase
			const querySnapshot = await db.collection("users").get();
			querySnapshot.forEach(doc => {
				if(!userRequesting || userRequesting !== doc.id){
					encodedUsers.push([doc.data(), doc.id]);
				}
			});

			return encodedUsers;

		});

	}

	public async decodeUsers(encodedUsers:[DocumentData, string][]) {

		const decodedUsers: IUser[] = [];
		const usersAndIvs: [DocumentData, string][] = [];
		const hippoEncrypto = new HippoEncrypto();

		// load RSA key into hippo
		return hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then( async () => {

			// get user's key from firebase
			for (const user of encodedUsers) {

				const [userData, id] = user;
				const collectionPathSecure = "users/"+id+"/secure";
				const subQuerySnapshot = await db.collection(collectionPathSecure).get();

				subQuerySnapshot.forEach(secureDoc => {
					const key = secureDoc.data().key;
					if (key) {
						usersAndIvs.push([{...userData, id}, key]);
					}
				});
			}

			for (const user of usersAndIvs) {
				const [userData, key] = user;
				const decodedUser = await hippoEncrypto.decodeUserShallow(userData, key).catch((error) => {
					console.error("decodeUserShallow:: ", error);
					return null;
				});
				if (decodedUser) {
					decodedUsers.push(decodedUser);
				}
			}
			return decodedUsers;
		});

	}

	// public async loadData() {
	// 	const [lastDocument, setLastDocument] = React.useState(Array<QueryDocumentSnapshot>);

	// 	let query = db.collection("users").orderBy("updatedDate");

	// 	if (lastDocument !== undefined) {
	// 		query = query.startAfter(lastDocument); // fetch data following the last document accessed
	// 	}

	// 	return query.limit(3).get();
	// }

	

	public async getUser(id: string): Promise<IUser> {
		// return {
		// 	id: "1234567890",
		// 	updatedAt: new firebase.firestore.Timestamp(0,0),
		// 	remindersEnabled: true,
		// 	firstName: "Test",
		// 	lastName: "User",
		// 	age: 99,
		// 	heightInCm: 200,
		// 	weightInKg: 75,
		// 	isMetric: false,
		// 	phoneNumber: "+18885551212",
		// 	totalAdherence: 80.2,
		// 	enabled: true,
		// 	lastAction: "User skipped a medication"
		// };


		const hippoEncrypto = new HippoEncrypto();
		await hippoEncrypto.loadRSAPrivateKey(this.mfaSecret);

		// console.log("getUser::id:: ", id);
		const userDoc = await db.collection("users").doc(id).get();
		const userData = userDoc.data();
		const userSecure = await db.doc(`users/${id}/secure/encryptedStorageKey`).get();
		const userKey = userSecure.data();

		let user: IUser = {
			id,
			photoUrl: "",
			updatedAt: firebase.firestore.Timestamp.now(),
			clinicID: "",
			email: "",
			gender: "",
			remindersEnabled: true,
			firstName: "User Not Found",
			lastName: " ",
			age: 30,
			heightInCm: 165,
			weightInKg: 90,
			isMetric: true,
			ethnicity: "",
			race: "",
			phone: "",
			clinic: "",
			createdAt: firebase.firestore.Timestamp.now()
		};

		if (userKey && userData !== undefined) {
			user = await hippoEncrypto.decodeUserShallow(userData, userKey.key).catch((error) => {
				console.error("getUser::decodeUserShallow:: ", error);
			});
			user.id = id;
		}

		return Promise.resolve(user);
	}

	public async getProviderUser(id: string): Promise<IPrivateUserEntity> {
		const userDoc = await db.doc(`users/${id}`).get();
		const userData = userDoc.data() as IPrivateUserEntity;

		return userData;
	}

	public async disableUser(id: string, setError: any, setLoading: any, setUserDisabled: any) {
		const disable = firebase.functions().httpsCallable("disableUser");
		disable({ "userId": id }).then(res => {
			console.log("User Disabled ", res);
			setLoading(false);
			setUserDisabled(true);
		})
	}

	public async deleteUser(id: string) {
		return db.collection("users").doc(id).update({"deletedAt": firebase.firestore.Timestamp.fromDate(new Date())}).then(() => {
			console.log("User Deleted.");
		});
	}

	public async getLifestyleMessages(userId: string) {
		return await db.collection(`users/${userId}/reminders`)
			.doc("lifestyle")
			.get();
	}

	/* *****************************************************
 	 *
   *	ADHERENCE
   *
   ***/

   public async generateWeeksAdherence (id: string, day: Date): Promise<number> {

	const reminders = await this.getReminders(id);
	const responses = await this.getReminderResponses(id);

	let prepReminders: IMedicationReminder[] = [];

	if(reminders !== undefined) {
		prepReminders = getPrepReminders(reminders);
		const prepResponses = responses?.filter((r) =>  {
			return prepReminders.map((e) => e.id).includes(r.reminderId);
		});

		if (prepResponses !== []) {
			const dayOfWeek = day.getDay(); // 0 == Sunday, 1 == Monday, etc...
			let confirmedCount = 0;
			let totalDays = 0;

			// Users can have multiple prep reminders. Idk why that is, but accounting
			// for it here anyway.
			prepReminders.forEach((prep) => {
				const daysSinceStartingPrep = getDayDiff(day, prep.createdAt.toDate());

				//   If they've been using the app for less than 1 week, change the
				//   the starting day of the week to this day so that the user's first
				//   week of adherence does not have invalid skips. E.g. If user started
				//   using the app on Friday (day 5) and took their meds Friday and
				//   Saturday, their adherence should be 100% (2/2), not 28% (2/7)

				// The first part of this if statement accounts for if this is the user's first week taking PrEP.
				if (daysSinceStartingPrep < 7 && daysSinceStartingPrep < dayOfWeek) {
					// Get confirmed count of applicable responses
					const applicableResponses = filterResponsesAfterDate(prepResponses, daysSinceStartingPrep - 1);
					confirmedCount += countReminderResponsesConfirmed(applicableResponses);

					if(prep.schedule.endsWith("*")) {
						totalDays += daysSinceStartingPrep;
					} else {
						const days = prep.schedule.split("* * ").slice(-1)[0].split(",");
						days.forEach((d) => {
							const dayAsInt = parseInt(d, 10);
							if (dayAsInt !== null && dayOfWeek >= dayAsInt && dayOfWeek >= dayAsInt - daysSinceStartingPrep) {
								totalDays += 1;
							}
						});
					}
				} else {
					// Get confirmed count of applicable responses
					const applicableResponses = filterResponsesAfterDate(prepResponses, dayOfWeek);
					confirmedCount += countReminderResponsesConfirmed(applicableResponses);

					// If schedule ends in a *, it means it's a daily med
					if (prep.schedule.endsWith("*")) {
						totalDays += dayOfWeek + 1;
					} else { // prep.schedule ends in days of week. Example Schedule: 15 11 * * 1,2,5 (Mon, Wed, Fri @ 11:15am)
						const days = prep.schedule.split("* * ").slice(-1)[0].split(",");
						days.forEach((d) => {
							const dayAsInt = parseInt(d, 10);
							if (dayAsInt !== null && dayOfWeek >= dayAsInt) {
								totalDays += 1;
							}
						});
					}
				}
			});

			const adjusted = totalDays > 0 ? totalDays : 1;
			return Promise.resolve(confirmedCount / adjusted);
		}
	}

	return Promise.resolve(0);
  }

  public async getTotalAdherence(userId: string): Promise<number> {
		const reminders = await this.getReminders(userId);
		const responses = await this.getReminderResponses(userId);

		const prepReminders = getPrepReminders(reminders).sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime());
		const prepResponses = responses?.filter((r) => prepReminders.map((e) => e.id).includes(r.reminderId));

		let daysSinceJoiningApp = 1;
		if (prepReminders) {
			prepReminders.forEach((reminder) => {
				const startDate = new Date(reminder.createdAt.seconds * 1000); // Date() expects ms not s
				const now = new Date();
				daysSinceJoiningApp = getDayDiff(now, startDate);
			});
		}

		let numConfirmed = 0;
		if (prepResponses) {
			prepResponses.forEach((response) => {
				if (response.status === "confirmed") {
					numConfirmed += 1;
				}
			});
		}

		return (numConfirmed / daysSinceJoiningApp) * 100;
	}

	public async getAdherence(id: string): Promise<IAdherence[]> {
		const data: IAdherence[] = [];

		const reminders = await this.getReminders(id);
		const responses = await this.getReminderResponses(id);
		const user = await this.getUser(id);

		const prepReminders = getPrepReminders(reminders).sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime());
		const prepResponses = responses?.filter((r) => prepReminders.map((e) => e.id).includes(r.reminderId));
		const sortedResponses = prepResponses?.sort((a, b) => b?.key.toDate().getTime() - a?.key.toDate().getTime());

		const date = new Date();
		let daysSinceJoiningApp = getDayDiff(date, new Date(user.createdAt.seconds * 1000)); 

		if(sortedResponses !== []) {
			for(let i = 0; i < daysSinceJoiningApp; i++) {
				const matchingResponses = sortedResponses?.filter((r) => datesAreSame(r.key.toDate(), date));
				const confirmedResponses = matchingResponses?.filter((r) => r.status === "confirmed");

				if(matchingResponses !== undefined && matchingResponses.length !== 0) {
					if(confirmedResponses !== undefined && confirmedResponses.length !== 0) {
						data.push({
							// @ts-ignore
							createdAt: new firebase.firestore.Timestamp.fromDate(date),
							adherencePercentage: (confirmedResponses.length / matchingResponses.length) * 100
						});
					} else {
						data.push({
							// @ts-ignore
							createdAt: new firebase.firestore.Timestamp.fromDate(date),
							adherencePercentage: 0
						});
					}
				} else {
					data.push({
						// @ts-ignore
						createdAt: new firebase.firestore.Timestamp.fromDate(date),
						adherencePercentage: 0
					});
				}
				date.setDate(date.getDate() - 1);
			}
		} else {
			for(let i = 0; i < 30; i++) {
				data.push({
					createdAt: firebase.firestore.Timestamp.fromDate(date),
					adherencePercentage: 0
				});
			}
		}

		return data;
	}

	/* *****************************************************
 	 *
   *	WEIGHTS
   *
   ***/


	public async getWeights(id: string): Promise<IWeight[]> {
		const unhashedWeightFields = ["weightInLbs"]

		// TODO: Hit FB for users
		const hippoEncrypto = new HippoEncrypto();
		await hippoEncrypto.loadRSAPrivateKey(this.mfaSecret);
		const weights: DocumentData[] = [];
		let decodedWeights;

		const userWeights = await db.collection(`users/${id}/weights`).get();
		userWeights.forEach(doc => {
			weights.push(doc.data());
		});
		const userSecure = await db.doc(`users/${id}/secure/encryptedStorageKey`).get();
		const userKey = userSecure.data();
		if (userKey) {
			decodedWeights = await hippoEncrypto.decodeFields(weights, userKey.key, unhashedWeightFields).catch(error => {
				console.error(error);
			})
		}
		else {
			console.log("nouserkey");
		}
		return Promise.resolve(decodedWeights);
	}

	/* *****************************************************
 	 *
   *	PROVIDER NOTES (Original "Notes")
   *
   ***/

	public async getProviderNotes(id: string): Promise<INoteWithKey[]> {
		const notes = await this.getData(id, ["text", "author"], "provider_notes");
		return mapAsINoteWithDate(notes);
	}

	public async saveProviderNote(userId: string, content: string, authorName: string, createdAt?: firebase.firestore.Timestamp): Promise<void> {
		const note = {
			createdAt: createdAt ?? firebase.firestore.Timestamp.now(),
			updatedAt: firebase.firestore.Timestamp.now(),
			author: authorName,
			text: content
		}

		const unhashedFields = ["author", "text"];

		const hippoEncrypto = new HippoEncrypto();

		// console.log("userId:: ", userId);
		const userSecure = await db.doc(`users/${userId}/secure/encryptedStorageKey`).get();
		// console.log("userSecure:: ", userSecure);
		const userKey = userSecure.data();
		hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then(async () => {
			// console.log("userKey:: ", userKey);
			if (userKey) {
				const enc = await hippoEncrypto.encryptMessage(note, userKey.key, unhashedFields, []);
				// console.log("enc:: ", enc);
				db.collection(`users/${userId}/provider_notes`).doc().set({
					...enc
				}).then(() => {
					console.log("Provider Note Saved.");
				});
			}
		});
	}

   /* *****************************************************
   *
   *	MY PREP NOTES (DIARY)
   *
   ***/


	// THIS IS NOT PROVIDER NOTES, THIS IS THE MY PREP NOTES THAT THE USER CREATES FOR THEMSELVES
	public async getMyPrepNotes(id: string): Promise<IDiary[]> {
		return this.getData(id, ["text", "secondaryEffectsIds", "customSecondaryEffects", "date"], "notes");
	}

	/* *****************************************************
 	 *
   *	MESSAGES
   *
   ***/

	public async sendAttachment(thread: IPrivateUserEntity, from: IPrivateUserEntity, file: File): Promise<IMessage> {
		return getByteArray(file)
			.then((bytes) => {

				// TODO: Test this. I think base64Image (once encrypted) should be what needs to be uploaded to Firebase
				//  Storage. Once uploaded, the URL to the object in FB-S needs to be set as the IMessage's `content` prop.
				const base64Image = bytesToBase64(bytes);

				return generateThumbnail(file)
					.then((thumbnail) => {
						const message: IMessage = {
							type: "photo",
							content: base64Image,
							createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
							from,
							thread,
							thumbnail
						}

						return Promise.resolve(message);

					})
					.catch((e) => {
						console.error(e);
						return Promise.reject();
					});
			})
			.catch((e) => {
				console.error(e);
				return Promise.reject();
			});
	}

	public async sendMessage(id:string, thread: IPrivateMessageUserEntity, from: IPrivateMessageUserEntity, content: string, type: string): Promise<IMessage> {
		// TODO: Save to FB

		console.log("Timestamp for sendMessage: ", firebase.firestore.Timestamp.now())

		const message: IMessage = {
			type,
			content,
			createdAt: firebase.firestore.Timestamp.now(),
			from,
			thread
		}

		const unhashedMessageFields = [
			"from",
			"thread",
			"content",
		]

		const unhashedPrivateUserFields = [
			"displayName",
			"profileUrl"
		]

		const hippoEncrypto = new HippoEncrypto();

		// console.log("thread.id:: ", thread.id);
		const userSecure = await db.doc(`users/${thread.id}/secure/encryptedStorageKey`).get();
		const userKey = userSecure.data();
		// console.log("userKey:: ", userKey);
		hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then(async () => {
			if (userKey) {
				const enc = await hippoEncrypto.encryptMessage(message, userKey.key, unhashedMessageFields,
					unhashedPrivateUserFields);
				db.collection(`users/${thread.id}/chat`).doc().set({
					...enc
				}).then(() => {
					console.log("Message Sent.");
				});
			}
		})

		return Promise.resolve(message);
	}

	public async encryptMessage(thread: IPrivateMessageUserEntity, from: IPrivateMessageUserEntity, content: string, type: string): Promise<any> {

		console.log("Entering Encrypt message function.")
		console.log("Timestamp.now(): ", firebase.firestore.Timestamp.fromDate(new Date()))



		const message: IMessage = {
			type,
			content,
			createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
			from,
			thread
		}

		const unhashedMessageFields = [
			"from",
			"thread",
			"content",
		]

		const unhashedPrivateUserFields = [
			"displayName",
			"profileUrl"
		]

		const hippoEncrypto = new HippoEncrypto();

		// console.log("thread.id:: ", thread.id);
		const userSecure = await db.doc(`users/${thread.id}/secure/encryptedStorageKey`).get();
		const userKey = userSecure.data();
		// console.log("userKey:: ", userKey);
		let enc;
		await hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then(async () => {
			if (userKey) {
				enc = await hippoEncrypto.encryptMessage(message, userKey.key, unhashedMessageFields,
					unhashedPrivateUserFields);
				console.log("Generating encrypted message with object.")
			}

			console.log("Generating encrypted message with empty object.")
		})
		return Promise.resolve({"userId": thread.id, "userSecure": userKey?.key, "encryptedMsg": enc});

	}

	public async getMessages(id: string): Promise<IMessage[]> {
		// TODO: Hit FB for users
		const hippoEncrypto = new HippoEncrypto();
		await hippoEncrypto.loadRSAPrivateKey(this.mfaSecret);
		const messages: DocumentData[] = [];

		let userMessages = await db.collection(`users/${id}/chat`).get();
		userMessages.forEach(doc => {
			messages.push({
				...doc.data(),
				id
			});
		});

		return Promise.resolve(this.decodeMessages(messages, id, hippoEncrypto))
	}

	// firebase live listening for new messages
	public async listenMessages(id: string, setMessages) {
		const hippoEncrypto = new HippoEncrypto();
		await hippoEncrypto.loadRSAPrivateKey(this.mfaSecret);
		let messages: DocumentData[] = [];

		// returns an unsubscribe function
		const unsubscribe = db.collection(`users/${id}/chat`)
			.onSnapshot((snapshot) => {
				messages = [];
				snapshot.forEach(doc => {
					messages.push({
						...doc.data(),
						id
					})
				})
				console.log("New message, refreshing...");

				this.decodeMessages(messages, id, hippoEncrypto).then( decoded => {
					// console.log("decoded", decoded);
					const sorted = decoded ? decoded.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds) : [];
					setMessages(sorted);
				});
			});

		return Promise.resolve(messages);
	}

	// eslint-disable-line
	public async decodeMessages(messages: DocumentData[], id: string, hippoEncrypto: HippoEncrypto) {

		const unhashedMessageFields = [
			"from",
			"thread",
			"content",
			"thumbnail"
		]

		const unhashedPrivateUserFields = [
			"id",
			"displayName",
			"profileUrl"
		]
		let decodedMessages;

		const userSecure = await db.doc(`users/${id}/secure/encryptedStorageKey`).get();
		const userKey = userSecure.data();
		// console.log("Key::", userKey);
		if (userKey) {
			console.log("Key retrieved, lets go!");
			decodedMessages = await hippoEncrypto.decodeMessages(messages, userKey.key, unhashedMessageFields,
				unhashedPrivateUserFields).catch(error => {
				console.error("Message Decryption Error::", error, error.message, error.name);
			}).then(res => {
				return res;
			})
		}
		else {
			console.log("nouserkey");
		}
		return Promise.resolve(decodedMessages);
	}

	/* *****************************************************
 	 *
   *	UPDATES
   *
   ***/

	public async postUpdate(type: string, title: string, body: string, section: string, description?: string,
										icon?: File, file?: File): Promise<void> {
		// if the body is media, the url for this media will go here
		let outBody = body;
		// same for the icon, except it can be undefined
		let outIcon: string | undefined;

		if ([type, title, body].includes("")) {
			console.log("Required field is empty.");
		}

		// post icon to fb if needed
		if (!!icon) {
			const storageRef = firebase.storage().ref();
			const iconFilename: string = `${Date.now()}_icon_${icon.name}`;
			const resourceRef = storageRef.child("resources/" + iconFilename);

			await resourceRef.put(icon)
			outIcon = await resourceRef.getDownloadURL();
		}

		// post file to fb if needed
		if (["photo", "audio", "video"].includes(type) && file) {
			const storageRef = firebase.storage().ref();
			const uploadFilename: string = `${Date.now()}_${file.name}`;
			const resourceRef = storageRef.child("resources/" + uploadFilename);

			await resourceRef.put(file)
			outBody = await resourceRef.getDownloadURL();
		}

		const post: IUpdatePost = {
			title,
			type,
			content: outBody,
			description,
			section,
			createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
			updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
			totalViews: 0,
		}

		// tslint:disable-next-line
		if (!!icon) {
			post.image = outIcon;
		}

		if (type === "photo") {
			post.image = outBody;
		}

		return db.collection("resources").doc().set(post).then(() => {
			console.log("Resource Published.");
		});
	}

	public async deleteUpdate(id: string) {
		return db.collection("resources").doc(id).delete().then(() => {
			console.log("Resource Deleted.");
		});
	}

	public async editUpdate(id: string, type: string, title: string, body: string, section: string, description?: string,
													icon?: File, file?: File): Promise<void> {

		// if the body is media, the url for this media will go here
		let outBody = body;
		// same for the icon, except it can be undefined
		let outIcon: string | undefined;

		if ([type, title, body].includes("")) {
			console.log("Required field is empty.");
		}

		// post icon to fb if needed
		if (!!icon) {
			const storageRef = firebase.storage().ref();
			const iconFilename: string = `${Date.now()}_icon_${icon.name}`;
			const resourceRef = storageRef.child("resources/" + iconFilename);

			await resourceRef.put(icon);
			outIcon = await resourceRef.getDownloadURL();
		}

		// post file to fb if needed
		if (["photo", "audio", "video"].includes(type) && file) {
			const storageRef = firebase.storage().ref();
			const uploadFilename: string = `${Date.now()}_${file.name}`;
			const resourceRef = storageRef.child("resources/" + uploadFilename);

			await resourceRef.put(file)
			outBody = await resourceRef.getDownloadURL();
		}

		const post: IUpdatePost = {
			updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
		};

		// tslint:disable-next-line
		if (!!icon) {
			post.image = outIcon;
		}
		if (!!title) {
			post.title = title
		}
		if (!!type) {
			post.type = type
		}
		if (!!outBody) {
			post.content = outBody
		}
		if (!!description) {
			post.description = description
		}
		if (!!section) {
			post.section = section
		}

		return db.collection("resources").doc(id).update(post).then(() => {
			console.log("Resource Published.");
		});
	}

	public async getUpdates(): Promise<IUpdate[]> {
		const updates: IUpdate[] = [];
		const querySnapshot = await db.collection("resources").get();
		querySnapshot.forEach(doc => {
			updates.push({
				...doc.data(),
				id: doc.id
			} as IUpdate);
		});

		return Promise.resolve(updates);
	}

	public async getSections(): Promise<ISection[]> {
		const sections: ISection[] = [];
		const querySnapshot = await db.collection("resources_sections").get();
		querySnapshot.forEach(doc => {
			sections.push({
				...doc.data(),
				id: doc.id
			} as ISection);
		});

		return Promise.resolve(sections);
	}

	public async postSection(title: string, language: string): Promise<void> {
		const section: ISectionPost = {
			title,
			language,
			createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
			updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
		};

		return db.collection("resources_sections").doc().set(section).then(() => {
			console.log("Section Saved.");
		});
	}

	public async deleteSection(id: string): Promise<void> {
		return db.collection("resources_sections").doc(id).delete().then(() => {
			console.log("Resource Section Deleted.");
		});
	}

	/* *****************************************************
 	 *
   *	USAGE
   *
   ***/

	public async getUsages(id: string): Promise<IUsage[]> {
		// Data may not be returned in this exact format. For example, it will probably need to return what kind of event has taken place (i.e. view, refill creation, etc.)
		const events: IUsage[] = [];
		const querySnapshot = await db.collection(`users/${id}/events`).get();
		querySnapshot.forEach(doc => {
			events.push({
				...doc.data()
			} as IUsage);
		});
		const sorted = events.sort((a,b) => b.createdAt.toMillis() - a.createdAt.toMillis());
		return Promise.resolve(sorted);
	}

	/* *********************************************************
	*
	*     REMINDERS
	*
	***/

	public async getReminders(id: string): Promise<any[]> {
		return this.getData(id, ["doctorName", "notes"], "reminders", true)
	}

	public async getReminderResponses(id: string): Promise<IReminderResponse[]> {
		return await this.getData(id, ["status", "reason", "streak"], "reminder_responses");
	}

	public async createReminders(id: string, scheduleDate: string, scheduleTime: string, doctorName: string, notes: string): Promise<void>{
		const hippoEncrypto = new HippoEncrypto();
		
		// convert string to timestamp
		const dateStr = `${scheduleDate} ${scheduleTime}:00`;
		console.log(dateStr);

		const [dateRelated, timeRelated] = dateStr.split(" ");
		console.log(dateRelated); // 👉︝ "MM-dd-YYYY"
		console.log(timeRelated); // 👉︝ "HH:mm:ss"

		const [month, day, year] = dateRelated.split("-");
		console.log(month, day, year);
		const [hours, minutes, seconds] = timeRelated.split(":");

		// const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
		// const date2 = new Date(+year, parseInt(month) - 1, +day, +hours, +minutes, +seconds);
		const date = new Date(dateStr);
		console.log(date); // 👉︝ Fri Jun 24 2022 09:30:05
		////

		// hippoEncrypto.decodeFields
		// Probably need to add a method to encodeField
		// doctorName and notes
		
		const appointment = {
			type: "appointment",
			doctorName,
			notes,
    		scheduledAt: firebase.firestore.Timestamp.fromDate(date),
			createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
			updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
		};

		// const unhashedFields = ["scheduledAt", "type", "createdAt", "updatedAt"];
		const unhashedFields = ["doctorName", "notes"];

		// console.log("userId:: ", userId);
		const userSecure = await db.doc(`users/${id}/secure/encryptedStorageKey`).get();
		// console.log("userSecure:: ", userSecure);
		const userKey = userSecure.data();
		hippoEncrypto.loadRSAPrivateKey(this.mfaSecret).then(async () => {
			// console.log("userKey:: ", userKey);
			if (userKey) {
				const enc = await hippoEncrypto.encryptMessage(appointment, userKey.key, unhashedFields, []);
				// console.log("enc:: ", enc);
				db.collection(`users/${id}/reminders`).doc().set({
					...enc
				}).then(() => {
					console.log("Appointment Created.");
				});
			}
		});
	}

	/* *********************************************************
	*
	*     MEDICATIONS
	*
	***/

	public async getMedications(id: string): Promise<IMedication[]> {
		return this.getData(id, ["name", "isDeleted"], "medications", true);
	}

	/* *****************************************************
 	*
	*	FEED
	*
	***/

	// This function generates the user's feed, which consists of reminders and patient notes. You can test
	// straight from `components/cards/FeedCard/FeedCard.tsx` using the `mock.ts` file in the same folder.
	public async generateFeed(id: string): Promise<(INoteWithKey|IReminderNotification)[]> {
		const reminders = await this.getReminders(id);
		const reminderResponses = await this.getData(id, ["status", "reason", "streak"], "reminder_responses");
		const providerNotes = mapAsINoteWithDate(await this.getData(id, [], "patient_notes"));
		// console.log(await this.getData(id, ["name", "isDeleted"], "medications", true));

		let feed: (IReminderNotification | INoteWithKey)[] = [];
		// console.log(reminders);

		feed = feed.concat(generateReminderNotifications(reminders, reminderResponses), providerNotes).sort((a, b) => {
			return Math.floor(a.key.getTime() / 1000) - Math.floor(b.key.getTime() / 1000);
		});

		return(Promise.resolve(feed));
	}

	public async createClinic(name: string, code: string): Promise<void>{
		const clinic: IUpdateClinic = {
			name,
			code,
			createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
			updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
		}

		return db.collection("clinics").doc().set(clinic).then(()=>{
			console.log("Clinic created")
		})

	}

	public async editClinic(id: string, name: string, code: string): Promise<void>{
		const clinic: IUpdateClinic = {
			name,
			code,
			updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
		}

		return db.collection("clinics").doc(id).update(clinic).then(()=>{
			console.log("Clinic updated")
		})
	}

	public async disableClinic(id: string): Promise<void>{
		return db.collection("clinics").doc(id).update({disabled: true}).then(()=>{
			console.log("Clinic disabled")
		})
	}

	public async getClinics(): Promise<IClinic[]>{
		const clinics: IClinic[] = [];
		const querySnapshot = await db.collection("clinics").get();
		querySnapshot.forEach(doc => {
			clinics.push({
				...doc.data(),
				id: doc.id
			} as IClinic);
		});
		return (Promise.resolve(clinics));
	}

	public async getClinicMessages(): Promise<IClinicMessages[]>{
		const groupMessages: IClinicMessages[] = [];
		const querySnapshot = await db.collection("clinic_messages").get();
		querySnapshot.forEach(doc => {
			groupMessages.push({
				...doc.data(),
				id: doc.id
			} as IGroupMessages);
		});
		return (Promise.resolve(groupMessages));
	}

	public async getGroupMessages(): Promise<IGroupMessages[]>{
		const groupMessages: IGroupMessages[] = [];
		const querySnapshot = await db.collection("group-messages").get();
		querySnapshot.forEach(doc => {
			groupMessages.push({
				...doc.data(),
				id: doc.id
			} as IGroupMessages);
		});
		return (Promise.resolve(groupMessages));
	}

	public async groupMessage(user_id: string, admin_name: string, patientEncMsg_: any[], message_: string, clinic_id: string, gender_: string, adherence_: string) {
		const groupMessage = firebase.functions().httpsCallable("createGroupMessage");
		groupMessage({ 
				"userId": user_id,
				"adminName": admin_name,
				"patientEncMsg": patientEncMsg_,
				"message": message_,
				"clinicId": clinic_id,
				"gender": gender_,
				"adherence": adherence_
		}).then(res => {
			console.log("Message sent ", res);
		})
	}

	public async clinicMessage(user_id: string, admin_name: string, patient_list: any[], message_: string, clinic_id: string, clinic_name: string) {
		const clinicMessage = firebase.functions().httpsCallable("createClinicMessage");
		clinicMessage({ 
				"userId": user_id,
				"adminName": admin_name,
				"patientEncMsg": patient_list,
				"message": message_,
				"clinicId": clinic_id,
				"clinicName": clinic_name,
		}).then(res => {
			console.log("Message sent ", res);
		})
	}

	

	public async addClinicMessage(user_id: string, admin_name: string, patient_list: any[], clinicMessage: string, clinic_id: string, clinic_name: string) {
		await firebase.firestore()
			.collection("clinic_messages")
			.add({ 
				userId: user_id,
				adminName: admin_name,
				createdBy: user_id,
				createdAt: firebase.firestore.Timestamp.now(),
				patientList: patient_list,
				message: clinicMessage,
				status: "pending",
				clinicId: clinic_id,
				clinicName: clinic_name
			})
			
	}

	public async addGroupMessage(user_id: string, admin_name: string, patient_list: any[], groupMessage: string, clinic_id: string, gender_: string, adherence_: string ) {
		await firebase.firestore()
			.collection("group-messages")
			.add({ 
				userId: user_id,
				adminName: admin_name,
				createdBy: user_id,
				createdAt: firebase.firestore.Timestamp.now(),
				patientList: patient_list,
				message: groupMessage,
				status: "pending",
				clinicId: clinic_id,
				gender: gender_,
				adherence: adherence_
			})
			
	}

}

export const api = new API();