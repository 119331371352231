import React, { useEffect, useState } from "react";
import "./index.scss";
import { api } from "../../api/prep";
import { Form, Spinner, Dropdown, Button } from "react-bootstrap";
import { handleFormInputChange } from "../../utils/form";
import { ISection } from "../../api/models/sections";
import i18n from "i18next";

interface ResourceSectionProps {
  sectionId: string,
  onChange: (string) => void
}

const ResourceSection: React.ComponentType<ResourceSectionProps> = (props: ResourceSectionProps): JSX.Element => {
  const [title, setTitle] = useState("");
  const [selectedSection, setSelectedSection] = useState({
    id: "",
    title: i18n.t("Create new section")
  });
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState<any[]>([{
    id: "",
    title: i18n.t("Create new section")
  }]);
  const [language, setLanguage] = useState("en");

  const handleTitleInput = handleFormInputChange(setTitle);
  const handleLanguage = handleFormInputChange(setLanguage);

  const loadSections = () => {
    return api.getSections().then(
      (items: ISection[]) => {
        const newSections = [sections[0]].concat(items);
        setSections(newSections);
        setLoading(false);
        return newSections;
      }
    ).catch((error) => {
      console.error("Error getting sections ", error);
      return [];
    });
  };

  useEffect(() => {
    loadSections().then(
      (newSections) => {
        if (props.sectionId) {
          const propsSection = newSections.find(sect => sect.id === props.sectionId);
          if (propsSection) {
            setSelectedSection(propsSection);
          }
        }
      }
    );
  }, []);

  const onSaveTitle = () => {
    setLoading(true);
    const saveTitle = title;
    api.postSection(saveTitle, language).then(
      () =>{ 
        setLoading(false);
        loadSections().then(
          (newSections) => {
            const newSection = newSections.find(sect => sect.title === saveTitle);
            if (newSection) {
              setSelectedSection(newSection);
            }
          }
        );
      }
    ).catch(
      (error) => {
        console.error("Error getting sections ", error);
        setLoading(false);
      }
    );
  };

  const onDeleteSection = () => {
    setLoading(true);
    api.deleteSection(selectedSection.id).then(
      () => {
        loadSections().then(
          () => {
            const createSection = sections.find(sect => !sect.id);
            setSelectedSection(createSection);
          }
        );
      }
    ).catch(
      (error) => {
        console.error("Error while deleting section ", error);
        setLoading(false);
      }
    );
  };

  const onSelectSection = (sectionId) => {
    const foundSection = sections.find(sect => sect.id === sectionId);
    setSelectedSection(foundSection);
    props.onChange(sectionId);
  };

  return <>
    { loading ? <div id="sectionSpinner" hidden={!loading}>
					<Spinner animation="border" role="status" />
			</div> : <>
        <Dropdown className="section-dropdown" onSelect={onSelectSection}>
          <Dropdown.Toggle>
            { selectedSection.title }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { sections.map(section => <Dropdown.Item key={section.id} eventKey={section.id}> { section.title } </Dropdown.Item>) }
          </Dropdown.Menu>
        </Dropdown>
        { !selectedSection.id && <>
          <Form.Label> {i18n.t("Enter section language:")} </Form.Label>
          <Form.Control style={{marginBottom: "16px", width: "150px"}} onChange={handleLanguage} value={language} as="select">
						<option value={"en"}>{i18n.t("English")}</option>
						<option value={"es"}>{i18n.t("Spanish")}</option>
					</Form.Control>
        </>}
        { !selectedSection.id && <>
          <Form.Label> {i18n.t("Enter section name:")} </Form.Label>
          <Form.Control id="titleInput" onChange={handleTitleInput} value={title}/>
          <div className="d-flex justify-content-end">
            <Button className="save-button btn btn-primary" onClick={onSaveTitle} disabled={!title}> {i18n.t("Save section")} </Button>
          </div>
        </> }
        { selectedSection.id && <div className="d-flex justify-content-end">
            <Button className="save-button btn btn-danger" onClick={onDeleteSection}> {i18n.t("Delete section")} </Button>
          </div>
        }
      </>}
  </>;
}

export default ResourceSection;