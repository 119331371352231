import React, { ComponentType, CSSProperties } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import appointment from "../../../assets/ic_appointment.svg";
import medication from "../../../assets/ic_medication.svg";
import refill from "../../../assets/ic_refill.svg";
import thumbsup from "../../../assets/ic_thumbsup.svg";
import note from "../../../assets/ic_note.svg";
import { UserState } from "../../../store/reducers/userReducer";
import { getFeedDateTime, getNotesCardDateTime, totitleCase } from "../../../utils/index";
import "./index.scss";
import { Link } from "react-router-dom";
import { IReminderNotification, isReminderNotification } from "../../../api/models/reminders/reminderNotification";
import { INoteWithKey } from "../../../api/models/note";
import { isMedicationReminder } from "../../../api/models/reminders/medicationReminder";
import { isAppointmentReminder } from "../../../api/models/reminders/appointmentReminder";
import { isRefillReminder } from "../../../api/models/reminders/refillReminder";
import { IMedication } from "../../../api/models/medication";
import { UserSideNavigation, MainSideNavigation } from "../../../config/navigation";
import { useHistory } from "react-router-dom";
import { isLifestyleReminder } from "../../../api/models/reminders/lifestyleReminder";
import i18n from "../../../i18n/config";

export type IFeedReminder = IReminderNotification | INoteWithKey;

interface IUserEngagementProps {
	user: UserState,
	notification: IFeedReminder,
	medication: IMedication | null,
}

const userEngagementCardStyle: CSSProperties = {
	display: "flex",
	flex: 1
}

const UserEngagementCard: ComponentType<IUserEngagementProps> = (props: IUserEngagementProps) => {

	const history = useHistory();

	const getChatRoute = () => {
		if(props.user !== null) {
			return `/users/${props.user.id}/chat`;
		} else {
			return "/";
		}
	}

	const getStatus = (s: string | null) => {
		if(s === null) {
			return <p className="status-received">{i18n.t("RECEIVED")}</p>
		} else {
			return <p className={`status-${s}`}>{s.toUpperCase()}</p>
		}
	}

	const displayIcon = (i: string) => {
		switch(i) {
			case "medication": {
				return (
					<ReactSVG src={medication} />
				)
			}
			case "refill": {
				return (
					<ReactSVG src={refill} />
				)
			}
			case "appointment": {
				return (
					<ReactSVG src={appointment} />
				)
			}
			case "lifestyle": {
				return (
					<ReactSVG src={thumbsup} />	
				)
			}
			case "providerNote": {
				return (
					<ReactSVG src={note} />	
				)
			}
			default: {
				return (
					<div> </div>
				)
			}
		}
	}

	const getLifestyleMessage = (t: IReminderNotification): string => {
		if (isLifestyleReminder(t.reminder)) {
			// The lifestyle reminder doc is created on first launch, so it's createdAt timestamp is the user's start date
			const userStartDate = t.reminder.createdAt.toDate().getTime();

			// The "key" is when this given reminder's push was supposed to be shown
			const reminderTime = t.key.getTime();

			// For our purposes dayDiff is the index for the lifestyle reminder to display, they show every day @ 9am
			let dayDiff = Math.round((reminderTime - userStartDate) / 1000 / 60 / 60 / 24);

			// Need to adjust the dayDiff if it exceeds the length of the messages array, % length will reset it properly
			// 30 % 30 === 0
			// 59 % 30 === 29
			// 72 % 30 === 12
			// etc...
			if (dayDiff > t.reminder.messages.length - 1) {
				dayDiff = dayDiff % t.reminder.messages.length;
			}

			// Try-catch just in case messages is null or something
			try {
				return t.reminder.messages[dayDiff].message;
			} catch (e) {
				console.error("getLifestyleMessage:: ", e);
				// This shouldn't happen but fall back to something that at least looks like real data. The "!" is different
				// than the actual message in FB (ends in a period there). If they report the "!", we know the issue is here.
				return i18n.t("You matter to others. Keep taking PrEP.");
			}
		}

		// This shouldn't happen but fall back to something that at least looks like real data. The "!" is different
		// than the actual message in FB (ends in a period there). If they report the "!", we know the issue is here.
		return i18n.t("You matter to others. Keep taking PrEP.");
	}

	const getTitle = (t: IReminderNotification): string => {
		if(isMedicationReminder(t.reminder)) {
			return i18n.t("Take") + String(props.medication == undefined ? "PrEP" : props.medication?.name);
		} else if(isAppointmentReminder(t.reminder)) {
			return i18n.t("Your appointment is coming up @") + getFeedDateTime(t.key);
		} else if(isRefillReminder(t.reminder)) {
			return "You only have" + String(t.pillsRemaining) + i18n.t(" pills left. Be sure to notify your pharmacy that you need a refill.");
		} else {
			return getLifestyleMessage(t);
		}
	}

	const displayReason = (notification: IReminderNotification) => {
		if(notification.response?.status === "skipped" && notification.response?.reason !== null) {
			return(
				<>
					<Row className="reason-title">{i18n.t("REASON")}</Row>
					<Row>{notification.response?.reason}</Row>
				</>
			);
		} 
		return <></>
	}

	const buildCard = (notification: IFeedReminder) => {
		if(isReminderNotification(notification)) {
			return (
				<div>
					<Row>
						<Col xs={"auto"}>
							<Row>{displayIcon(notification.reminder.type)}</Row>
						</Col>
						<Col className="align-self-center update-content notification-box">
							<Row><h6>{getTitle(notification)}</h6></Row>
							<Row><p>{getNotesCardDateTime(notification.key)}</p></Row>
							{isMedicationReminder(notification.reminder) && displayReason(notification)}
							<Row className="action-row">
								<Col className="message-user">{notification.response?.status === "skipped" && <div onClick={() => history.push(getChatRoute())}>Message {totitleCase(props.user?.firstName)}</div>}</Col>
								{isMedicationReminder(notification.reminder) && <Col className="status">{notification.response !== null ? getStatus(notification.response.status) : getStatus("received") }</Col>}
							</Row>
						</Col>
					</Row>
				</div>
			);
		} else {
			return (
				<div>
					<Row>
						<Col xs={"auto"}>
							<Row>{displayIcon(notification.type)}</Row>
						</Col>
						<Col className="align-self-center update-content notification-box">
							<Row><h6>{i18n.t("Note by")}{notification.author} - {getNotesCardDateTime(notification.key)}</h6></Row>
							<Row><p>{notification.body}</p></Row>
						</Col>
					</Row>
				</div>
			)
		}
	}

	return (
		<>
			<Card id="userEngagement" body={true} style={userEngagementCardStyle}>
				{buildCard(props.notification)}
			</Card>
		</>
	);
};

export default UserEngagementCard;