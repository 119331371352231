import React, { CSSProperties, useEffect, useState } from "react";
import { IDiary } from "../../api/models/diary";
import { IUser } from "../../api/models/user";
import { api } from "../../api/prep";
import { IWeight } from "../../api/models/weight";
import DiaryCard from "../../components/Cards/DiaryCard/DiaryCard";
import { UserState } from "../../store/reducers/userReducer";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { OrNull } from "../../typings";
import UserEngagementCard from "../../components/Cards/UserEngagementCard/UserEngagementCard";
import { userIdStyle, userNameStyles } from "../../components/Cards/UserCard/UserCard";
import "./index.scss";
import { selectedUserSetAction } from "../../store/actions";

interface IDiaryProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

const feedStyles: CSSProperties = {
	flexGrow: 1,
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap",
	flexDirection: "column"
}

const userDetailsStyles: CSSProperties = {
	display: "flex",
	flex: 1,
	flexDirection: "column"
}

const Diary: React.ComponentType<IDiaryProps> = (props: IDiaryProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [user, setUser] = useState<OrNull<IUser>>(props.user);
	const [diaries, setDiaries] = useState<IDiary[]>([]);

	useEffect(() => {
		// User might be null if user directly navigated to this url. Query user if that happens.
		if (!props.user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				if (usr) {
					api.getMyPrepNotes(usr.id).then( res => {
						setDiaries(res);
					});
				}
			});
		} else {
			api.getMyPrepNotes(props.user.id).then( res => {
				// console.log("res:: ", res);
				setDiaries(res);
			});
		}
	}, []); // eslint-disable-line

	const generateCards = () => {
		if (diaries) {
			return diaries.map((diary, index) => {
				return diary === undefined ? <></> :  <DiaryCard diary={diary} key={index}/>
			})
		} else {
			return <></>;
		}
	}

	return(
		<div className="feedStyles diary">
			<div style={feedStyles}>
				{generateCards()}
			</div>
		</div>
	)
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Diary);