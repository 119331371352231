import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Spinner, Col} from "react-bootstrap";
import { api } from "../../api/prep";
import "./index.scss";
import i18n from "../../i18n/config";
import { PublicUserState } from "../../store/reducers/publicUserReducer";
import { connect } from "react-redux";
import ManageClinicMessages from "../../components/Modals/ManageClinicMessages/ManageClinicMessages";
import ClinicMessagesCard from "../../components/Cards/ClinicMessagesCard/ClinicMessagesCard";
import { IClinicMessages } from "../../api/models/clinicMessages";

interface IClinicMessagesProps {
	publicUser: PublicUserState,
}

const ClinicMessages: React.ComponentType<IClinicMessagesProps> = (props: IClinicMessagesProps): JSX.Element => {
    const [groupMessages, setGroupMessages] = useState<any[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loadClinics = () =>{
        api.getClinicMessages()
            .then((currGroupMessages: IClinicMessages[])=>{
                if(props.publicUser?.role === "clinic_admin"){
                    for(let i = 0; i < currGroupMessages.length; i++){
                        if(currGroupMessages[i].clinicId === props.publicUser?.clinicID){
                            groupMessages.push(currGroupMessages[i]);
                        }
                    }
                    setLoading(false)
                } else {
                    setGroupMessages(currGroupMessages)
                    setLoading(false)
                }
            }).catch((error) => {
				console.error(i18n.t("Error getting clinics "), error);
			});
    }
    useEffect(() =>  {
        setLoading(true);
        loadClinics();
	}, []);

    const sortMessagesByRecentAscending = (a, b): number => {
        const lastA = a.createdAt;
        const lastB = b.createdAt;

        if (lastA < lastB) {
            return 1;
        }
        if (lastA > lastB) {
            return -1;
        }
        return 0;
    };

    return (
        <>
            <div className="clinicContainer">
				<Card body={true} className="clinicMessagesPageHeaderCardStyle">
                {/* <Card.Title>Clinic Messages</Card.Title> */}
                    <div className="modal-wrapper">
                        <div className="item">
							<button className="btn btn-outline-primary rounded-sm" onClick={() => {
								setModalShow(true);
							}}>{i18n.t("Send a Clinic Notification")} </button>
						</div>
                    </div>
                </Card>
                <div className="clinicStyles">
                    <div id="clinicSpinner" hidden={!loading}>
                        <Spinner animation="border" role="status" />
                    </div>
                    <Row>
                    {groupMessages.sort(sortMessagesByRecentAscending).slice(0).map((groupMessage, index) => {
                        if (groupMessage) {
                            return (
                            <>
                                {props.publicUser?.role === "super_admin" &&
                                <Row>
                                    <ClinicMessagesCard customKey={groupMessage.id} clinicMessage={groupMessage} refClinicList={groupMessages} message={groupMessage.message}
                                    patientList={groupMessage.patientList} createdAt={groupMessage.createdAt}
                                    createdBy={groupMessage.createdBy} status = {groupMessage.status} clinicName={groupMessage.clinicName} 
                                    adminName={groupMessage.adminName} onAction={()=>{loadClinics();}} />
                                </Row>   
                                } 

                                {props.publicUser?.role === "clinic_admin" &&

                                <Row>
                                    <ClinicMessagesCard customKey={groupMessage.id} clinicMessage={groupMessage} refClinicList={groupMessages} message={groupMessage.message}
                                    patientList={groupMessage.patientList} createdAt={groupMessage.createdAt}
                                    createdBy={groupMessage.createdBy} status = {groupMessage.status} clinicName={""} 
                                    adminName={groupMessage.adminName} onAction={()=>{loadClinics();}} />
                                </Row> 
                                }
                            </>);
                        }
                        return <div key={index}> </div>;
                    })}
                    </Row>
                </div>
                { modalShow && <ManageClinicMessages show={modalShow} onHide={() => { setModalShow(false);}}/> }
            </div>
            
        </>
    );
}

// export default Groups;

const mapStateToProps = ({ publicUser }) => ({ publicUser });
export default connect(mapStateToProps, {})(ClinicMessages);


