import React, { CSSProperties, useState } from "react";
import { Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../../api/prep";
import logo from "../../../assets/logo.png";
import app_logo from "../../../assets/app-logo.png";
import { ROUTES } from "../../../config/routes";
import { publicUserEntitySetAction, urlRefSetAction } from "../../../store/actions";
import { UrlRefState } from "../../../store/reducers/urlRefReducer";
import { OrNull, OrUndefined } from "../../../typings";
import { getCookie, getInputValue, getUrlRoute } from "../../../utils";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import "./index.scss";
import { updateSessionAuth } from "../../../api/sessions";
import i18n from "../../../i18n/config";
import { APP_ROLES } from "../../../config/roles";

interface ILoginProps {
	urlRef: UrlRefState
	urlRefSetAction: typeof urlRefSetAction
	publicUserEntitySetAction: typeof publicUserEntitySetAction
}

const cardChild: CSSProperties = {
	alignSelf: "center"
};

const errorStyles: CSSProperties = {
	color: "red",
	fontSize: "14pt",
	textAlign: "center",
	marginTop: "24px"
}

const LoginCard: React.ComponentType<ILoginProps> = (props): JSX.Element => { // eslint-disable-line no-undef
	const [error, setError] = useState<OrNull<string>>(null);
	const history = useHistory();

	const storePrivateUserEntity = (uId: OrUndefined<string>) => {
		if (uId) {
			let newRoute = "";
			if (props.urlRef) {
				newRoute = getUrlRoute(props.urlRef);
			}

			api.getProviderUser(uId)
				.then((user) => {
					if(user.role && Object.values(APP_ROLES).includes(user.role)){
						const pui = user;

						// Store in redux
						props.publicUserEntitySetAction({...pui, id: uId});

						// Navigate to security if no cookie
						const storedPhys = getCookie("physical");

						if (storedPhys) {
							api.saveKey(storedPhys);
							updateSessionAuth({token: storedPhys, isTemp: false});
							props.urlRefSetAction(null);
							history.push(newRoute ? newRoute : ROUTES.MAIN.path);
						} else {
							history.push(ROUTES.SECURITY_KEY.path);
						}
					}else{
						setError(i18n.t("Something went wrong, please contact system administrator."))
					}
				})
				.catch((e) => {
					console.error("Saving the public user failed:: ", e);

					// Navigate to security anyway, saving the user is convenient but not required atm
					history.push(ROUTES.SECURITY_KEY.path);
				});
		} else {
			console.error("User ID was undefined even though login succeeded?");

			// Navigate to security anyway, saving the user is convenient but not required atm
			history.push(ROUTES.SECURITY_KEY.path);
		}
	}

	const navigateToSecurity = () => {
		const email = getInputValue("#emailField");
		const pass = getInputValue("#passwordField");

		// Clear error on submit in case there is one
		setError(null);

		if (email && pass) {
			api.login(email, pass)
				.then((userCredentials) => {
					storePrivateUserEntity(userCredentials.user?.uid);
				}).catch((e) => {
					setError(e.message);
			});
		} else {
			setError(i18n.t("Please enter an email and password"));
		}
	};

	return (
		<>
			<Card body={true} className="loginCard">
				<img src={app_logo} alt="Logo" style={cardChild}/>
				{/* <h2 style={cardChild}>PrEP</h2> */}
				<h2 style={cardChild}>Memoru</h2>


				<Form.Group className="loginField">
					<Form.Label>{i18n.t("Email address")}</Form.Label>
					<Form.Control id="emailField" type="email" placeholder={i18n.t("Enter email")} />
				</Form.Group>

				<Form.Group className="loginField">
					<Form.Label>{i18n.t("Password")}</Form.Label>
					<Form.Control id="passwordField" type="password" placeholder={i18n.t("Password")} />
				</Form.Group>

				<Link style={cardChild} to={ROUTES.FORGOT_PASSWORD.path}>{i18n.t("Forgot Password?")}</Link>

				{error &&
					<p style={errorStyles}>{error}</p>
				}

				<PrimaryButton className="loginButton" onClick={navigateToSecurity}>
				{i18n.t("Sign In")}
				</PrimaryButton>
			</Card>
		</>
	);

};

const mapStateToProps = ({ urlRef }) => ({ urlRef });

export default connect(mapStateToProps, {
	publicUserEntitySetAction,
	urlRefSetAction
})(LoginCard);