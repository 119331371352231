import React, { CSSProperties } from "react";
import { Route, Switch } from "react-router";
import LoginCard from "../components/Cards/LoginCard/LoginCard";
import ForgotPasswordCard from "../components/Cards/ForgotPasswordCard/ForgotPasswordCard";
import SecurityKeyCard from "../components/Cards/SecurityKeyCard/SecurityKeyCard";
import { ROUTES } from "../config/routes";
import background from "../assets/background.svg";
import "./auth.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const containerStyles: CSSProperties = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100vh",
	backgroundImage: `url(${background})`,
};

const Auth: React.ComponentType = (): JSX.Element => { // eslint-disable-line no-undef
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		cacheBackgroundImage();
	}, []);

	const cacheBackgroundImage = async () => {
		const promise = await new Promise((resolve, reject) => {
			const img = new Image();

			img.src = background;
			img.onload = (e) => resolve(e);
			img.onerror = (e) => reject(e);
		});

		setIsLoading(false);
	}

	if(!isLoading) {
		return (
			<main id="mainLogin">
				<div id="authFormsContainer" style={containerStyles}>
					<Switch>
						<Route
							path={ROUTES.LOGIN.path}
							component={LoginCard}
							exact={true}
						/>
						<Route
							path={ROUTES.FORGOT_PASSWORD.path}
							component={ForgotPasswordCard}
							exact={true}
						/>
						<Route
							path={ROUTES.SECURITY_KEY.path}
							component={SecurityKeyCard}
							exact={true}
						/>
					</Switch>
				</div>
			</main>
		);
	} else {
		return(
			<div id="userSpinner" hidden={!isLoading}>
					<Spinner animation="border" role="status" />
			</div>
		);
	}
};

export default Auth;
