import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IUser } from "../../api/models/user";
import { Row, Spinner } from "react-bootstrap";
import UserCard from "../../components/Cards/UserCard/UserCard";
import { api } from "../../api/prep";
import "./index.scss";
import { PublicUserState } from "../../store/reducers/publicUserReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import {DocumentData} from "@firebase/firestore-types";
import i18n from "../../i18n/config";

interface IUserProps {
	publicUser: PublicUserState,
}

interface IWindow {
	innerWidth: number,
	innerHeight: number
}

const spinnerStyles = {
    width: "324px",
	height: "192px",
	marginTop: "0",
	marginLeft: "24px",
	paddingTop: "24px",
	alignItems: "center",
	overflow: "hidden",
}

const Users: React.ComponentType<IUserProps> = (props: IUserProps): JSX.Element => {
	const [users, setUsers] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [encodedUsers, setEncodedUsers] = useState<[DocumentData, string][]>([]);
	const [lastIndexOfUser, setLastIndexOfUser] = useState<number>(0);
	const [limit, setLimit] = useState<number>(0)
	let subsetUsers: [DocumentData, string][];
	let decodedUsers:any[]=[];


	const getWindowSize = () => {
		const {innerWidth, innerHeight} = window;
		return {innerWidth, innerHeight}
	}

	const calculateLimit = (window : IWindow) => {
		let windowArea = window.innerHeight * window.innerWidth * 0.60
		let capacity = Math.floor(windowArea/54000)
		return capacity + 1
	}

	const limitCalculated = calculateLimit(getWindowSize())

	console.log("Limit calcultation: ", limitCalculated)

	useEffect(() => {
		setLoading(true);
		setLimit(limitCalculated)
		let clinicUsers: any[]=[];
		if(limit && props.publicUser?.role){
			api.getAllEncodedUsers(props.publicUser.id)
			.then((items: [DocumentData, string][]) => {
				if(props.publicUser?.role === "clinic_admin"){
					// Show only patients with the clinic_id from the clinic_admin
					for(let i = 0; i < items.length; i++){
						if(props.publicUser.clinicID && items[i][0].clinicID === props.publicUser?.clinicID  && !(items[i][0].deletedAt)){
							clinicUsers.push(items[i])
						}
					}
				}else{
					//Show all patients for the super_admin

					for(let i = 0; i < items.length; i++){
						if(!(items[i][0].deletedAt)){
							clinicUsers.push(items[i])
						}
					}
					// clinicUsers = items
				}
				setEncodedUsers(clinicUsers);

				subsetUsers = clinicUsers.slice(0, limit);

				setLastIndexOfUser(subsetUsers.length);

				// console.log("all encoded users");

				api.decodeUsers(subsetUsers).then((items2: IUser[]) => {

					setUsers(items2);
					console.log("decode Users");
					setLoading(false);
				});

			}).catch((error) => {
				console.error("Error getting users ", error);
			});
		}
		
	}, [props.publicUser, limit]);

	// Method to load more patients when scrolling through them
	const fetchMoreData = () => {
		// console.log("fetchMoreData")
		setLoading(true);

		console.log(lastIndexOfUser)
		console.log(encodedUsers.length)
		if (lastIndexOfUser >= encodedUsers.length) {
			setHasMore(false);
			setLoading(false);
			console.log("setHasMore to false");
			return;
		}

		if (lastIndexOfUser + limit > encodedUsers.length) {
			subsetUsers = encodedUsers.slice(lastIndexOfUser,encodedUsers.length);
			setLastIndexOfUser(encodedUsers.length);
		} else {
			subsetUsers = encodedUsers.slice(lastIndexOfUser, lastIndexOfUser + limit);
			setLastIndexOfUser(lastIndexOfUser+limit);

		}


		api.decodeUsers(subsetUsers).then((items: IUser[]) => {
			// console.log("decodeUsersCall");

			for(const user of items) {
				setUsers(current => [...current, user]);
			}

			setLoading(false);
		});

	}

	return (
		<>
			{ !users.length && !loading? (
				<p className="mx-5">{i18n.t("This clinic has not any associated users")}</p>
			) : (
				<InfiniteScroll
					dataLength={users.length}
					next={fetchMoreData}
					hasMore={hasMore}
					loader={
						<div id="userSpinner" hidden={!loading} style={spinnerStyles}>
							<span ><Spinner animation="border" role="status" /></span>
						</div>
					}
					endMessage={
						<p style={{ textAlign: "center" }}>
							<b>{i18n.t("No additional patients to be displayed.")}</b>
						</p>
					}
				>
					<div className="userStyles">
						<Row className="mx-0">
							{users.slice(0).map((item, index) => {
								if (item) {
									return <div className="col-xl-4 col-lg-6 col-xs-12"><UserCard customKey={item.id} user={item} /></div>;
								}
								return <div key={index}> </div>;
							})}
						</Row>
						
					</div>
				</InfiniteScroll>
			) }
		</>
	)
}

const mapStateToProps = ({ publicUser }) => ({ publicUser });
export default connect(mapStateToProps, {})(Users);