import React, { ElementType, useEffect } from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNav/SideNav";
import { MainSideNavigation, UserSideNavigation } from "../../config/navigation";
import { ROUTES } from "../../config/routes";
import { Route, Switch } from "react-router";
import Adherence from "../Adherence/Adherence";
import Diary from "../Diary/Diary";
import Notes from "../Notes/Notes";
import Updates from "../Updates/Updates";
import Usage from "../Usage/Usage";
import Users from "../Users/Users";
import Clinics from "../Clinics/Clinics"
import "./index.scss";
import Feed from "../Feed/Feed";
import Chat from "../Chat/Chat";
import { hookstate, useHookstate, useState as useGlobalState } from "@hookstate/core";
import globalState from "../../utils/globalState";
import RoleRoute from "../../router/RoleRoute";
import { render } from "@testing-library/react";
import Profile from "../Profile/Profile";
import Groups from "../Groups/Groups";
import Appointment from "../Appointment/Appointment";
import ClinicMessages from "../ClinicMessages/ClinicMessages";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Main: React.ComponentType = (): JSX.Element => { // eslint-disable-line no-undef

	const global = useGlobalState(globalState);
	const {lang} = global.get();
	// const [storeState, setStoreState] = useState<any>("en");



	useEffect(() =>  {
		// setStoreState("es");
		console.log(lang + " other test");
		// hydrate(App);

	}, [lang]);

	return (
		<>
			<Switch>
				<RoleRoute
					path={ROUTES.MAIN.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser={false} />
					<main id="mainContent">
						<SideNav items={MainSideNavigation} path={ROUTES.MAIN.path} />
						<Users />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.CLINICS.path}
					exact={true}
					roles={["super_admin"]}
				>
					<Header isUser={false} />
					<main id="mainContent">
						<SideNav items={MainSideNavigation} path={ROUTES.CLINICS.path}/>
						<Clinics />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.GROUPS.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser={false} />
					<main id="mainContent">
						<SideNav items={MainSideNavigation} path={ROUTES.GROUPS.path}/>
						<Groups />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.CLINIC_MESSAGES.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser={false} />
					<main id="mainContent">
						<SideNav items={MainSideNavigation} path={ROUTES.CLINIC_MESSAGES.path}/>
						<ClinicMessages />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.UPDATES.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser={false} />
					<main id="mainContent">
						<SideNav items={MainSideNavigation} path={ROUTES.UPDATES.path}/>
						<Updates />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.ADHERENCE.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.ADHERENCE.path} />
						<Adherence />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				
				<Route
					path={ROUTES.PROFILE.path}
					exact={true}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.PROFILE.path} />
						<Profile />
					</main>
					
					<ToastContainer limit={1}/>			
				</Route>
				<RoleRoute
					path={ROUTES.FEED.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.FEED.path} />
						<Feed />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.CHAT.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.CHAT.path} />
						<Chat />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.NOTES.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.NOTES.path} />
						<Notes />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.DIARY.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.DIARY.path} />
						<Diary />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.USAGE.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.USAGE.path} />
						<Usage />
					</main>
					
					<ToastContainer limit={1}/>			
				</RoleRoute>
				<RoleRoute
					path={ROUTES.APPOINTMENT.path}
					exact={true}
					roles={["super_admin", "clinic_admin"]}
				>
					<Header isUser />
					<main id="mainContent">
						<SideNav items={UserSideNavigation} path={ROUTES.APPOINTMENT.path} />
						<Appointment />
						
					</main>
					<ToastContainer limit={1}/>			
				</RoleRoute>
			</Switch>
		</>
	);
};

export default Main;