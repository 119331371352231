import React from "react";


const NotFound: React.ComponentType = (): JSX.Element => { // eslint-disable-line no-undef
	return (
		<div>
			Uh oh, that resource could not be found :/
		</div>
	);
};

export default NotFound;
