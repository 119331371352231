import { IReminderNotification } from "./models/reminders/reminderNotification"; 
import { IMedicationReminder, isMedicationReminder } from "./models/reminders/medicationReminder"; 
import { IRefillReminder, isRefillReminder } from "./models/reminders/refillReminder"; 
import { IAppointmentReminder, isAppointmentReminder } from "./models/reminders/appointmentReminder"; 
import { IReminderResponse } from "./models/reminders/reminderResponse";
import { ILifestyleReminder, isLifestyleReminder } from "./models/reminders/lifestyleReminder";
import { getAllDaysOfWeek } from "../utils";
import { IFrequencyTime } from "./models/reminders/utils/frequencyTime";
import { ITimeOfDay } from "./models/reminders/utils/timeOfDay";
import { OrNull } from "../typings";

export type IReminder = IMedicationReminder | IAppointmentReminder | IRefillReminder | ILifestyleReminder;

const filter = (reminders: IReminder[], filterPillCount: boolean): IReminder[] => {
    if(reminders !== undefined) {
        return reminders.filter((reminder) => {
            if(isMedicationReminder(reminder)) {
                return true;
            } else if(isAppointmentReminder(reminder)) {
                const today = new Date();
                const weekAgo = new Date();
                weekAgo.setDate(today.getDate() - 7);
                return today.getTime() <= reminder.scheduledAt.toDate().getTime() && today.getTime() >= weekAgo.getTime();
            } else if(isRefillReminder(reminder)) {
                if(filterPillCount) {
                    return (reminder.medicationId === "default-medication-1" || reminder.displayAsCountdown) &&
                            reminder.pickupDate.toDate().getTime() <= new Date().getTime();
                }
            } else if(isLifestyleReminder(reminder)) {
                return true;
            }
            return false;
        });
    }
    return [];
}

const generateScheduleDates = (schedule: IFrequencyTime | null, reminder: IReminder): Date[] => {
    const generatedDates: Date[] = [];
    const today = new Date();

    if(schedule !== null) {
        const daysOfWeek = schedule.daysOfWeek;
        let current = new Date(today);
				current.setHours(schedule.time.hour);
				current.setMinutes(schedule.time.minute);

        for(let i = 0; i < 90; i++) {
        		const dayToCheck = new Date(current);
        		dayToCheck.setDate(current.getDate() - i)
            if(daysOfWeek.includes(dayToCheck.getDay()) && dayToCheck.getTime() >= reminder.createdAt.toDate().getTime()) {
                generatedDates.push(dayToCheck);
            }
        }
    }
    return generatedDates;
}

const parseSchedule = (cron: string): OrNull<IFrequencyTime> => {
    if (cron !== null) {
        const parts = cron.split(" ");
        if (parts.length === 5) {
            const minute = parseInt(parts[0], 10);
            const hour = parseInt(parts[1], 10);
            const dayOfWeek = parts[4];
            let daysOfWeek: number[] = [];

            if (dayOfWeek === null || dayOfWeek === "*") {
                daysOfWeek = getAllDaysOfWeek();
            } else {
                daysOfWeek = dayOfWeek.split(",").map((e) => parseInt(e, 10));
            }

            const time: ITimeOfDay = { hour, minute };

            return { daysOfWeek, time };
        }
    }
    return null;
}

const keysForReminderAndDay = (reminder: IReminder): Date[] => {
    if (isMedicationReminder(reminder) || isLifestyleReminder(reminder)) {
        const schedule = parseSchedule(reminder.schedule);
        return generateScheduleDates(schedule, reminder);
    } else if (isRefillReminder(reminder)) {
        return [reminder.pickupDate.toDate()];
    } else if (isAppointmentReminder(reminder)) {
        return [reminder.scheduledAt.toDate()];
    }

    return [new Date()];
}

const calculatePillsRemaining = (reminder: IRefillReminder, medicationReminderIds: string[], responses: IReminderResponse[]): number => {
    const filteredResponses = responses.filter((r) => {
        return r.status === "confirmed" &&
            medicationReminderIds.includes(r.reminderId) &&
            (r.key >= reminder.createdAt) &&
            (r.key <= reminder.pickupDate);
    })

    // Now that we have the responses that apply to this reminder
    return Math.max(reminder.pillCount - filteredResponses.length, 0);
}

export const generateReminderNotifications = (reminders: IReminder[], responses: IReminderResponse[], filterPillCount: boolean = true): IReminderNotification[] =>  {
    const notifications: IReminderNotification[] = [];

    // 1) Filter reminders
    const applicableReminders: IReminder[] = filter(reminders, filterPillCount);

    // 2) For each reminder, generate the time key to use for syncing responses
    for (const reminder of applicableReminders) {
        const keys = keysForReminderAndDay(reminder);

        let pillsRemaining: number | null = null;
        if (isRefillReminder(reminder)) {
            const applicableReminderIds = reminders
                .filter((r) => isMedicationReminder(r) && r.medicationId === reminder.medicationId)
                .map((e) => e.id);
            pillsRemaining = calculatePillsRemaining(reminder, applicableReminderIds, responses);
        }

        // Find response for key + reminderId
        // This needs to be updated to take into account if someone has two PrEP reminders for the same day
        if(isMedicationReminder(reminder) || isLifestyleReminder(reminder)) {
            let notification;
            keys.forEach((k) => {
                const applicableResponse = responses.filter((r) => {
                    return reminder.id === r.reminderId &&
                    r.key.toDate().getFullYear() === k?.getFullYear() &&
                    r.key.toDate().getMonth() === k?.getMonth() &&
                    r.key.toDate().getDate() === k?.getDate();
                });

                if(applicableResponse?.length > 0) {
                    notification = {key: k, reminder, response: applicableResponse[0], pillsRemaining};  
                } else {
                    notification = {key: k, reminder, response: null, pillsRemaining};  
                }

                notifications.push(notification);     
            });
        } else {
            const notification = {key: keys[0], reminder, response: null, pillsRemaining};
            notifications.push(notification);
        }
    }

    return notifications;
  }