import { applyMiddleware, combineReducers, createStore, Reducer, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import storageSession from "redux-persist/lib/storage";
import reduxThunk from "redux-thunk";
import { IActionBase } from "./actions";
import authReducer from "./reducers/authReducer";
import urlRefReducer from "./reducers/urlRefReducer";
import userReducer from "./reducers/userReducer";
import publicUserReducer from "./reducers/publicUserReducer";
import langReducer from "./reducers/langReducer";


// Persist store to session storage for refresh
const persistConfig: PersistConfig<any> = {
	key: "root:prep",
	storage: storageSession,
	blacklist: [
		"user"
	]
};

/**
 * Util function for creating reducers.
 * @param initialState
 * @param handlers
 * @returns {(state: any, action: any) => any}
 */
export function createReducer(initialState: any, handlers: any) {
	return (state: any = initialState, action: IActionBase) => {
		return handlers.hasOwnProperty(action.type)
			? handlers[action.type](state, action)
			: state;
	};
}

/**
 * ===== REGISTER REDUCERS HERE ===== *
 * Main reducer object to define store.
 * @type {Reducer<any>}
 */
const rootReducer: Reducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	publicUser: publicUserReducer,
	urlRef: urlRefReducer,
	lang: langReducer,
});

// Create store with reducers, initial state and any middleware.
let middleware: StoreEnhancer = composeWithDevTools(applyMiddleware(logger, reduxThunk));
if (process.env.NODE_ENV === "production") {
	middleware = composeWithDevTools(applyMiddleware(reduxThunk));
}


// export const store: Store = createStore(rootReducer, middleware);
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, middleware);
export const persistor = persistStore(store);
