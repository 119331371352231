import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import Spinner from "react-bootstrap/Spinner";
import i18n from "../../../i18n/config";

const headerStyles = {
    paddingBottom: "9px",
    fontSize: "20px",
    lineHeight: "24px"
}

const spinnerStyles = {
    
    margin: "45%"
}

const modalBodyStyles = {
    padding: "5%"
}

const LoadingModal = (props): JSX.Element => {
    
    return (
        <div id="dis-user">
            <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            >
                <Modal.Header style={headerStyles} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {i18n.t("Disabling User...")} 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                <span style={spinnerStyles}><Spinner animation="border" role="status" /></span>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoadingModal;