import { IAuthPayload } from "../actions";
import { AUTH_SET } from "../actions/types";
import { createReducer } from "../index";


export interface IAuthState {
	isTemp: boolean
	mfaSecret: string
	id: string
}

export const initialAuthState: IAuthState = {
	isTemp: true,
	mfaSecret: "",
	id: ""
};

/**
 * Handle setting auth state.
 * @param {IAuthState} state
 * @param {IAuthPayload} action
 * @returns {IAuthState}
 */
const authSetReducer = (state: IAuthState, action: IAuthPayload): IAuthState => action.payload;

/**
 * Main reducer mapping.
 */
export default createReducer(initialAuthState, {
	[AUTH_SET]: authSetReducer
});