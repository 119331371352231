import React, { CSSProperties, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Spinner, Col, Form, Button, ProgressBar, Modal} from "react-bootstrap";

import { connect } from "react-redux";
import { DocumentData } from "@firebase/firestore-types";
import { PublicUserState } from "../../../store/reducers/publicUserReducer";
import { handleFormInputChange } from "../../../utils/form";
import { api } from "../../../api/prep";
import { IUser } from "../../../api/models/user";
import i18n from "../../../i18n/config";
import "./index.scss";
import { createMessageUserEntity } from "../../../utils";
import { OrNull } from "../../../typings";
import LinearProgressBar from "../../ProgressBar/LinearProgressBar";
import SpinnerButton from "../../Buttons/SpinnerButton";



interface IGroupsProps {
	publicUser: PublicUserState
    show: boolean
	onHide: () => void
}

const spinnerStyles = {
	marginLeft: "24px",
    marginRight: "24px",
}

const ManageGroups: React.ComponentType<IGroupsProps> = (props: IGroupsProps): JSX.Element => {
    const [users, setUsers] = useState<any[]>([]);
	const [encodedUsers, setEncodedUsers] = useState<[DocumentData, string][]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [progressBar, setProgressBar] = useState<boolean>(false);
    const [currCategory, setCurrCategory] = useState("All");
    const [currAdhRate, setAdhRate] = useState("all-adherence");
    const [message, setMessage] = useState("");
    const [categorySubmit, setCategorySubmit] = useState<boolean>(false);
    const [chosenUsers, setChosenUsers] = useState<any[]>([]);
    const [chosenUsersIds, setChosenUsersIds] = useState<any[]>([]);
    const [chosenUsersEntities, setChosenUsersEntities] = useState<any[]>([]);
    const [messageUserEntities, setMessageUserEntities] = useState<any[]>([]);
    const [encMessages, setEncMessages] = useState<any[]>([]);
    const [now, setNow] = useState<number>(0);
    const [adminName, setAdminName] = useState("");
    const [error_, setError_] = useState<OrNull<string>>(null);


    const handleCategoryInput = handleFormInputChange(setCurrCategory);
    const handleAdhRateInput = handleFormInputChange(setAdhRate);
    const handleMessageInput = handleFormInputChange(setMessage);



    useEffect(() =>  {
        setLoading(true);
        let clinicUsers: any[]=[];
        api.getAllEncodedUsers(props.publicUser?.id)
			.then((items: [DocumentData, string][]) => {
                var acum = 0;
                const itemsCount = items.length;
                console.log("Number of patients :: ", itemsCount)
                setNow(acum/itemsCount);
                console.log("Initial Accum :: ", (acum/items.length))
				if(props.publicUser?.role === "clinic_admin"){
					// Show only patients with the clinic_id from the clinic_admin
					for(let i = 0; i < items.length; i++){
						if(props.publicUser.clinicID && items[i][0].clinicID === props.publicUser?.clinicID  && !(items[i][0].deletedAt) && items[i][0].enabled !== false){
							clinicUsers.push(items[i])
                            console.log("Accum :: " , acum + 1) 
                            console.log("Calc Clinic :: " , (acum + 1)/itemsCount)
                            setNow((acum++)/itemsCount * 100)
						}
					}
				}else{
					//Show all patients for the super_admin
					for(let i = 0; i < items.length; i++){
						if(!(items[i][0].deletedAt) && items[i][0].enabled !== false){
							clinicUsers.push(items[i])
                            console.log("Accum :: " , acum + 1) 
                            console.log("Calc Admin :: " , (acum + 1)/itemsCount)
                            setNow((acum++)/itemsCount * 100)
						}
					}
				}

                setEncodedUsers(clinicUsers);
                // setNow(clinicUsers.length);

                api.decodeUsers(clinicUsers).then((items2: IUser[]) => {
					setUsers(items2);
                    setNow(100)
                    setTimeout(() => {setLoading(false);}, 500)

				});


            }).catch((error) => {
				console.error("Error getting users ", error);
			});

            if(props.publicUser?.id){
                api.getUser(props.publicUser?.id).then((adminUser: IUser)=>{
                    if(adminUser.firstName && adminUser.lastName){
                        let fullName = adminUser.firstName + " " + adminUser.lastName;
                        setAdminName(fullName);
    
                    }
            });
        }
            
		

	}, []);

    /*
<option value={"Male"}>{i18n.t("Male")}</option>
                                        <option value={"Female"}>{i18n.t("Female")}</option>
                                        <option value={"Transmale"}>{i18n.t("Transmale")}</option>
                                        <option value={"Transfemale"}>{i18n.t("Transfemale")}</option>
                                        <option value={"Non-binary"}>{i18n.t("Non-binary")}</option>

    */
    function isUserCorrectGender(currentGenderSelected: string, userGender: string): boolean {
        if (currentGenderSelected === "Cis-Male" && (userGender === "Male" || userGender === "Masculino" || userGender === "Cis-Male" || userGender === "Hombre Cis")) {
            return true;
        } else if (currentGenderSelected === "Cis-Female" && (userGender === "Female" || userGender === "Fememino" || userGender === "Cis-Female" || userGender === "Mujer Cis")) {
            return true;
        }  else if (currentGenderSelected === "Transmale" && (userGender === "Transmale" || userGender === "Hombre Trans")) {
            return true;
        }  else if (currentGenderSelected === "Transfemale" && (userGender === "Transfemale" || userGender === "Mujer Trans")) {
            return true;
        }  else if (currentGenderSelected === "Non-binary" && (userGender === "Non-binary" || userGender === "No Binario")) {
            return true;
        } else if (currentGenderSelected === "Other" && (userGender === "Other" || userGender === "Otro")) {
            return true;
        } else if (currentGenderSelected === "Prefer not to say" && (userGender === "Prefer not to say" || userGender === "Prefiero no decirlo")) {
            return true;
        } else {
            return false;
        }
      }

    function addUserToChosenList(index: number) {
        if (chosenUsers.length === 0) {
            chosenUsers.push(users[index]);
            setChosenUsers(
                [
                    // ...chosenUsers,
                    users[index]
                ]
                );
        } else {
            chosenUsers.push(users[index]);
            setChosenUsers(
            [
                ...chosenUsers,
                // users[index]
            ]
            );
        }
    }

    const onSubmit = () => {
        setCategorySubmit(true);
        setProgressBar(true);

        let decodedUsers: any[]=[];
        
        if(loading === false){

            // Rewriting for-loop
            for (let i = 0; i < users.length; i++) {
                if(isUserCorrectGender(currCategory, users[i].gender)) {
                    // calculate adherence
                    if(currAdhRate === "less50"){
                        api.getTotalAdherence(users[i].id).then((adh: number) => {
                            // select user by appropiate adherence rate
                            if(adh < 50) {
    
                                addUserToChosenList(i);
    
                                chosenUsersIds.push(users[i].id);
                                decodedUsers.push(users[i]);
                                chosenUsersEntities.push([users[i], users[i].id]);
                            } 
                        });
                    } else if (currAdhRate === "50-80"){
                        api.getTotalAdherence(users[i].id).then((adh: number) => {
                            // select user by appropiate adherence rate
                             if(adh >= 50 && adh <= 80) {
                                
                                addUserToChosenList(i);
                                
                                chosenUsersIds.push(users[i].id);
                                decodedUsers.push(users[i]);
                                chosenUsersEntities.push([users[i], users[i].id]);
    
                            } 
                        });
                    } else if (currAdhRate === "greater80"){
                        api.getTotalAdherence(users[i].id).then((adh: number) => {
                            // select user by appropiate adherence rate
                            if(adh > 80)  {
                                // more than 80%
                                
                                addUserToChosenList(i);
    
                                chosenUsersIds.push(users[i].id);
                                decodedUsers.push(users[i]);
                                chosenUsersEntities.push([users[i], users[i].id]);
    
                            }
                        });
                    } else {
                        addUserToChosenList(i);
                        chosenUsersIds.push(users[i].id);
                        decodedUsers.push(users[i]);
                        chosenUsersEntities.push([users[i], users[i].id]);
                    } 
                    // api.getTotalAdherence(users[i].id).then((adh: number) => {
                    //     console.log("Adh: ", adh);
                    //     // select user by appropiate adherence rate
                    //     if(adh < 50) {

                    //         addUserToChosenList(i);

                    //         chosenUsersIds.push(users[i].id);
                    //         decodedUsers.push(users[i]);
                    //         chosenUsersEntities.push([users[i], users[i].id]);
                            
                    //     } else if(adh >= 50 && adh <= 80) {
                            
                    //         addUserToChosenList(i);
                            
                    //         chosenUsersIds.push(users[i].id);
                    //         decodedUsers.push(users[i]);
                    //         chosenUsersEntities.push([users[i], users[i].id]);

                    //     } else {
                    //         // more than 80%
                            
                    //         addUserToChosenList(i);

                    //         chosenUsersIds.push(users[i].id);
                    //         decodedUsers.push(users[i]);
                    //         chosenUsersEntities.push([users[i], users[i].id]);

                    //     }
                    // });
                } else {
                    if(currCategory === "All"){
                        if(currAdhRate === "less50"){
                            api.getTotalAdherence(users[i].id).then((adh: number) => {
                                // select user by appropiate adherence rate
                                if(adh < 50) {
        
                                    addUserToChosenList(i);
                                    // chosenUsers.push(users[i]);
        
                                    chosenUsersIds.push(users[i].id);
                                    decodedUsers.push(users[i]);
                                    chosenUsersEntities.push([users[i], users[i].id]);
                                } 
                            });
                        } else if (currAdhRate === "50-80"){
                            api.getTotalAdherence(users[i].id).then((adh: number) => {
                                // select user by appropiate adherence rate
                                 if(adh >= 50 && adh <= 80) {
                                    
                                    addUserToChosenList(i);
                                    // chosenUsers.push(users[i]);

                                    
                                    chosenUsersIds.push(users[i].id);
                                    decodedUsers.push(users[i]);
                                    chosenUsersEntities.push([users[i], users[i].id]);
        
                                } 
                            });
                        } else if (currAdhRate === "greater80"){
                            api.getTotalAdherence(users[i].id).then((adh: number) => {
                                // select user by appropiate adherence rate
                                if(adh > 80)  {
                                    // more than 80%
                                    
                                    addUserToChosenList(i);
                                    // chosenUsers.push(users[i]);

        
                                    chosenUsersIds.push(users[i].id);
                                    decodedUsers.push(users[i]);
                                    chosenUsersEntities.push([users[i], users[i].id]);
        
                                }
                            });
                        } else {
                                addUserToChosenList(i);
                                chosenUsersIds.push(users[i].id);
                                decodedUsers.push(users[i]);
                                chosenUsersEntities.push([users[i], users[i].id]);

                        }

                    }

                }
            }

            // for(let i = 0; i < chosenUsersEntities.length; i++){
            //     const thread = createMessageUserEntity(chosenUsersEntities[i][0], chosenUsersEntities[i][1]);
            //     messageUserEntities.push(thread)
            // }
            
        setProgressBar(false);
        }
	}

    const onSend = async () => {
        setLoadingButton(true);
        // api.groupMessage("OQv8Jpvv03VTCf8j3R5pYqefktm2", currCategory, currAdhRate, chosenUsers, message);
        if(props.publicUser?.id){
            const pubUser = {
				id: props.publicUser.id || "",
				displayName: props.publicUser.displayName || "Admin",
				profileUrl: props.publicUser.profileUrl || "",
			}

            for(let i = 0; i < chosenUsersEntities.length; i++){
                const thread = createMessageUserEntity(chosenUsersEntities[i][0], chosenUsersEntities[i][1]);
                messageUserEntities.push(thread)
            }

            for(let i = 0; i < messageUserEntities.length; i++){
                const enc = await api.encryptMessage(messageUserEntities[i], pubUser, message, "text");
                encMessages.push(enc);

            }
            api.groupMessage(props.publicUser.id, adminName, encMessages, message, props.publicUser.clinicID, currCategory, currAdhRate);
            props.onHide(); 
        setTimeout(() => {setLoadingButton(false);props.onHide();}, 1000) //To emulate a request promise time
        }
	}

    const errorStyles: CSSProperties = {
        color: "red",
        fontSize: "12pt",
        textAlign: "center",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    }
    

    

    return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal">
				<Form >
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
                        {i18n.t(loading ? "Preparing Patients for Group Message" : "Send Group Message")}
						</Modal.Title>
					</Modal.Header>
                    <div hidden={!loading} style={spinnerStyles}>

                            {/* <span ><ProgressBar now={now} label={`${now}%`}/></span> */}
                            <span ><LinearProgressBar progress={now} label={i18n.t("Loading patients...")}/></span>

                            {/* <span ><ProgressBar now={now} label={i18n.t("Loading patients...")}/></span> */}


                        </div>

                    {loading === false &&
					<Modal.Body style={{width: "400px", marginLeft: "32px", marginBottom: "24px"}}>
                        <div className="modal-wrapper" hidden={categorySubmit === true}>
                                <Form.Group>
                                    <Form.Label> {i18n.t("Select Gender")} </Form.Label>
                                    <Form.Control as={"select"} id="nameInput" onChange={handleCategoryInput}>
                                        <option value={"All"}>{i18n.t("All")}</option>
                                        <option value={"Cis-Male"}>{i18n.t("Cis-Male")}</option>
                                        <option value={"Cis-Female"}>{i18n.t("Cis-Female")}</option>
                                        <option value={"Transmale"}>{i18n.t("Transmale")}</option>
                                        <option value={"Transfemale"}>{i18n.t("Transfemale")}</option>
                                        <option value={"Non-binary"}>{i18n.t("Non-binary")}</option>
                                        <option value={"Other"}>{i18n.t("Other")}</option>
                                        <option value={"Prefer not to say"}>{i18n.t("Prefer not to say")}</option>
                                    </Form.Control>
                                </Form.Group>
                        </div>
                        <div className="modal-wrapper" hidden={categorySubmit === true}>
                                <Form.Group>
                                    <Form.Label> {i18n.t("Select Adherence")} </Form.Label>
                                    <Form.Control as={"select"} id="nameInput" onChange={handleAdhRateInput}>
                                        <option value={"all-adherence"}>{i18n.t("All")}</option>
                                        <option value={"less50"}>{i18n.t("Less than 50%")}</option>
                                        <option value={"50-80"}>{i18n.t("50%-80%")}</option>
                                        <option value={"greater80"}>{i18n.t("Greater than 80%")}</option>
                                    </Form.Control>
                                </Form.Group>
                        </div>

                        <div hidden={categorySubmit === true}><Button onClick={onSubmit}>{i18n.t("Filter")}</Button></div>

                    
                         {categorySubmit === true && loading === false && progressBar === false &&
                 <Modal.Body style={{width: "370px", marginBottom: "24px", marginTop: "16px", marginRight: "40px"}}>
                        {/**style={{borderBottom: "0.05rem solid gray"}} */}
                        <div>
                                <Form.Group style={{marginBottom: "0"}}>
                                    <Form.Label> {i18n.t("Total selected users") + `(${chosenUsers.length}): `}</Form.Label>
                                    {/** className="containerScrollable" */}
                                    <Form.Group style={{height: "135px", overflowY: "scroll", overflowX: "hidden", paddingLeft: "24px"}}
                                        >         
                                        {chosenUsers.map((usr, index) => { 
                                            if (usr) {
                                             return (
                                                <>
                                                    <Row>
                                                        <Form.Label>
                                                            {usr.firstName} {usr.lastName}
                                                        </Form.Label>
                                                    </Row>
                                                </>
                                             );
                                            }

                                            return <div key={index}> </div>;
                                         })
                                        }
                                    </Form.Group> 
                                </Form.Group>
                        </div>
                    <div className="modal-wrapper">
                                <Form.Group style={{marginTop: "16px"}}>
                                    <Form.Label> {i18n.t("Message: ")} </Form.Label>
                                    <Form.Control as={"textarea"} maxLength={500} style={{height: "90px"}} onChange={handleMessageInput}>
                                    </Form.Control>
                                </Form.Group>
                        </div>
                        <SpinnerButton onClick={onSend} message={message} patients={chosenUsers.length} loading={loadingButton} text={i18n.t("Send")} loadingText={i18n.t("Sending...")}/>   
                </Modal.Body>
                
            }
					</Modal.Body>
                    }
					<Modal.Footer>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

const mapStateToProps = ({ publicUser }) => ({ publicUser });
export default connect(mapStateToProps, {})(ManageGroups);