import React from "react";
import { Route, Switch } from "react-router";
import { APP_ROUTER_PATHS, AUTH_ROUTER_PATHS } from "../config/routes";
import ProtectedRoute from "./ProtectedRoute";
import { AppComponent } from "../App";
import NotFound from "../pages/404";
import Main from "../pages/Main/Main";
import Auth from "../pages/Auth";


/**
 * Main high-level/parent routes of the dashboard application.
 * @returns JSX.Element
 * @constructor
 */
const BaseRoutes: AppComponent = () => {
	return (
		<Switch>
			<ProtectedRoute path={APP_ROUTER_PATHS} exact={true} component={Main} />
			<Route path={AUTH_ROUTER_PATHS} exact={true} component={Auth} />
			<Route component={NotFound} />
		</Switch>
	);
};

export default BaseRoutes;