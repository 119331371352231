import React, { ComponentType, CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { IUsage } from "../../../api/models/usage";
import { getNotesCardDateTime } from "../../../utils/index";
import "./index.scss";

interface IUsageCardProps {
	usage: IUsage
}

const imageStyle: CSSProperties = {
	maxWidth: "inherit",

}

const UsageCard: ComponentType<IUsageCardProps> = (props) => {

	const date = props.usage.createdAt.toDate();

	// TODO: This needs to list the events off the user
	return (
		<>
			<Card id="usage" body={true}>
				<Row>
					<Col xs={"auto"}>
						<p>{props.usage.description}</p>
					</Col>
					<Col className="usage-content">
							<p>{getNotesCardDateTime(date)}</p>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default UsageCard;