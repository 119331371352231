import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_es from "../i18n/translation_es.json";
import translation_en from "../i18n/transalation_en.json"
import detector from "i18next-browser-languagedetector";
// import { reactI18nextModule } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translation_en
  },
  es: {
    translation: translation_es
  }
};

const DETECTION_OPTIONS = {
	order: ["navigator"]
};


i18n
	.use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
		detection: DETECTION_OPTIONS,
		resources,
		fallbackLng: "en",

		keySeparator: false,

		interpolation: {
			escapeValue: false,
		}
  });

  export default i18n;