import React, { CSSProperties, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import { IUpdate } from "../../api/models/updates";
import { api } from "../../api/prep";
import audio from "../../assets/ic_audio_upload.svg";
import image from "../../assets/ic_image_upload.svg";
import link from "../../assets/ic_link_upload.svg";
import text from "../../assets/ic_text_upload.svg";
import video from "../../assets/ic_video_upload.svg";
import UpdateCard from "../../components/Cards/UpdateCard/UpdateCard";
import UploadResource from "../../components/Modals/UploadResource/UploadResource";
import "./index.scss";
import i18n from "../../i18n/config";

const errorStyles: CSSProperties = {
	color: "red",
	fontSize: "14pt",
	textAlign: "center"
}

const couponStyles: CSSProperties = {
	borderRadius: "16px",
	display: "block",
}

const Updates: React.ComponentType = () => {
	const [updates, setUpdates] = useState<IUpdate[]>([]);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalType, setModalType] = React.useState("");

	const loadUpdates = () => {
		api.getUpdates().then((items) => {
			setUpdates(items.sort((a,b) => {
				if (a.createdAt.seconds < b.createdAt.seconds) {
					return -1;
				}
				if (a.createdAt.seconds > b.createdAt.seconds) {
					return 1;
				}
				return 0;
			}));
			// setUpdates(items);
		});
	}


	useEffect(() => {
		loadUpdates();
	}, []);

	return(
		<>
			<div className="couponContainer">
				<Card body={true} className="updateCardFormStyles">
					<div className="upload">
					{i18n.t("Upload a new resource")}
					</div>
					<div className="modal-wrapper">
						<div className="item">
							<button onClick={() => {
								setModalShow(true);
								setModalType("audio");
							}}><ReactSVG src={audio} /></button>
						</div>
						<div className="item">
							<button onClick={() => {
								setModalShow(true);
								setModalType("video");
							}}><ReactSVG src={video} /></button>
						</div>
						<div className="item">
							<button onClick={() => {
								setModalShow(true);
								setModalType("photo");
							}}><ReactSVG src={image} /></button>
						</div>
						<div className="item">
							<button onClick={() => {
								setModalShow(true);
								setModalType("link");
							}}><ReactSVG src={link} /></button>
						</div>
						<div className="item">
							<button onClick={() => {
								setModalShow(true);
								setModalType("text");
							}}><ReactSVG src={text} /></button>
						</div>
					</div>
				</Card>

				<div style={couponStyles}>
					{updates.map((item, index) => {
						return <UpdateCard key={index} update={item} onUpdate={() => loadUpdates()} />
					})}
				</div>

				{ modalShow && <UploadResource type={modalType} show={modalShow} onHide={() => { setModalShow(false); loadUpdates() }} /> }
			</div>
		</>
	)
}

export default Updates;