import React, { CSSProperties, useState } from "react";
import { Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { api } from "../../../api/prep";
import { updateSessionAuth } from "../../../api/sessions";
import keyAnim from "../../../assets/keyAnim.gif";
import logo from "../../../assets/logo.png";
import app_logo from "../../../assets/app-logo.png";
import { ROUTES } from "../../../config/routes";
import { authSet, publicUserEntitySetAction, urlRefSetAction } from "../../../store/actions";
import { UrlRefState } from "../../../store/reducers/urlRefReducer";
import { ListaColors } from "../../../styles/colors";
import { getUrlRoute } from "../../../utils";
import { APP_NAME } from "../../../utils/globals";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import "./index.scss";
import i18n from "../../../i18n/config";


interface ISecurityKeyCard {
	authSet: typeof authSet
	publicUserEntitySetAction: typeof publicUserEntitySetAction
	urlRef: UrlRefState
	urlRefSetAction: typeof urlRefSetAction
}

const cardChild: CSSProperties = {
	alignSelf: "center",
	marginLeft: "4rem",
	marginRight: "4rem",
	textAlign: "center"
};

const gifAnim: CSSProperties = {
	...cardChild,
	width: "150px"
};

const SecurityKeyCard: React.ComponentType<ISecurityKeyCard> = (props): JSX.Element => { // eslint-disable-line no-undef
	const [submittedKey, setSubmittedKey] = useState(false);
	const [key, setKey] = useState("");
	const history = useHistory();

	const keyEntered = (event) => {
		if (event.target.value.length === 3136) {
			setKey(event.target.value);
			setSubmittedKey(true);
		}
	};

	const handlePublic = () => {
		let newRoute = "";
		if (props.urlRef) {
			newRoute = getUrlRoute(props.urlRef);
		}

		updateSessionAuth({token: key, isTemp: true});
		api.saveKey(key);
		document.cookie = "physical=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		props.urlRefSetAction("");
		history.push(newRoute ? newRoute : ROUTES.MAIN.path);
	};

	const handlePrivate = () => {
		let newRoute = "";
		if (props.urlRef) {
			newRoute = getUrlRoute(props.urlRef);
		}

		updateSessionAuth({isTemp: false, token: key});
		api.saveKey(key);
		// set a cookie with the physkey
		const d = new Date();
		// set cookie to expire in 30 days
		d.setTime(d.getTime() + ((30)*24*60*60*1000));
		const cookiePath = `physical=${key}; expires=${d.toUTCString()}; path=/;`
		document.cookie = document.cookie = cookiePath;
		props.urlRefSetAction("");
		history.push(newRoute ? newRoute : ROUTES.MAIN.path);
	};

	const SecurityKeyBody = (): JSX.Element => { // eslint-disable-line no-undef
		return (
			<>
				<p style={cardChild}>{i18n.t("Please insert your security key then place your finger on the button on the security key")}</p>

				<Form.Group className="loginField">
					<Form.Control id="securityKeyField" type="password" placeholder={i18n.t("Security Key")} onInput={keyEntered} />
				</Form.Group>

				<img src={keyAnim} alt="Security Key Animation" style={gifAnim} />
			</>
		)
	};

	const DeviceTypeBody = (): JSX.Element => { // eslint-disable-line no-undef
		const privateButton: CSSProperties = {
			width: "40%"
		};

		const publicButton: CSSProperties = {
			width: "40%",
			backgroundColor: ListaColors.gray,
			borderColor: ListaColors.gray,
			marginBottom: "2rem"
		};

		const hideCheckmark: CSSProperties = {
			display: "none"
		};

		const showCheckmark: CSSProperties = {
			display: "block"
		};

		return (
			<>
				<p style={cardChild}>{i18n.t("Are you using a public computer? If not, do you want to save this key on this computer?")}</p>

				<PrimaryButton onClick={handlePrivate} style={privateButton}>
				{i18n.t("Yes, this is a safe computer")}
				</PrimaryButton>

				<PrimaryButton onClick={handlePublic} style={publicButton}>
				{i18n.t("No, this is a public computer")}
				</PrimaryButton>

				<div className={"circle-loader " + (submittedKey ? "load-complete" : "")} style={cardChild}>
					<div className="checkmark draw" style={(submittedKey ? showCheckmark : hideCheckmark)}> </div>
				</div>
			</>
		)
	};

	return (
		<>
			<Card body={true} className="loginCard" >
				<img src={app_logo} alt="Logo" style={cardChild}/>
				<h2 style={cardChild}>{APP_NAME}</h2>

				{submittedKey ? <DeviceTypeBody /> : <SecurityKeyBody />}
			</Card>
		</>
	);
};

const mapStateToProps = ({ urlRef }) => ({ urlRef });
export default connect(mapStateToProps, {
	authSet,
	publicUserEntitySetAction,
	urlRefSetAction
})(SecurityKeyCard);