import React, { CSSProperties, useEffect, useState } from "react";
import { IUser } from "../../api/models/user";
import { api } from "../../api/prep";
import { UserState } from "../../store/reducers/userReducer";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { OrNull } from "../../typings";
import UserEngagementCard from "../../components/Cards/UserEngagementCard/UserEngagementCard";
import "./index.scss";
import { selectedUserSetAction } from "../../store/actions";
import { dateGroupBy } from "../../utils/index";
import { INoteWithKey } from "../../api/models/note";
import { IReminderNotification, isReminderNotification } from "../../api/models/reminders/reminderNotification";
import { IAdherence } from "../../api/models/adherence";
import AdherenceCard from "../../components/Cards/AdherenceCard/AdherenceCard";
import { IMedication } from "../../api/models/medication";
import { isMedicationReminder } from "../../api/models/reminders/medicationReminder";
import { Spinner } from "react-bootstrap";
import i18n from "../../i18n/config";

interface IFeedProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

const Feed: React.ComponentType<IFeedProps> = (props: IFeedProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [user, setUser] = useState<OrNull<IUser>>(props.user);
	const [loading, setLoading] = useState<boolean>(false);
	const [feed, setFeed] = useState<(INoteWithKey | IReminderNotification)[]>([]);
	const [chartData, setChartData] = useState<IAdherence[]>([]);
	const [userMedications, setUserMedications] = useState<IMedication[]>([]);

	const getData = (usr: IUser) => {
		api.generateFeed(usr.id).then(res => {
			setFeed(res);
		});
		api.getAdherence(usr.id).then(adherence => {
			// console.log("adherence:: ", adherence);
			setChartData(adherence);
		});
		api.getMedications(usr.id).then(medications => {
			setUserMedications(medications);
			// console.log(userMedications);
		});
	}

	useEffect(() => {
		setLoading(true);

		// User might be null if user directly navigated to this url. Query user if that happens.
		if (!props.user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				if (usr) {
					getData(usr);
					setLoading(false);
				}
			});
		} else {
			getData(props.user);
			setLoading(false);
		}
	}, []); // eslint-disable-line

	const getMedication = (reminder: any) => {
		if(isMedicationReminder(reminder)) {
			return userMedications?.filter((m) => {
				return m.id === reminder.medicationId;
			})[0];
		}
		return null;
	}

	// Sort API response to return data grouped by date
	const sortedFeed = dateGroupBy(feed);
	// console.log(userMedications);
	const today = new Date();
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const getDateHeader = (header: string): string => {
		const months: string[] = [i18n.t("January"), i18n.t("February"), i18n.t("March"), i18n.t("April"), i18n.t("May"), i18n.t("June"), i18n.t("July"), i18n.t("August"), i18n.t("September"), i18n.t("October"), i18n.t("November"), i18n.t("December")];

		if(header === `${months[today.getMonth()]} ${today.getDate()}`) {
			return i18n.t("Today");
		} else if(header === `${months[yesterday.getMonth()]} ${yesterday.getDate()}`) {
			return i18n.t("Yesterday");
		}
		return header.charAt(0).toUpperCase() + header.slice(1);
	} 

	return(
		<>
			<div id="userSpinner" hidden={!loading}>
					<Spinner animation="border" role="status" />
			</div>
			{
				!loading &&
				<div className="feedStyles-notifications">
					{Object.entries(sortedFeed).map(([key, value]) => {
						return (
							<div key={key} className="cardGroup">
								{value.length !== 0 && <h6 className="displayTime">{getDateHeader(key)}</h6>}
								<div>
									{value.map((item, itemKey) => {
										return (
											item !== undefined && <UserEngagementCard user={user} notification={item} key={itemKey} medication={isReminderNotification(item) ?  getMedication(item.reminder) : null}/>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			}
		</>
	)
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Feed);

/*

					{Object.keys(sortedFeed).map((date, dateKey) => {
						return (
							<div key={dateKey} className="cardGroup">
								{sortedFeed[date].length !== 0 && <h6 className="displayTime">{getDateHeader(date)}</h6>}
								<div>
									{sortedFeed[date].map((item, itemKey) => {
										return (
											item !== undefined && <UserEngagementCard user={user} item={item} key={itemKey} medication={isReminderNotification(item) ?  getMedication(item.reminder) : null}/>
										)
									})}
								</div>
							</div>
						)
					})}
*/