// Fetch data from server and export as csv.

// ***** Utils *****

/**
 * Replace all instances of a separator with escaped version (surrounded by double quotes)
 * @param content
 * @param separator
 */
const escapeSeparators = (content, separator: string) => {
	if (content === null || content === undefined) {
		return "";
	}
	return String(content).replace(/"/g, "\"\"");
}

const formulaForArrays = (value, replacer) => {
	if ((JSON.stringify(value, replacer).replace(/null/g, "0").replace(/\[|\]|/g, "").replace(/,/g, "&CHAR(10)&")).length === 0) {
		return "";
	}
	return "=" + JSON.stringify(value, replacer).replace(/null/g, "0").replace(/\[|\]|/g, "").replace(/,/g, "&CHAR(10)&")
}

/**
 * Create a line of CSV (ends with a separator, all separator characters are escaped out)
 */
const makeCSVItem = (content: string, separator: string) => {
	return "\"" + escapeSeparators(content, separator) + "\"" + separator;
}

// ***** Main Function *****


export const exportData = (filename: string, fields: string[], data: any[]) => {
	const separator = ",";

	console.log("Exporting data...");
	const replacer = (key, value) => value === null ? "" : (value != undefined && value.seconds != null ? value.seconds : value);
	// console.log(fields);
	const csvData = [
		fields.join(separator),
		...data.map((item) => fields.map((fieldName) => typeof item[fieldName] != "object" ? JSON.stringify(item[fieldName], replacer) : formulaForArrays(item[fieldName], replacer)).join(separator))
	].join("\r\n")
	const filenameWithExtension = filename + ".csv" || "export.csv";

	const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) { // IE 10+
		navigator.msSaveBlob(blob, filenameWithExtension);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) { // feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filenameWithExtension);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}