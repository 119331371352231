import React, { CSSProperties} from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IClinic } from "../../../api/models/clinics";
import "./index.scss";
import i18n from "../../../i18n/config"
import { ReactSVG } from "react-svg";
import edit from "../../../assets/ic_edit.svg";
import deleteIcon from "../../../assets/ic_delete.svg";
import ManageClinic from "../../Modals/ManageClinic/ManageClinic";
import { IGroupMessages } from "../../../api/models/groupMessages";



interface IGroupMessagesCardProps {
	groupMessage: IGroupMessages,
    onAction: () => void
    customKey: string
    message: string
    patientList: []
    gender: string
    adherence: string
    createdAt: string
    createdBy: string
    adminName: string
    status: string
    refClinicList: IGroupMessages[]
}

export const clinicNameStyles: CSSProperties = {
	fontSize: "16px",
	fontWeight: 500,
	marginBottom: 0
}

export const clinicIdStyle: CSSProperties = {
	color: "rgba(0, 0, 0, 0.6)",
	fontSize: "12.8px"
}

export const clinicCodeStyle: CSSProperties = {
	color: "rgba(0, 0, 0, 0.6)",
	fontWeight: "bold"
}

const disabled: CSSProperties = {
	fontSize: "16pt",
	fontWeight: 500,
	color: "rgb(241, 123, 119)",
	lineHeight: 1,
	/*marginBottom: 0,
	marginTop: 0,*/
	background: "#DDDDDD",
	justifyContent: "flex-start"
}

const left: CSSProperties = {
	alignSelf: "flex-start"
}

const GroupMessagesCard: React.ComponentType<IGroupMessagesCardProps> = (props: IGroupMessagesCardProps) => {
	const history = useHistory();
	// const [totalAdherence, setTotalAdherence] = useState<number>(0);

    const [editModalShow, setEditModalShow] = React.useState(false);
    const [deleteModalShow, setDeleteModalShow] = React.useState(false);


	const handleClick = (uId: string) => {
		// props.selectedUserSetAction(props.user);
		// history.push(ROUTES.FEED.path.replace(":userId", uId));
	};

    let displayAdherenceText = function(adherenceType: string) : string {
        if (adherenceType === "less50") {
            return  i18n.t("49% or Less");
        } else if(adherenceType === "more80") {
            return  i18n.t("81% or More");
        } else if(adherenceType === "all-adherence"){
            return i18n.t("All");
        } else {
            return  i18n.t("50% - 80%");
        }
    }

    let genderTranslation = function(gender: string) : string {
        if(gender === "Femenino" || gender === "Female" || gender === "Cis-Female" || gender === "Mujer Cis"){
            return i18n.t("Cis-Female");
        }
    
        if(gender === "Masculino" || gender === "Male" || gender === "Cis-Male" || gender === "Hombre Cis"){
            return i18n.t("Cis-Male");
        }
    
        if(gender === "Hombre Trans" || gender === "Transmale"){
            return i18n.t("Transmale");
        }
    
        if(gender === "Mujer Trans" || gender === "Transfemale"){
            return i18n.t("Transfemale");
        }
    
        if(gender === "No Binario" || gender === "Non-binary"){
            return i18n.t("Non-binary");
        } 
    
        if(gender === "Other" || gender === "Otro"){
            return i18n.t("Other");
        } 
    
        if(gender === "Prefer not to say" || gender === "Prefiero no decirlo"){
            return i18n.t("Prefer not to say");
        } 
    
        return i18n.t("All");
    }

    type DateTimeFormatOptions = Intl.DateTimeFormatOptions;
    const dateFormat = (obj: any): string => {
        const now: Date = new Date(0);
        const dateOptions: DateTimeFormatOptions = {
            minute: "numeric",
            hour: "numeric",
            month: "short",
            day: "numeric",
            year: "numeric"
        };
    
        // Attempt to grab datetime props from obj
        const latestTime: number = obj 
        const seconds: number = obj.seconds
        const nano: number = obj.nanoseconds
            
    
        // Get final date obj for formatting
        const latestDate: Date = latestTime
            ? new Date(seconds*1000 + nano/1000000)
            : now;
        

    
        return latestDate.toLocaleDateString("en-US", dateOptions);
    };

	return (
        <>
            <Card key={props.customKey} 
            // className={"groupMsgCard"}
                style={{width: "698px", display: "flex", marginBottom: "24px", marginTop: "16px", marginRight: "40px", marginLeft: "40px"}}
             onClick={() => handleClick(props.groupMessage.id)}>
                <Card.Body style={{marginLeft: "0px", marginBottom: "24px"}}>
                    {/* <Container> */}
                    <Row>
                            <Col xs={8}>
                            {i18n.t("Created by ") + props.adminName + i18n.t(" at ") + dateFormat(props.createdAt)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                            {i18n.t("Gender: ") + genderTranslation(props.gender)}
                            </Col>
                            <Col xs={4}>
                            {i18n.t("Adherence: ") + displayAdherenceText(props.adherence)}                              
                            </Col>
                            <Col>
                            {props.patientList.length > 1 ? i18n.t("Sent to ") + (props.patientList.length).toString() + i18n.t(" patients") : i18n.t("Sent to ") + (props.patientList.length).toString() + i18n.t(" patient" ) }
                            </Col>
                        </Row>
                        {/* <Row className="enabled">
                            <Col>
                            {props.patientList.length > 1 ? "Sent to " + (props.patientList.length).toString() + " patients" : "Sent to " + (props.patientList.length).toString() + " patient" }
                            </Col>
                        </Row> */}
                        <Row className="enabled" style={{marginTop: "10px"}}>
                            <Col>
                            {i18n.t("Message: ")}
                            </Col>
                        </Row>
                        <Row className="enabled">
                            {/* <div style={{display: "flex", width: "650px", height:"100px", flexDirection: "row", marginBottom: "24px", marginRight: "0px", marginLeft: "20px"}}> */}
                            <div className="messageBox_" style={{border: "solid gray 0.05rem"}}>
                                <div className="messageBody_">{props.message}</div>
                            </div>
                        </Row>
                    {/* </Container> */}
                </Card.Body>
                <Card.Footer className="colorFooter_" style={{borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                    {i18n.t("Message Status: ")} 
                     {props.status === "Completed" ? i18n.t("Completed") : i18n.t("Pending")}
                </Card.Footer>
        
            </Card>
        </>
    )
}

export default connect(null, {})(GroupMessagesCard);
