import React, { CSSProperties, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { IUser } from "../../../api/models/user";
import eventIcon from "../../../assets/ic_event.svg";
import { ROUTES } from "../../../config/routes";
import { selectedUserSetAction } from "../../../store/actions";
import { lastSeen } from "../../../utils/index";
import AdherenceCircleGraph from "../../TotalAdherence/TotalAdherence";
import "./index.scss";
import { api } from "../../../api/prep";
import i18n from "../../../i18n/config"

interface IUserCardProps {
	selectedUserSetAction: typeof selectedUserSetAction
	user: IUser
	customKey: string
}

export const userNameStyles: CSSProperties = {
	fontSize: "16px",
	fontWeight: 500,
	marginBottom: 0
}

const stepsContainer: CSSProperties = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center"
}

const stepsNumber: CSSProperties = {
	marginBottom: 0,
	fontSize: "18pt",
	fontWeight: 500
}

export const userIdStyle: CSSProperties = {
	color: "rgba(0, 0, 0, 0.6)",
	fontSize: "12.8px"
}

const lastActive: CSSProperties = {
	color: "rgba(0, 0, 0, 0.38)",
	marginBottom: 0,
	marginTop: "1rem",
	fontSize: "12.8px"
}

const disabled: CSSProperties = {
	fontSize: "16pt",
	fontWeight: 500,
	color: "rgb(241, 123, 119)",
	lineHeight: 1,
	/*marginBottom: 0,
	marginTop: 0,*/
	background: "#DDDDDD",
	justifyContent: "flex-start"
}

const left: CSSProperties = {
	alignSelf: "flex-start"
}

const UserCard: React.ComponentType<IUserCardProps> = (props: IUserCardProps) => {
	const history = useHistory();
	const [totalAdherence, setTotalAdherence] = useState<number>(0);

	const handleClick = (uId: string) => {
		props.selectedUserSetAction(props.user);
		history.push(ROUTES.FEED.path.replace(":userId", uId));
	};

	useEffect( () => {
		// TODO: Remove when done. This is for User "Harry P" who has 1 adherence confirmation for 8/10. This check is just
		//  to cut down on log spam while debugging
		//if (props.user.id === "OCqAMiF265NBSOHKuj6xRyKsSrQ2") {
			api.getTotalAdherence(props.user.id)
				.then((adherence) => {
					setTotalAdherence(adherence);
				})
				.catch(console.error);
		//}
	}, [])

	const enabled = (isEnabled: boolean): JSX.Element => {

		switch(isEnabled) {
			case true: {
				return (
					<>
					<Card key={props.customKey} className="userEnabledCard clickable" onClick={() => handleClick(props.user.id)}>
						<Card.Body className="user-body">
							<Container>
								<Row className="enabled">
									<Col xs={8}>
										{
											props.user.firstName && props.user.lastName ?
												<p style={userNameStyles}>{props.user.firstName} {props.user.lastName}</p> :
												<p style={userNameStyles}>{i18n.t("No Set Name")}</p>
										}
										<p style={userIdStyle}>{props.user.id}</p>
										{props.user.updatedAt && <p style={lastActive}>{i18n.t("Last seen")} {lastSeen(props.user.updatedAt)}</p>}
									</Col>
									<Col xs={4}>
										{<AdherenceCircleGraph adherence={totalAdherence} width="80px" fontSize="24px"/>}
									</Col>
									
								</Row>
								{props.user.lastAction || props.user.enabled === false ? <Card.Footer className="user-footer">
									<Row>
										<Col xs={2}><ReactSVG src={eventIcon} /></Col>
										<Col xs={10} style={{ textAlign: "left" }}><p>{i18n.t("User skipped a medication")}</p></Col>
									</Row>
								</Card.Footer>: ""}
							</Container>
						</Card.Body>
					</Card>
					</>
				)
			}
			case false: {
				return (
					<Card body={true} className="userDisabledCard clickable" onClick={() => handleClick(props.user.id)}  style={disabled}>
						<div>
							{
								props.user.firstName && props.user.lastName ?
									<p style={userNameStyles}>{props.user.firstName} {props.user.lastName}</p> :
									<p style={userNameStyles}>{i18n.t("No Set Name")}</p>
							}
							<p className="disabled-id">{props.user.id}</p>
							<p className="disabled-label">{i18n.t("Disabled")}</p>
						</div>
					</Card>
				)
			}
			default: {
				return <div>{i18n.t("There are no users.")}</div>
			}
		}
	};

	return(
		<>
			{props.user.enabled !== undefined ? enabled(props.user?.enabled || false) : enabled(true)}
		</>
	)
}

export default connect(null, {
	selectedUserSetAction
})(UserCard);
