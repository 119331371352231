export const firebaseConfig = {
	apiKey: "AIzaSyA9pxdJ6-kMihvrcR5v7txifVY3SI8tMNU",
	authDomain: "prepii-development.firebaseapp.com",
	projectId: "prepii-development",
	storageBucket: "prepii-development.appspot.com",
	messagingSenderId: "1020099107510",
	appId: "1:1020099107510:web:968addc884e59a3b61e417",
	measurementId: "G-KWR2M19XQ2"
};

export const firebaseProdConfig = {
	apiKey: "AIzaSyApX_kfmNYnqcLsdf4b5OFqHU2Ko1hYF1I",
	authDomain: "prepii.firebaseapp.com",
	projectId: "prepii",
	storageBucket: "prepii.appspot.com",
	messagingSenderId: "335696133784",
	appId: "1:335696133784:web:6bb136a1e2207b7729daed",
	measurementId: "G-5V22EE1SWW"
}