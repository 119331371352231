import React, { CSSProperties, useEffect, useState } from "react";
import { UserState } from "../../store/reducers/userReducer";
import "./index.scss";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import ChatCard from "../../components/Cards/ChatCard/ChatCard";
import { IMessage, IPrivateMessageUserEntity, IPrivateUserEntity } from "../../api/models/message";
import { api } from "../../api/prep";
import { selectedUserSetAction, publicUserEntitySetAction } from "../../store/actions";
import {
	createMessageUserEntity,
	createPrivateUserEntity,
	encodeImage,
	getChatBreakDate,
	normalizeDate,
	uploadMessageFile
} from "../../utils";
import { OrUndefined } from "../../typings";
import attach from "../../assets/ic_attach.svg";
import send from "../../assets/ic_send.svg";
import { ReactSVG } from "react-svg";
import { ListaColors } from "../../styles/colors";
import { PublicUserState } from "../../store/reducers/publicUserReducer";
import firebase from "../../index";
import { IUser } from "../../api/models/user";
import ChatImageCard from "../../components/Cards/ChatImageCard/ChatImageCard";
import { BOT_NAME } from "../../utils/globals";
import mascot from "../../assets/ic_chat_mascot.svg";
import i18n from "i18next";

interface IChatProps {
	user: UserState,
	publicUser: PublicUserState,
	selectedUserSetAction: typeof selectedUserSetAction,
	publicUserEntitySetAction: typeof publicUserEntitySetAction
}

const lineStyle: CSSProperties = {
	backgroundColor: "rgba(0, 0, 0, 0.26)",
	height: "1px"
}

const dateDividerStyle: CSSProperties = {
	color: "rgba(0, 0, 0, 0.26)"
}

const inputGroupStyle: CSSProperties = {
	boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
	borderRadius: "28px"
}

const inputStyle: CSSProperties = {
	borderLeftWidth: "0",
	borderRightWidth: "0",
	borderColor: `${ListaColors.secondaryLight}`
}

const btnStyle: CSSProperties = {
	border: `1px solid ${ListaColors.secondaryLight}`
}

const leftBtnStyle: CSSProperties = {
	...btnStyle,
	borderRadius: "28px 0 0 28px",
	borderRightWidth: "0"
}

const rightBtnStyle: CSSProperties = {
	...btnStyle,
	borderRadius: "0 28px 28px 0",
	borderLeftWidth: "0"
}

const chatRenderStyle: CSSProperties = {
	height: "70vh",
	overflow: "scroll",
	marginBottom: "16px"
	/*scrollBehavior: "smooth"*/
}

const disabled: CSSProperties = {
	display: "none"
}

const Chat: React.ComponentType<IChatProps> = (props: IChatProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [messages, setMessages] = useState<IMessage[]>([]);
	const [bottomMargin, setBottomMargin] = useState<number>(0);
	const [input, setInput] = useState<string>("");
	let chatDate: OrUndefined<Date>;
	const currUsrId: OrUndefined<string> = firebase.auth().currentUser?.uid;

	// TODO: This is probably going to change to accommodate the firebase live listening thing
	useEffect(() => {
		if (props.user) {
			api.listenMessages(props.user.id, setMessages).then((items) => {
				// The chat input is position:sticky which is great but only works if content overflows
				const chatContainer = document.querySelector("#chatContainer") as HTMLElement;
				const mainContent = document.querySelector("#mainContent") as HTMLElement;
				const btmMrg = Math.abs(mainContent.scrollHeight - chatContainer.scrollHeight - 100);
				setBottomMargin(btmMrg);
				if (!messages || messages === []) {
					setMessages([]);
				}
			}).catch(err => {
				console.error("Error getting messages::", err);
			});
		} else {
			api.getUser(userId).then((usr) => {
				// Set the user in Redux
				props.selectedUserSetAction(usr);
			});
		}
	}, [props.user]); // eslint-disable-line

	// Scroll to bottom of chat container whenever messages is updated
	useEffect(() => {
		const chatContainer = document.querySelector("#chatRegion") as HTMLElement;
		chatContainer.scrollTop = chatContainer.scrollHeight;
	}, [messages]);

	const handleMessageChange = (event) => {
		setInput(event.target.value);
	};

	const handleFileChange = async (event) => {
		const firstFile = event.target.files[0];
		let base64Content: string = await encodeImage(firstFile);
		if (props.user && props.publicUser && base64Content && props.user.id) {
			const thread = createMessageUserEntity(props.user,  props.user.id);
			const content = await uploadMessageFile(firstFile, props.user.id);
			const contentPath = await content.ref.getDownloadURL();
			api.sendMessage(props.user.id, thread, props.publicUser, contentPath, "image")
				.then((sentMsg) => {
					// TODO: Remove this, the live listening firebase setup should handle this for us
					setMessages(messages.concat([sentMsg]));
				})
				.catch(console.error);
		} else if (props.user && base64Content) {
			// If we're here, it means saving their private user entity to redux failed during login.
			// We can try to re-generate and save it now.
			if (currUsrId) {
				api.getUser(currUsrId)
					.then((currUsr) => {
						const fromUser: IPrivateMessageUserEntity = createMessageUserEntity(currUsr, currUsrId);
						const thread: IPrivateMessageUserEntity = createMessageUserEntity(props.user as IUser, currUsrId); // It's not null, I'm checking in the parent else-if
						// Save it to redux so we don't have to do this again later
						props.publicUserEntitySetAction(fromUser as IPrivateUserEntity);

						api.sendMessage(currUsrId, thread, fromUser, base64Content, "image")
							.then((sentMsg) => {
								// TODO: Remove this, the live listening firebase setup should handle this for us
								setMessages(messages.concat([sentMsg]));
							})
							.catch(console.error);
					})
					.catch(console.error);
			}  else if (props.publicUser && base64Content) {
				// This shouldn't be possible, but adding logging just in case
				console.error("Attempted to send an attachment before the thread was inflated.")
			}
		}
	}

	const nukeFileInput = (event) => {
		// Nuke the input's value so handleFileChange() will fire if you try to upload the same file twice,
		// side note, if that behavior is not desired, can just remove this function and it'll stop the user from
		// sending the same file more than once.
		event.target.value = "";
	}

	const postMessage = (type: string, content: string) => {
		if (props.user && props.publicUser && content && props.user.id) {
			const thread = createMessageUserEntity(props.user, props.user.id);
			const pubUser = {
				id: props.publicUser.id || "",
				displayName: props.publicUser.displayName || "Admin",
				profileUrl: props.publicUser.profileUrl || "",
			}
			api.sendMessage(props.user.id, thread, pubUser, content, type)
				.then((sentMsg) => {
					// TODO: Remove this, the live listening firebase setup should handle this for us
					setMessages(messages.concat([sentMsg]));

					// Clear message state
					setInput("");
				})
				.catch(console.error);
		} else if (props.user && content) {
			// If we're here, it means saving their private user entity to redux failed during login.
			// We can try to re-generate and save it now.
			if (currUsrId) {
				api.getUser(currUsrId)
					.then((currUsr) => {
						const fromUser: IPrivateMessageUserEntity = createMessageUserEntity(currUsr, currUsrId);
						const thread: IPrivateMessageUserEntity = createMessageUserEntity(props.user as IUser, currUsrId); // It's not null, I'm checking in the parent else-if
						// Save it to redux so we don't have to do this again later
						// props.publicUserEntitySetAction(fromUser);

						api.sendMessage(currUsrId, thread, fromUser, content, type)
							.then((sentMsg) => {
								// TODO: Remove this, the live listening firebase setup should handle this for us
								setMessages(messages.concat([sentMsg]));

								// Clear message state
								setInput("");
							})
							.catch(console.error);
					})
					.catch(console.error);
			}
		} else if (props.publicUser && content) {
			// This shouldn't be possible, but adding logging just in case
			console.error("Attempted to send a message before the thread was inflated.")

			// Clear message state
			setInput("");
		}

	}

	const sendAttachment = () => {
		const attachmentInput = document.querySelector("#attachment") as HTMLElement;
		attachmentInput.click();
	}

	const sendMessage = () => {
		postMessage("text", input);
	}

	const messagesRender = (): JSX.Element => {
		if (messages.length !== 0) {
			return (
				<Container>
					{messages.map((message, index) => {
						//
						const isStaff = message.from && (message.from.id !== props.user?.id)
						const isBot = message.from && (message.from.id === BOT_NAME)
						const isActiveStaff = message.from && (message.from.id === props.publicUser?.id)
						const classes = isStaff ? "chatRow justify-content-end" : "chatRow";
						let insertDateBreak = false;

						if (chatDate) {
							if (normalizeDate(message.createdAt.toDate()) > normalizeDate(chatDate)) {
								chatDate = message.createdAt.toDate();
								insertDateBreak = true;
							}
						} else {
							chatDate = message.createdAt.toDate();
						}

						return (
							<Container key={index}>
								{insertDateBreak &&
									<Row className="chatRow">
										<Col style={lineStyle} className="align-self-center"> </Col>
										<Col xs={"auto"} style={dateDividerStyle}>{getChatBreakDate(chatDate)}</Col>
										<Col style={lineStyle} className="align-self-center"> </Col>
									</Row>
								}
								<Row className={classes}>
									<Col xs={"auto"} className="chatCol">
										{message.type === "text"
											? <ChatCard message={message} isStaffMessage={isStaff} isBotMessage={isBot} isActiveStaffMessage={isActiveStaff}/>
											: <ChatImageCard message={message} isStaffMessage={isStaff} isActiveStaffMessage={isActiveStaff}/>
										}
									</Col>
								</Row>
							</Container>
						);
					})}
				</Container>
			);
		} else {
			return (
				<Container style={{ textAlign: "center", display:"flex", justifyContent: "center" }}>
					<ReactSVG src={mascot} className="var-margin" style={{ alignSelf: "center" }}/>
				</Container>
			);
		}
	}

	if(props.user != undefined && props.user.enabled != undefined){
		
		switch(props.user.enabled) {
			case true: {
				return (
					<Container id="chatContainer" className="chatContainer">
					<Row id="chatRegion" style={chatRenderStyle}>
						{messagesRender()}
					</Row>

			
					<Row className="chatInputContainer">
						<InputGroup size="lg" style={inputGroupStyle}>
							<InputGroup.Prepend>
								{/*<Form.File id="attachment" hidden onChange={handleFileChange} onClick={nukeFileInput}/>*/}
								{/*<Button id="attachmentBtn" className="btnHover" style={leftBtnStyle} onClick={sendAttachment}><ReactSVG src={attach} /></Button>*/}
								<Button id="attachmentBtn" className="btnHover" style={leftBtnStyle}/>
							</InputGroup.Prepend>
							<Form.Control className="chatInput" style={inputStyle} type="text" onChange={handleMessageChange} value={input} placeholder={i18n.t("Type a message")}/>
							<InputGroup.Append>
								<Button className="btnHover" onClick={sendMessage} style={rightBtnStyle}><ReactSVG src={send} /></Button>
							</InputGroup.Append>
						</InputGroup>
					</Row>
				</Container>
				)
			}
			case false: {
				return (
					<Container id="chatContainer" className="chatContainer">
					<Row id="chatRegion" style={chatRenderStyle}>
						{messagesRender()}
					</Row>

					<Row className="chatInputContainer" style={disabled}>
						<InputGroup size="lg" style={inputGroupStyle}>
							<InputGroup.Prepend>
								{/*<Form.File id="attachment" hidden onChange={handleFileChange} onClick={nukeFileInput}/>*/}
								{/*<Button id="attachmentBtn" className="btnHover" style={leftBtnStyle} onClick={sendAttachment}><ReactSVG src={attach} /></Button>*/}
								<Button id="attachmentBtn" className="btnHover" style={leftBtnStyle}/>
							</InputGroup.Prepend>
							<Form.Control className="chatInput" style={inputStyle} type="text" onChange={handleMessageChange} value={input} placeholder={i18n.t("Type a message")}/>
							<InputGroup.Append>
								<Button className="btnHover" onClick={sendMessage} style={rightBtnStyle}><ReactSVG src={send} /></Button>
							</InputGroup.Append>
						</InputGroup>
					</Row>

				</Container>
				)
			}
		}
	} else {
		return (
			<Container id="chatContainer" className="chatContainer">
			<Row id="chatRegion" style={chatRenderStyle}>
				{messagesRender()}
			</Row>

			<Row className="chatInputContainer">
				<InputGroup size="lg" style={inputGroupStyle}>
					<InputGroup.Prepend>
						{/*<Form.File id="attachment" hidden onChange={handleFileChange} onClick={nukeFileInput}/>*/}
						{/*<Button id="attachmentBtn" className="btnHover" style={leftBtnStyle} onClick={sendAttachment}><ReactSVG src={attach} /></Button>*/}
						<Button id="attachmentBtn" className="btnHover" style={leftBtnStyle}/>
					</InputGroup.Prepend>
					<Form.Control className="chatInput" style={inputStyle} type="text" onChange={handleMessageChange} value={input} placeholder={i18n.t("Type a message")}/>
					<InputGroup.Append>
						<Button className="btnHover" onClick={sendMessage} style={rightBtnStyle}><ReactSVG src={send} /></Button>
					</InputGroup.Append>
				</InputGroup>
			</Row>

		</Container>
		)
	}
};

const mapStateToProps = ({ user, publicUser }) => ({ user, publicUser });
export default connect(mapStateToProps, {
	selectedUserSetAction,
	publicUserEntitySetAction
})(Chat);