import { APP_ROLES } from "./roles"

export interface IRoute {
	name: string
	path: string
	hasRole?: string[]
}

export interface IAppRouteNames {
	MAIN: IRoute
	UPDATES: IRoute
	ADHERENCE: IRoute
	PROFILE: IRoute
	FEED: IRoute
	CHAT: IRoute
	NOTES: IRoute
	DIARY: IRoute
	USAGE: IRoute
	CLINICS: IRoute
	GROUPS: IRoute
	APPOINTMENT: IRoute
	CLINIC_MESSAGES: IRoute
}

export interface IAuthRoutes {
	FORGOT_PASSWORD: IRoute
	LOGIN: IRoute
	SECURITY_KEY: IRoute
}

export interface IRouteNames extends IAppRouteNames, IAuthRoutes {
}

export const APP_ROUTES: IAppRouteNames = {
	MAIN: {
		name: "main",
		path: "/",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	UPDATES:	{
		name: "updates",
		path: "/updates",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	ADHERENCE:	{
		name: "adherence",
		path: "/users/:userId/adherence",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	PROFILE:	{
		name: "profile",
		path: "/users/:userId/profile",
		hasRole:[
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	FEED: {
		name: "feed",
		path: "/users/:userId/feed",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	CHAT: {
		name: "chat",
		path: "/users/:userId/chat",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	NOTES: {
		name: "notes",
		path: "/users/:userId/notes",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	DIARY: {
		name: "diary",
		path: "/users/:userId/myprepnotes",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	USAGE: {
		name: "usage",
		path: "/users/:userId/usage",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	CLINICS: {
		name: "clinics",
		path: "/clinics",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN
		]
	},
	GROUPS: {
		name: "groups",
		path: "/groups",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	APPOINTMENT: {
		name: "appointment",
		path: "/users/:userId/appointment",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	},
	CLINIC_MESSAGES: {
		name: "clinic_messages",
		path: "/clinic-messages",
		hasRole: [ 
			APP_ROLES.SUPER_ADMIN,
			APP_ROLES.CLINIC_ADMIN
		]
	}
};

export const AUTH_ROUTES: IAuthRoutes = {
	SECURITY_KEY: {
		name: "security-key",
		path: "/security-key"
	},
	FORGOT_PASSWORD: {
		name: "forgot-password",
		path: "/forgot-password"
	},
	LOGIN: {
		name: "login",
		path: "/login"
	}
};

export const ROUTES: IRouteNames = {
	...APP_ROUTES,
	...AUTH_ROUTES
};


// Array of auth-route paths
export const AUTH_ROUTER_PATHS: string[] = Object.keys(AUTH_ROUTES)
	.map((key: string) => AUTH_ROUTES[key].path);

// Array of app-route paths
export const APP_ROUTER_PATHS: string[] = Object.keys(APP_ROUTES)
	.map((key: string) => APP_ROUTES[key].path).concat("/");

export const APP_ROUTE_ROLES = (pathName) =>{
	const APP_ROUTE = Object.keys(APP_ROUTES).map((key: string)=> APP_ROUTES[key])
	return APP_ROUTE
}

export const APP_ROUTER_ROLES: string[] = Object.keys(APP_ROUTES)
.map((key: string) => APP_ROUTES[key].hasRole.map((role)=> role.name));
