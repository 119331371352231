import { Simulate } from "react-dom/test-utils";
import { Interface } from "readline";
import RSAKey from "rsa-key";
import {
	aesStringToKey, btoaHex, dataStringToKey,
	decode,
	decryptValue,
	encode,
	encryptValue,
	hash, MapEntry, pemToBinary
} from "./crypto";
// import { TEST_VARS } from "./env";
import { arrayBufferToBase64, arrayBufferToString, base64ToArrayBuffer, stringToArrayBuffer } from "./platform-cryptor";
import {
	Badge,
	DecodedUser,
	EncodedUser,
	UNENCRYPTED_FIELD_NAMES,
	UNENCRYPTED_WEIGHT_NAMES,
	User,
	Weight
} from "./types";
// import encrypted = Simulate.encrypted;

export {
	hash, // may be unneeded
	encryptValue,
	decryptValue,
	encode,
	decode
};

/**
 * Helper class for common HIPAApotamus operations.
 */
export class HippoEncrypto {
	public rsaPrivateCryptoKey?: CryptoKey;
	public aesCryptoKey?: CryptoKey;
	public aesIv?: Uint8Array;

	/**
	 * Consumes the RSA Private key and creates a CryptoKey instance of it for web-crypto API usage.
	 * @param {string} rsaPrivateKey - Base64 encoded RSA private PEM key.
	 */
	public loadRSAPrivateKey(rsaPrivateKey: string): Promise<void> {
		const key = new RSAKey(rsaPrivateKey);
		const pkcs8Key = key.exportKey("der", "private", "pkcs8");
		return crypto.subtle.importKey(
			"pkcs8",
			pkcs8Key,
			{
				name: "RSA-OAEP",
				hash: "SHA-512"
			},
			false,
			["decrypt"]
		).then(res => {
			this.rsaPrivateCryptoKey = res;
			console.log("key loaded, success!");
			return;
		}).catch(err => {
			console.error("HippoEncrypto::" + err);
		});
	}

	private static buf2hex(buffer) { // buffer is an ArrayBuffer
		return [...new Uint8Array(buffer)]
			.map(x => x.toString(16).padStart(2, '0'))
			.join('');
	}

	/**
	 * Hashes a field name for an encrypted field, used for looking up encrypted data.
	 * @param {string} fieldName - The plain text english field name (ie firstName, lastName etc.)
	 * @returns {Promise<string>} - The SHA-512 -> Base64 Encoded -> First 20 chars version of field name.
	 */
	public static async getHashedFieldName(fieldName: string): Promise<string> {
		const data = new TextEncoder().encode(fieldName);
		const hashBuffer = await crypto.subtle.digest("SHA-512", data);
		// @ts-ignore
		const hex = this.buf2hex(hashBuffer) //  hashBuffer.toString();

		return hex.substring(0, 20);
	}

	// decrypts the AES key and IV, returns `${key}:${iv}`
	public async rsaDecryptAESKey(encryptedAESKey: string) {
		if (!this.rsaPrivateCryptoKey) {
			throw Error("HippoEncrypto:: No RSA private CryptoKey present.");
		}

		const decoded = base64ToArrayBuffer(encryptedAESKey);

		const alg: RsaOaepParams = { name: "RSA-OAEP" };
		const decryptedTestKey = await crypto.subtle.decrypt(alg, this.rsaPrivateCryptoKey, decoded);
		return (new TextDecoder()).decode(decryptedTestKey);
	}

	// #### DECRYPTION ####

	// NOTE: this function not being used currently
	// take a string containing the AES
	// decrypts a series of  with a key and IV formatted as `${key}:${iv}`
	// optional param "subcollections" if you need to decrypt a user and its
	async aesDecryptUserData(keyAndIv: string, user: Object, subcollections?: boolean): Promise<any> {

		const [keyText, iv] = aesStringToKey(keyAndIv);

		const key = await crypto.subtle.importKey(
			"raw",
			keyText,
			{
				name: "AES-CBC"
			},
			false,
			["encrypt", "decrypt"]
		).catch((e) => console.error("aesDecryptUserData::importKey:: ", e));

		if (!key) {
			throw Error("Invalid key");
		}

		const unhashedFields: string[] = [
			"firstName", //?
			"lastName",
			"age", // ?
			"heightInCm",
			"weightInKg",
			"isMetric", //?
			"ethnicity", //?
			"race", //?
			"phone", 
			"gender",//?
			"birthdate",
			"email",
			"clinic", //?
			"locale" // [en/es]
		];

		let hashedFields: string[] = [];
		let outUser = user as DecodedUser;

		// create unhashed top level fields
		for (const index in unhashedFields) {
			const field = await HippoEncrypto.getHashedFieldName(unhashedFields[index]).catch((e) => console.error("aesDecryptUserData::getHashedFieldName:: ", e));
			if (!field) {
				throw Error("Invalid field hash");
			}
			hashedFields.push(field);
		}

		// decrypt top level fields
		for (const index in hashedFields) {
			const field = hashedFields[index];
			if (user[field]) {
				const unhashedFieldName = unhashedFields[index];

				const [iv, mac, cypherText] = dataStringToKey(user[field]);
				const algorithm = {name: "AES-CBC", iv: iv};

				const resValue = await crypto.subtle.decrypt(algorithm, key, cypherText).catch((e) => console.error("aesDecryptUserData::decrypt:: ", e));
				if (!resValue) {
					console.error(user);
					throw Error(`Failed to decrypt field:: ${field} : ${unhashedFieldName} : user:: ${user}`);
				}
				outUser[unhashedFieldName] = (new TextDecoder()).decode(resValue);
			}
		}

		if (subcollections) {
			const temp = await this.decryptUserSubcollections(user, outUser, key).catch((e) => console.error("aesDecryptUserData::decryptUserSubcollections:: ", e));
			if (!temp) {
				throw Error("Failed to decrypt subcollections");
			}
			outUser = temp;
		}

		return outUser;
	}

	async decryptUserSubcollections(user: Object, outUser: DecodedUser, key: CryptoKey) {
		const unhashedBadgeFields: string[] = [
			"key"
		];

		const unhashedWeightFields: string[] = [
			"weightInLbs"
		];

		let hashedBadgeFields: string[] = [];
		let hashedWeightFields: string[] = [];

		// create unhashed badge fields
		for (const index in unhashedBadgeFields) {
			const field = await HippoEncrypto.getHashedFieldName(unhashedBadgeFields[index]);
			hashedBadgeFields.push(field);
		}
		// create unhashed weight fields
		for (const index in unhashedWeightFields) {
			const field = await HippoEncrypto.getHashedFieldName(unhashedWeightFields[index]);
			hashedWeightFields.push(field);
		}

		// decrypt badge fields
		const decryptedBadges: Badge[] = [];
		for (const index in user["badges"]) {
			for (const fieldIndex in hashedBadgeFields) {
				const hashedFieldName = hashedBadgeFields[fieldIndex];
				const unhashedFieldName = unhashedBadgeFields[fieldIndex];

				if (user["badges"][index][hashedFieldName]) {
					const [iv, mac, cypherText] = dataStringToKey(user["badges"][index][hashedFieldName]);
					const algorithm = {name: "AES-CBC", iv: iv};

					const resValue = await crypto.subtle.decrypt(algorithm, key, cypherText);

					const decryptedBadge = {
						...user["badges"][index],
					};
					decryptedBadge[unhashedFieldName] = (new TextDecoder()).decode(resValue);

					decryptedBadges.push(decryptedBadge);
				}
			}
		}
		outUser.badges = decryptedBadges;

		// decrypt weight fields
		const decryptedWeights: Weight[] = [];
		for (const index in user["weights"]) {
			for (const fieldIndex in hashedWeightFields) {
				const hashedFieldName = hashedWeightFields[fieldIndex];
				const unhashedFieldName = unhashedWeightFields[fieldIndex];

				if (user["weights"][index][hashedFieldName]) {
					const [iv, mac, cypherText] = dataStringToKey(user["weights"][index][hashedFieldName]);
					const algorithm = {name: "AES-CBC", iv: iv};

					const resValue = await crypto.subtle.decrypt(algorithm, key, cypherText);

					const decryptedWeight = {
						...user["weights"][index],
					};
					decryptedWeight[unhashedFieldName] = (new TextDecoder()).decode(resValue);

					decryptedWeights.push(decryptedWeight);
				}
			}
		}
		outUser.weights = decryptedWeights;

		return outUser;
	}

	/**
	 * decryptFields
	 * takes a object with encoded fields and an array of keys,
	 * returns an object with decrypted fields (SingleUser Page)
	 * @param weights
	 * @param keyAndIv
	 * @param unhashedFields
	 */
	async decryptFields(weights: Object[], keyAndIv: string, unhashedFields: string[]): Promise<Object[]> {
		const [keyText, iv] = aesStringToKey(keyAndIv);

		const key = await crypto.subtle.importKey(
			"raw",
			keyText,
			{
				name: "AES-CBC"
			},
			false,
			["encrypt", "decrypt"]
		)

		// decrypt weight fields
		const hashedFields: string[] = [];
		// create unhashed fields
		for (const index in unhashedFields) {
			const field = await HippoEncrypto.getHashedFieldName(unhashedFields[index]);
			hashedFields.push(field);
		}

		const decryptedFields: Object[] = [];
		for (const wgt of weights) {
			const decryptedField = {
				...wgt,
			};

			for (const fieldIndex in hashedFields) {
				const hashedFieldName = hashedFields[fieldIndex];
				const unhashedFieldName = unhashedFields[fieldIndex];

				if (wgt[hashedFieldName]) {
					if (Array.isArray(wgt[hashedFieldName])) {
						const outArray: any[] = [];
						for (const field of wgt[hashedFieldName]) {
							const [iv, mac, cypherText] = dataStringToKey(field);
							const algorithm = {name: "AES-CBC", iv: iv};

							const resValue = await crypto.subtle.decrypt(algorithm, key, cypherText);

							outArray.push(new TextDecoder().decode(resValue));
						}
						decryptedField[unhashedFieldName] = outArray;
					} else { // Value is not an array
						const [iv, mac, cypherText] = dataStringToKey(wgt[hashedFieldName]);
						const algorithm = {name: "AES-CBC", iv: iv};

						const resValue = await crypto.subtle.decrypt(algorithm, key, cypherText);

						decryptedField[unhashedFieldName] = (new TextDecoder()).decode(resValue);
					}
				}
			}
			decryptedFields.push(decryptedField);
		}
		return decryptedFields;
	}

	/**
	 * decryptMessageFields
	 * takes a object with encoded fields and an array of keys,
	 * returns an object with decrypted fields (SingleUser Page).
	 * @param weights
	 * @param keyAndIv
	 * @param unhashedFields
	 * @param unhashedSubFields - include for messages, will be the unhashed fields of the sender/recipient objects
	 */
	async decryptMessageFields(weights: Object[], keyAndIv: string, unhashedFields: string[],
						unhashedSubFields: string[]): Promise<Object[]> {
		const [keyText, integrityKey] = aesStringToKey(keyAndIv);

		const key = await crypto.subtle.importKey(
			"raw",
			keyText,
			{
				name: "AES-CBC"
			},
			false,
			["encrypt", "decrypt"]
		)

		// const hmacKey = await crypto.subtle.importKey(
		// 	"raw",
		// 	integrityKey,
		// 	{
		// 		name: "HMAC",
		// 		hash: {name: "SHA-256"}
		// 	},
		// 	false,
		// 	["verify"]
		// );

		// decrypt weight fields
		const hashedFields: string[] = [];
		// create unhashed fields
		for (const index in unhashedFields) {
			const field = await HippoEncrypto.getHashedFieldName(unhashedFields[index]);
			hashedFields.push(field);
		}

		const decryptedFields: Object[] = [];
		for (const wgt of weights) {
			const decryptedField = {
				...wgt,
			};

			for (const fieldIndex in hashedFields) {
				const hashedFieldName = hashedFields[fieldIndex];
				const unhashedFieldName = unhashedFields[fieldIndex];

				if (wgt[hashedFieldName]) {

					// decrypt subcollections
					// NOTE: this could be written recursively if the function was reworked, but having to declare the
					//  unhashed field names makes it less useful to do so
					if (unhashedSubFields && typeof wgt[hashedFieldName] === "object") {
						const subCollection = {
							...wgt[hashedFieldName]
						};

						const hashedSubFields: string[] = [];
						// create hashed fields
						for (const index in unhashedSubFields) {
							const field = await HippoEncrypto.getHashedFieldName(unhashedSubFields[index]);
							hashedSubFields.push(field);
						}

						// iterate through fields of subcollection
						for (const subFieldIndex in hashedSubFields) {
							const hashedSubFieldName = hashedSubFields[subFieldIndex];
							const unhashedSubFieldName = unhashedSubFields[subFieldIndex];

							if (wgt[hashedFieldName][hashedSubFieldName]) {
								const [subIv, subMac, subCypherText]
									= dataStringToKey(wgt[hashedFieldName][hashedSubFieldName]);

								const subAlgorithm = {name: "AES-CBC", iv: subIv};

								const resValue = await crypto.subtle.decrypt(subAlgorithm, key, subCypherText);

								subCollection[unhashedSubFieldName] = (new TextDecoder()).decode(resValue);
							}
						}
						decryptedField[unhashedFieldName] = subCollection;

					} else {
						const [iv, mac, cypherText] = dataStringToKey(wgt[hashedFieldName]);
						const algorithm = {name: "AES-CBC", iv: iv};


						// // get text for key signature
						// const hmacEncoded = new Uint8Array(String(iv).length + wgt[hashedFieldName].length);
						// hmacEncoded.set(new Uint8Array(iv));
						// hmacEncoded.set(wgt[hashedFieldName].length, String(iv).length);
						//
						// const resSignature = await crypto.subtle.verify({ "name": "HMAC" }, hmacKey, hmacEncoded, mac);
						//
						// console.log("resSignature::", resSignature);


						const resValue = await crypto.subtle.decrypt(algorithm, key, cypherText);
						decryptedField[unhashedFieldName] = (new TextDecoder()).decode(resValue);
					}
				}
			}
			decryptedFields.push(decryptedField);
		}
		return decryptedFields;
	}


	/**
	 * decodeUser
	 * takes a User object with encoded fields and returns a User object with decrypted fields (SingleUser Page)
	 * @param user
	 */
	public async decodeUser(user: Object) {
		if (!this.rsaPrivateCryptoKey) {
			throw ("HippoEncrypto:: Auth Error, RSA key not initialized, are you logged in?")
		}

		const userFields = Object.keys(user);
		const encodedFields = userFields.filter(word => !UNENCRYPTED_FIELD_NAMES.includes(word));

		if (!user["secure"] || !user["secure"]["encryptedStorageKey"] || !user["secure"]["encryptedStorageKey"]["key"]) {
			throw Error("HippoEncrypto:: User has no encryption key.");
		}

		// console.log("user['secure']:: ", user["secure"]);
		// console.log("user['secure']['encryptedStorageKey']:: ", user["secure"]["encryptedStorageKey"]);
		// console.log("user['secure]['encryptedStorageKey']['key']:: ", user["secure"]["encryptedStorageKey"]["key"]);

		const userKey = user["secure"]["encryptedStorageKey"]["key"];

		if (!userKey) {
			throw Error("HippoEncrypto:: User has no encryption key.");
		}

		const aesString = await this.rsaDecryptAESKey(userKey);

		const dataMap = new Map<string, string>();
		encodedFields.forEach(field => {
			dataMap.set(field, user[field]);
		})

		return await this.aesDecryptUserData(aesString, user);
	}

	/**
	 * decodeUserShallow
	 * takes a user and a separate key/iv pair and decrypts the top-level attributes (no sub-collections)
	 * @param user
	 * @param encryptedStorageKey
	 */
	public async decodeUserShallow(user: Object, encryptedStorageKey: string) {
		if (!this.rsaPrivateCryptoKey) {
			throw Error("HippoEncrypto:: Auth Error, RSA key not initialized, are you logged in?");
		}

		const userFields = Object.keys(user);
		const encodedFields = userFields.filter(word => !UNENCRYPTED_FIELD_NAMES.includes(word));

		const userKey = encryptedStorageKey;
		if (!userKey) {
			throw Error("HippoEncrypto:: User has no encryption key.");
		}

		const aesString = await this.rsaDecryptAESKey(userKey);

		const dataMap = new Map<string, string>();
		encodedFields.forEach(field => {
			dataMap.set(field, user[field]);
		})

		return await this.aesDecryptUserData(aesString, user);
	}

	/**
	 * decodeFields
	 * decrypt an array of objects (weights)
	 * @param data
	 * @param encryptedStorageKey
	 * @param unhashedFieldNames -
	 */
	public async decodeFields(data: Object[], encryptedStorageKey: string, unhashedFieldNames: string[]) {

		if (!this.rsaPrivateCryptoKey) {
			throw ("HippoEncrypto:: Auth Error, RSA key not initialized, are you logged in?")
		}

		const userFields = Object.keys(data);
		// filter out un-hashed field names
		const encodedFields = userFields.filter(word => !unhashedFieldNames.includes(word));

		const userKey = encryptedStorageKey;

		if (!userKey) {
			throw Error("HippoEncrypto:: User has no encryption key.");
		}

		const aesString = await this.rsaDecryptAESKey(userKey);

		return await this.decryptFields(data, aesString, unhashedFieldNames);
	}

	/**
	 * decodeMessage
	 * decrypt an array of message objects and the sender/recipient subcollections on that object
	 * @param data
	 * @param encryptedStorageKey
	 * @param unhashedFieldNames -
	 * @param unhashedSubFieldNames
	 */
	public async decodeMessages(data: Object[], encryptedStorageKey: string, unhashedFieldNames: string[],
							   unhashedSubFieldNames: string[]) {

		if (!this.rsaPrivateCryptoKey) {
			throw ("HippoEncrypto:: Auth Error, RSA key not initialized, are you logged in?")
		}

		const userKey = encryptedStorageKey;

		if (!userKey) {
			throw Error("HippoEncrypto:: User has no encryption key.");
		}

		const aesString = await this.rsaDecryptAESKey(userKey);

		return await this.decryptMessageFields(data, aesString, unhashedFieldNames, unhashedSubFieldNames);
	}

	// #### ENCRYPTION ####

	/**
	 * encryptMessage
	 * encrypt a message object to be stored in Firestore
	 * @param content
	 * @param keyString
	 * @param encryptedFields - the unhashed names of the fields to be hashed
	 * @param encryptedSubFields - the unhashed names of the subfields to be hashed
	 */
	public async encryptMessage(content: Object, keyString: string,
								encryptedFields: string[], encryptedSubFields: string[]) {
		const encoder = new TextEncoder();

		if (!this.rsaPrivateCryptoKey) {
			throw Error("HippoEncrypto:: Auth Error, RSA key not initialized, are you logged in?")
		}

		const aesString = await this.rsaDecryptAESKey(keyString);
		const [confidentialKey, integrityKey] = aesStringToKey(aesString);

		const aesKey = await crypto.subtle.importKey(
			"raw",
			confidentialKey,
			{
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);

		const hmacKey = await crypto.subtle.importKey(
			"raw",
			integrityKey,
			{
				name: "HMAC",
				hash: {name: "SHA-256"}
			},
			false,
			["sign", "verify"]
		);


		const encryptedItem = {};

		const hashedFields: string[] = [];
		for (const field of encryptedFields) {
			const name = await HippoEncrypto.getHashedFieldName(field);
			hashedFields.push(name);
		}

		const hashedSubFields: string[] = [];
		for (const field of encryptedSubFields) {
			const name = await HippoEncrypto.getHashedFieldName(field);
			hashedSubFields.push(name);
		}

		// set unencrypted values
		for (const key of Object.keys(content)) {
			if (!encryptedFields.includes(key)) {
				encryptedItem[key] = content[key];
			}
		}

		for (const fieldIndex in encryptedFields) {
			const hashedFieldName = hashedFields[fieldIndex];
			const unhashedFieldName = encryptedFields[fieldIndex];

			// encrypt sub fields / user objects
			if (content[unhashedFieldName] !== undefined) {
				if (typeof content[unhashedFieldName] === "object") {
					const resContent = {}

					// set unencrypted values
					for (const key of Object.keys(content[unhashedFieldName])) {
						if (!encryptedSubFields.includes(key)) {
							resContent[key] = content[unhashedFieldName][key];
						}
					}

					for (const [subFieldIndex, subFieldValue] of encryptedSubFields.entries()) {
						const hashedSubFieldName = hashedSubFields[subFieldIndex];
						const unhashedSubFieldName = encryptedSubFields[subFieldIndex];

						const iv = crypto.getRandomValues(new Uint8Array(16));
						const encoded = encoder.encode(content[unhashedFieldName][subFieldValue]);
						// console.log(`Encoding(${unhashedFieldName}, ${subFieldValue}): ${content[unhashedFieldName][subFieldValue]}`);
						// console.log("hashedsub::", hashedSubFieldName);

						const resValue = await crypto.subtle.encrypt({
							name: "AES-CBC",
							iv: iv
						}, aesKey, encoded);
						const encryptedBytes = new Uint8Array(resValue);

						// get text for key signature
						const hmacEncoded = new Uint8Array(iv.length + encryptedBytes.length);
						hmacEncoded.set(iv);
						hmacEncoded.set(encryptedBytes, iv.length);
						const resSignature = await crypto.subtle.sign({ "name": "HMAC" }, hmacKey, hmacEncoded);

						resContent[hashedSubFieldName] =
							`${arrayBufferToBase64(iv)}:${arrayBufferToBase64(resSignature)}:${arrayBufferToBase64(resValue)}`;
					}
					encryptedItem[hashedFieldName] = resContent;

				} else {
					const iv = crypto.getRandomValues(new Uint8Array(16));
					const encoded = encoder.encode(content[unhashedFieldName]);
					// console.log(`Encoding(${unhashedFieldName}): ${content[unhashedFieldName]}`);

					const resValue = await crypto.subtle.encrypt({
						name: "AES-CBC",
						iv: iv
					}, aesKey, encoded);
					const encryptedBytes = new Uint8Array(resValue);

					// get text for key signature
					const hmacEncoded = new Uint8Array(iv.length + encryptedBytes.length);
					hmacEncoded.set(iv);
					hmacEncoded.set(encryptedBytes, iv.length);
					const resSignature = await crypto.subtle.sign({ "name": "HMAC" }, hmacKey, hmacEncoded);

					encryptedItem[hashedFieldName] =
						`${arrayBufferToBase64(iv)}:${arrayBufferToBase64(resSignature)}:${arrayBufferToBase64(resValue)}`;
				}
			}
		}

		// console.log("encrypted::", encryptedItem);
		return encryptedItem;
	}
}
