import React, { CSSProperties, useEffect, useState } from "react";
import { IAdherence } from "../../api/models/adherence";
import { IUser } from "../../api/models/user";
import { api } from "../../api/prep";
import AdherenceCard from "../../components/Cards/AdherenceCard/AdherenceCard";
import { UserState } from "../../store/reducers/userReducer";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { OrNull } from "../../typings";
import "./index.scss";
import { selectedUserSetAction } from "../../store/actions";
import TotalAdherence from "../../components/TotalAdherence/TotalAdherence";
import Card from "react-bootstrap/Card";
import i18n from "../../i18n/config";

interface IAdherenceProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

const Adherence: React.ComponentType<IAdherenceProps> = (props: IAdherenceProps): JSX.Element => { // eslint-disable-line no-undef
	const { userId } = useParams();
	const [user, setUser] = useState<OrNull<IUser>>(props.user);
	const [chartData, setChartData] = useState<IAdherence[]>([]);
	const [weeksAdherence, setWeeksAdherence] = useState<number>(0);

	useEffect(() => {
		// User might be null if user directly navigated to this url. Query user if that happens.
		if (!props.user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				if (usr) {
					api.getAdherence(usr.id).then(adherence => {
						setChartData(adherence);
					});
					api.generateWeeksAdherence(usr.id, new Date()).then(adherence => {
						setWeeksAdherence(adherence);
					});
				}
			});
		} else {
			api.getAdherence(props.user.id).then(adherence => {
				setChartData(adherence);
			});
			api.generateWeeksAdherence(props.user.id, new Date()).then(adherence => {
				setWeeksAdherence(adherence);
			});
		}
	}, []); // eslint-disable-line

	return(
		<div className="adherenceStyles">
				<AdherenceCard adherence={chartData} user={props.user} />
				<Card className="adherence">
					<Card.Title>
						<h5>{i18n.t("Total Adherence")}</h5>
					</Card.Title>
					<Card.Body>
						<TotalAdherence adherence={weeksAdherence * 100} width="136px" fontSize="29px"/>
					</Card.Body>
				</Card>
		</div>
	)
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Adherence);