import React, { CSSProperties, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Spinner, Col, Form, Button, ProgressBar, Modal} from "react-bootstrap";

import { connect } from "react-redux";
import { DocumentData } from "@firebase/firestore-types";
import { PublicUserState } from "../../../store/reducers/publicUserReducer";
import { handleFormInputChange } from "../../../utils/form";
import { api } from "../../../api/prep";
import { IUser } from "../../../api/models/user";
import i18n from "../../../i18n/config";
import "./index.scss";
import { IClinic } from "../../../api/models/clinics";
import { createMessageUserEntity } from "../../../utils";
import { OrNull } from "../../../typings";
import LinearProgressBar from "../../ProgressBar/LinearProgressBar";
import SpinnerButton from "../../Buttons/SpinnerButton";



interface IClinicMessagesProps {
	publicUser: PublicUserState
    show: boolean
	onHide: () => void
}

const spinnerStyles = {
	marginLeft: "24px",
    marginRight: "24px",
}

const ManageClinicMessages: React.ComponentType<IClinicMessagesProps> = (props: IClinicMessagesProps): JSX.Element => {
    const [users, setUsers] = useState<any[]>([]);
	const [encodedUsers, setEncodedUsers] = useState<[DocumentData, string][]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [progressBar, setProgressBar] = useState<boolean>(false);
    const [clinics, setClinics] = useState<any[]>([]);
    const [currCategory, setCurrCategory] = useState("Male");
    const [currClinic, setCurrClinic] = useState("");
    const [message, setMessage] = useState("");
    const [categorySubmit, setCategorySubmit] = useState<boolean>(false);
    const [chosenClinicUsers, setChosenClinicUsers] = useState<any[]>([]);
    const [adminName, setAdminName] = useState("");
    const [now, setNow] = useState<number>(0);
    const [chosenUsersEntities, setChosenUsersEntities] = useState<any[]>([]);
    const [messageUserEntities, setMessageUserEntities] = useState<any[]>([]);
    const [encMessages, setEncMessages] = useState<any[]>([]);
    const [error_, setError_] = useState<OrNull<string>>(null);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const handleCategoryInput = handleFormInputChange(setCurrCategory);
    const handleClinicInput = handleFormInputChange(setCurrClinic);
    const handleMessageInput = handleFormInputChange(setMessage);



    useEffect(() =>  {
        setLoading(true);
        let clinicUsers: any[]=[];

        api.getAllEncodedUsers(props.publicUser?.id)
			.then((items: [DocumentData, string][]) => {
				if(props.publicUser?.role === "clinic_admin"){
					// Show only patients with the clinic_id from the clinic_admin
					for(let i = 0; i < items.length; i++){
						if(props.publicUser.clinicID && items[i][0].clinicID === props.publicUser?.clinicID  && !(items[i][0].deletedAt) && items[i][0].enabled !== false){
							clinicUsers.push(items[i])
						}
					}
				}else{
					//Show all patients for the super_admin
					for(let i = 0; i < items.length; i++){
						if(!(items[i][0].deletedAt) && items[i][0].enabled !== false){
							clinicUsers.push(items[i])
                            // setNow(now+i);

						}
					}
				}

    
                setEncodedUsers(clinicUsers);
                setNow(clinicUsers.length);

                api.decodeUsers(clinicUsers).then((items2: IUser[]) => {
					setUsers(items2);
					setLoading(false);
                    let name;

                    for(let i = 0; i < items2.length; i++){
                        if(items2[i].id === props.publicUser?.id){
                            if(items2[i].firstName){
                                name = items2[i].firstName
                            }
                        }
                    }


				});


            }).catch((error) => {
				console.error("Error getting users ", error);
			});

            if(props.publicUser?.role === "super_admin" || props.publicUser?.role === "clinic_admin"){
                let chosenClinic: any[]=[];
                api.getClinics()
                    .then((currClinics: IClinic[])=>{
                        for(let i = 0; i < currClinics.length; i++){
                            if(currClinics[i].disabled !== true){
                                chosenClinic.push(currClinics[i]);
                            }
                        }
                        setClinics(chosenClinic)
                        setCurrClinic(chosenClinic[0].id)

                    }).catch((error) => {
				        console.error(i18n.t("Error getting clinics "), error);
			});
            }
            

            if(props.publicUser?.id){
                api.getUser(props.publicUser?.id).then((adminUser: IUser)=>{
                    if(adminUser.firstName && adminUser.lastName){
                        let fullName = adminUser.firstName + " " + adminUser.lastName;
                        setAdminName(fullName);
    
                    }
            });
        }

	}, []);

    const onSubmit = () => {
        setCategorySubmit(true);
        setProgressBar(true);
        let clinicUser: any[] = [];
        for(let i = 0; i < users.length; i++){
            if(users[i].clinicID === currClinic){
                clinicUser.push(users[i]);
            }
        }
        setChosenClinicUsers(clinicUser);
        setProgressBar(false);  

	}

    const onSend = async () => {
        setLoadingButton(true);
        let clinicName = "";

        if(props.publicUser?.role === "super_admin"){
            for(let i = 0; i < chosenClinicUsers.length; i++){
                chosenUsersEntities.push([chosenClinicUsers[i], chosenClinicUsers[i].id]);
            }
        } else {
            for(let i = 0; i < users.length; i++){
                chosenUsersEntities.push([users[i], users[i].id]);
            }
        }

        if(props.publicUser?.id){
            if(props.publicUser.role === "super_admin"){
                for(let i = 0; i < clinics.length; i++){
                    if(clinics[i].id === currClinic){
                        clinicName = clinics[i].name
                    }
                }

                const pubUser = {
                    id: props.publicUser.id || "",
                    displayName: props.publicUser.displayName || "Admin",
                    profileUrl: props.publicUser.profileUrl || "",
                }
    
                for(let i = 0; i < chosenUsersEntities.length; i++){
                    const thread = createMessageUserEntity(chosenUsersEntities[i][0], chosenUsersEntities[i][1]);
                    messageUserEntities.push(thread)
                }
    
                for(let i = 0; i < messageUserEntities.length; i++){
                    const enc = await api.encryptMessage(messageUserEntities[i], pubUser, message, "text");
                    encMessages.push(enc);
    
                }

                api.clinicMessage(props.publicUser.id, adminName, encMessages, message, currClinic, clinicName);
                props.onHide();
                setTimeout(() => {setLoadingButton(false);props.onHide();}, 1000) //To emulate a request promise time
            } else {
                for(let i = 0; i < clinics.length; i++){
                    if(clinics[i].id === props.publicUser.clinicID){
                        clinicName = clinics[i].name
                    }
                }

                const pubUser = {
                    id: props.publicUser.id || "",
                    displayName: props.publicUser.displayName || "Admin",
                    profileUrl: props.publicUser.profileUrl || "",
                }
    
                for(let i = 0; i < chosenUsersEntities.length; i++){
                    const thread = createMessageUserEntity(chosenUsersEntities[i][0], chosenUsersEntities[i][1]);
                    messageUserEntities.push(thread)
                }
    
                for(let i = 0; i < messageUserEntities.length; i++){
                    const enc = await api.encryptMessage(messageUserEntities[i], pubUser, message, "text");
                    encMessages.push(enc);
    
                }
                    api.clinicMessage(props.publicUser.id, adminName, encMessages, message, props.publicUser.clinicID, clinicName);
                    props.onHide();
                    setTimeout(() => {setLoadingButton(false);props.onHide();}, 1000) //To emulate a request promise time
            }
        }

	}

    return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal">
				<Form >
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
                        {i18n.t(loading ? "Preparing Clinics for Clinic Notification" : "Send Clinic Notification")}
						</Modal.Title>
					</Modal.Header>
                    <div hidden={!loading} style={spinnerStyles}>

                            <span ><LinearProgressBar progress={now} label={i18n.t("Loading patients...")}/></span>
                        </div>
                    {loading === false && props.publicUser?.role === "super_admin" &&
					<Modal.Body style={{width: "400px", marginLeft: "32px", marginBottom: "24px"}}>
                        <div className="modal-wrapper" hidden={categorySubmit === true}>
                                <Form.Group>
                                    <Form.Label> {i18n.t("Select Clinic")} </Form.Label>
                                    <Form.Control as={"select"} id="nameInput" onChange={handleClinicInput} value={currClinic}>
                                            {clinics.map((clinic: IClinic, i: number) => (
                                                <option key={`clinic-${i}`} value={clinic.id}>{clinic.name}</option>
                                             ))}
                                    </Form.Control>
                                </Form.Group>
                        </div>

                        <div hidden={categorySubmit === true}><Button onClick={onSubmit}>{i18n.t("Filter")}</Button></div>
                    

                    
                         {categorySubmit === true && loading === false && progressBar === false &&
                 <Modal.Body style={{width: "370px", marginBottom: "24px", marginTop: "16px", marginRight: "40px"}}>
                        <div style={{borderBottom: "0.05rem solid gray"}}>
                                <Form.Group style={{marginBottom: "0"}}>
                                    <Form.Label> {i18n.t("Total selected users") + `(${chosenClinicUsers.length}): `}</Form.Label>
                                    <Form.Group className="containerScrollable"
                                        >                           
                                        {chosenClinicUsers.map((usr, index) => { 
                                            if (usr) {
                                             return (
                                                <>
                                                    <Row>
                                                        <Form.Label>
                                                            {usr.firstName} {usr.lastName}
                                                        </Form.Label>
                                                    </Row>
                                                </>
                                             );
                                            }

                                            return <div key={index}> </div>;
                                         })
                                        }
                                    </Form.Group> 
                                </Form.Group>
                        </div>
                    <div className="modal-wrapper">
                                <Form.Group style={{marginTop: "16px"}}>
                                    <Form.Label> {i18n.t("Message: ")} </Form.Label>
                                    <Form.Control as={"textarea"} maxLength={500} style={{height: "90px"}} onChange={handleMessageInput}>
                                    </Form.Control>
                                </Form.Group>
                        </div>
                        <SpinnerButton onClick={onSend} message={message} patients={users.length} loading={loadingButton} text={i18n.t("Send")} loadingText={i18n.t("Sending...")}/>   
                        
                </Modal.Body>
            }
					</Modal.Body>
                    }

     {loading === false && props.publicUser?.role === "clinic_admin" && progressBar === false &&
                 <Modal.Body style={{width: "370px", marginBottom: "24px", marginTop: "16px", marginRight: "40px", marginLeft: "40px"}}>
                        <div style={{borderBottom: "0.05rem solid gray"}}>
                                <Form.Group style={{marginBottom: "0"}}>
                                    <Form.Label> {i18n.t("Total selected users") + `(${users.length}): `}</Form.Label>
                                    <Form.Group className="containerScrollable"
                                        >                           
                                        {users.map((usr, index) => { 
                                            if (usr) {
                                             return (
                                                <>
                                                    <Row>
                                                        <Form.Label>
                                                            {usr.firstName} {usr.lastName}
                                                        </Form.Label>
                                                    </Row>
                                                </>
                                             );
                                            }

                                            return <div key={index}> </div>;
                                         })
                                        }
                                    </Form.Group> 
                                </Form.Group>
                        </div>
                    <div className="modal-wrapper">
                                <Form.Group style={{marginTop: "16px"}}>
                                    <Form.Label> {i18n.t("Message: ")} </Form.Label>
                                    <Form.Control as={"textarea"} style={{height: "90px"}} onChange={handleMessageInput}>
                                    </Form.Control>
                                </Form.Group>
                        </div>
                        <SpinnerButton onClick={onSend} message={message} loading={loadingButton} text={i18n.t("Send")} loadingText={i18n.t("Sending...")}/>   
                </Modal.Body>
            }                    
					<Modal.Footer>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

const mapStateToProps = ({ publicUser }) => ({ publicUser });
export default connect(mapStateToProps, {})(ManageClinicMessages);