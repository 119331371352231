import { Timestamp } from "@firebase/firestore-types";
import { IReminder } from "../../feedGenerator";

export interface ILifestyleMessage {
    message: string
    type: string
}

export interface ILifestyleReminder {
    id: string
    type: string
    messages: ILifestyleMessage[]
    schedule: string
    createdAt: Timestamp
    updatedAt: Timestamp
}

export const isLifestyleReminder = (reminder: IReminder): reminder is ILifestyleReminder  => {
    return reminder.type === "lifestyle";
}