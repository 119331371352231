import React, {useEffect, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import "./styles/main.scss";
import BaseRoutes from "./router";
import  ILangState  from "./store/reducers/langReducer";
import {persistor, store} from "./store";
import { hookstate, useHookstate, useState as useGlobalState } from "@hookstate/core";
import globalState from "./utils/globalState";
import {hydrate, render} from "react-dom";
import i18n from "i18next";


export type AppComponent = React.ComponentType<any>;


// export const globalState = hookstate(0);

function App() {

	// const [storeState, setStoreState] = useState<any>(store.getState());
	// const global = useGlobalState(globalState);
	// const {lang} = global.get();
	// const [storeState, setStoreState] = useState<any>("en");

	// const [test,setTest] = useState<string>("en");




		// useEffect(() =>  {
		// 		// setStoreState("es");
		// 		console.log(lang + " from app");
		// 		i18n.changeLanguage(lang).then();
		//
		// 		setTest(lang);
		//
		// }, [lang]);


	return (
    <div className="App">
      <Router >
				<BaseRoutes />
			</Router>
    </div>
  );
}

export default App;

