import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./index.scss";

interface TotalAdherenceProps {
    adherence: number | undefined
    width: string,
    fontSize: string
}

const AdherenceCircleGraph: React.ComponentType<TotalAdherenceProps> = (props: TotalAdherenceProps): JSX.Element => {
    const [totalAdherence, setTotalAdherence] = useState<number>(0);
    const [color, setColor] = useState<string>("");

    useEffect(() => {
        if (props.adherence !== undefined) {
            setTotalAdherence(props.adherence);
            if(props.adherence < 26) {
                setColor("red");
            } else if (props.adherence < 76) {
                setColor("yellow");
            } else {
                setColor("green");
            }
        } else {
            setTotalAdherence(0);
        }
    }, [props]);

    return (
        <div className="totalAdherenceContainer" style={{ width: props.width }}>
            <CircularProgressbarWithChildren value={totalAdherence} text={`${Math.round(totalAdherence)}%`} styles={buildStyles({ textSize: `${props.fontSize}` })} strokeWidth={12} className={`CircularProgressbarWithChildren ${color}`} />
        </div>
    )
}

export default AdherenceCircleGraph;