import React, { ComponentType, CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import { IUpdate } from "../../../api/models/updates";
import icon from "../../../assets/default_icon.svg";
import audio from "../../../assets/ic_audio.svg";
import edit from "../../../assets/ic_edit.svg";
import image from "../../../assets/ic_image.svg";
import link from "../../../assets/ic_link.svg";
import text from "../../../assets/ic_text.svg";
import trash from "../../../assets/ic_trash.svg";
import DeleteResource from "../../Modals/DeleteResource/DeleteResource";
import UploadResource from "../../Modals/UploadResource/UploadResource";
import "./index.scss";
import i18n from "../../../i18n/config";

interface IUpdateCardProps {
	update: IUpdate,
	onUpdate: () => void,
	edit?: any
}

const imageStyle: CSSProperties = {
	maxWidth: "inherit",
}

const UpdateCard: ComponentType<IUpdateCardProps> = (props) => {

	const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalType, setModalType] = React.useState("");

	const getContent = (content: IUpdate) => {
		const tag = content.tags && content.tags.length ? content.tags.join(", ") : "";

		switch (content.type) {
			case "text": {
				return <>
					<h4>{i18n.t("Body")}</h4>
					<p>{content.content}</p>
					<h4>{i18n.t("Description")}</h4>
					<p>{content.description}</p>
				</>
			}
			case "photo": {
				return <>
					<img src={content.content} alt={content.content}/>
					<p className="p-top">{content.description}</p>
				</>
			}
			case "audio": {
				return <>
					<audio controls>
						<source src={content.content} type="audio/ogg"/>
						<source src={content.content} style={imageStyle} type="audio/mpeg"/>
						{i18n.t("Your browser does not support this content.")}
					</audio>
					<p className="p-top">{content.description}</p>
				</>
			}
			case "video": {
				return <>
					<video style={{width: "100%"}} controls>
						<source src={content.content} type="video/mp4" />
						<source src={content.content} type="video/ogg"/>
						{i18n.t("Your browser does not support this content.")}
					</video>
					<p className="p-top">{content.description}</p>
				</>
			}
			case "link": {
				return <>
					<p><a href={content.content}>{content.content}</a></p>
				</>
			}
			default: {
				return <p>{content.content}</p>;
			}
		}
	}

	const getIcon = (content: IUpdate) => {
		switch (content.type) {
			case "text": {
				return text;
			}
			case "photo": {
				return image;
			}
			case "audio": {
				return audio;
			}
			case "video": {
				return image;
			}
			case "link": {
				return link;
			}
			default: {
				return text;
			}
		}
	};

	// TODO: This needs to list the events off the user
	return (
		<>
		<Card className="update" body={true}>
			<Card.Header style={{background: `url(${props.update.image ? props.update.image : image}) no-repeat center center` }}>
				<Row>
					<Col xs={"auto"}><ReactSVG src={getIcon(props.update)}/></Col>
				</Row>
				<Row>
					<Col><Card.Title><h5>{props.update.title}</h5></Card.Title></Col>
				</Row>
			</Card.Header>
			<Row className="update-row">
				<Col className="align-self-center update-content">
					{getContent(props.update)}
				</Col>
			</Row>
			<Row className="crud">
				<ReactSVG src={edit} onClick={() => {
					setModalShow(true)
				}}/>
				<ReactSVG src={trash} onClick={() => setModalDeleteShow(true)} />
			</Row>
		</Card>

    	<UploadResource type={props.update.type} show={modalShow} onHide={() => { setModalShow(false); props.onUpdate() }} edit={props.update} />
		<DeleteResource show={modalDeleteShow} onHide={() => { setModalDeleteShow(false); props.onUpdate() }} resource={props.update} />
		</>
	);
};

export default UpdateCard;