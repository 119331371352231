import React, {useEffect, useState} from "react";
import { ProgressBar } from "react-bootstrap";

interface LinearProgressBarProps {
	progress: number,
    label?: string
}

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({progress, label}): JSX.Element => { // eslint-disable-line no-undef
	
    const [computedLabel, setComputedLabel] = useState<string>("");

    function getComputedLabel(progressNumber: number){
        if(label){
            return `${label} ${progressNumber.toFixed(2)}%`
        } else {
            return `${progressNumber.toFixed(2)}%`
        }
    }

    useEffect(() =>  {
        setComputedLabel(getComputedLabel(progress))
    },[progress])

	return (
		<ProgressBar now={progress} label={computedLabel}/>
	);
};

export default LinearProgressBar;