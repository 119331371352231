// platform-cryptor.tsx

const AES_BLOCK_SIZE = 64;
const AES_IV_SIZE = 16;

let iv: Uint8Array;

export const stringToBuffer = (s: string) => {
	const enc = new TextEncoder();
	return enc.encode(s);
};

/**
 * Convert string to an ArrayBuffer.
 * @param {string} str
 * @returns {ArrayBuffer}
 */
export const stringToArrayBuffer = (str: string): ArrayBuffer => {
	const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
	const bufView = new Uint16Array(buf);
	for (let i = 0, strLen = str.length; i < strLen; i++) {
		bufView[i] = str.charCodeAt(i);
	}

	return buf;
};

/**
 * Convert array buffer to utf-8 string.
 * @param {ArrayBuffer} buf
 * @returns {string}
 */
export const arrayBufferToString = (buf: ArrayBuffer): string => {
	// @ts-ignore
	return String.fromCharCode.apply(null, new Uint8Array(buf));
};

/**
 * Decode a base64 string to an ArrayBuffer.
 * @param {string} base64
 * @returns {ArrayBuffer}
 */
export const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
	const binary_string = atob(base64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}

	return bytes.buffer;
};

/**
 * Base64 encode an array buffer.
 * @param {ArrayBuffer} buffer
 * @returns {string}
 */
export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
	let binary = "";
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}

	return btoa(binary);
};

// uses RSA-OAEP encryption
// fixme? currently this does not specify PKCS1_v1_5 padding while HB asks for it specifically, fix if this causes issues
export const rsaEncrypt = (publicKey: CryptoKey, s: string) => {
	const message = stringToBuffer(s);
	return crypto.subtle.encrypt({ name: "RSA-OAEP" }, publicKey, message);
};

// uses aes-cbc
export const aesDecrypt = (key: CryptoKey, cipherText: ArrayBuffer) => {
	return crypto.subtle.decrypt({
			name: "AES-GCM",
			iv: iv
		},
		key,
		cipherText
	);
};

// uses aes-cbc
// fixme? currently this does not specify PKCS1_v1_5 padding while HB asks for it specifically, fix if this causes issues
export const aesEncrypt = (key: CryptoKey, rawText: string) => {
	const message = stringToBuffer(rawText);
	iv = crypto.getRandomValues(new Uint8Array(AES_IV_SIZE));
	return crypto.subtle.encrypt(
		{
			name: "AES-GCM",
			iv
		},
		key,
		message
	);
};

