import { Timestamp } from "@firebase/firestore-types";

// Note AKA ProviderNote
export interface INote {
	id: string
	createdAt: Timestamp
	updatedAt: Timestamp

	// encrypted
	author: string
	text: string
}

export interface INoteWithKey {
	type: string
	key: Date
	updatedAt: Date
	author: string
	body: string
}

export const mapAsINoteWithDate = (notes: INote[]): INoteWithKey[] => {
    return notes?.map((n: INote) => {
        return { type: "providerNote", key: n.createdAt.toDate(), updatedAt: n.updatedAt.toDate(), author: n.author, body: n.text  };
    });
}

export const areNotes = (responses: any): responses is INoteWithKey[]  => {
    let isNote: boolean = false;
    responses.forEach((n: any) => {
        if("body" in n) {
            isNote = true;
        }
    });

    return isNote;
}