import { Timestamp } from "@firebase/firestore-types";
import { IReminder } from "../reminder";

export interface IAppointmentReminder {
    id: string
    type: string
    scheduledAt: Timestamp
    createdAt: Timestamp
    updatedAt: Timestamp

    // Encrypted Fields
    doctorName: string
    notes: string
}

export const isAppointmentReminder = (reminder: IReminder): reminder is IAppointmentReminder  => {
    return reminder.type === "appointment";
}