import React, { ComponentType, CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ReactSVG } from "react-svg";
import { INoteWithKey } from "../../../api/models/note";
import note from "../../../assets/ic_note.svg";
import { getNotesCardDateTime } from "../../../utils";
import "./index.scss";

interface INotesCardProps {
	note: INoteWithKey
}

const imageStyle: CSSProperties = {
	maxWidth: "inherit",

}

const NotesCard: ComponentType<INotesCardProps> = (props) => {

	const date = props.note.key;

	return (
		<>
			<Card id="notes" className="displayNotesCardContainer" body={true}>
				<Row>
					<Col xs={"auto"}>
						<Row>
							<ReactSVG src={note} className="note"/>
						</Row>
					</Col>
					<Col className="align-self-center update-content note-box">
						<Row className="wrap-r">
							<Col lg={8} className="col-sm-8 au"><h5>{props.note.author}</h5></Col>
							<Col lg={4} className="col-sm-4 dt"><h6>{getNotesCardDateTime(date)}</h6></Col>
						</Row>
						<Row>
							<p>{props.note.body}</p>
						</Row>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default NotesCard;