import React, { useEffect, useState, CSSProperties } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Spinner} from "react-bootstrap";
import appointment from "../../assets/ic_appointment.svg";
import { api } from "../../api/prep";
import "./index.scss";
import i18n from "../../i18n/config";
import { connect } from "react-redux";
import ManageAppointments from "../../components/Modals/ManageAppointments/ManageAppointments";
import { IReminderNotification, isReminderNotification } from "../../api/models/reminders/reminderNotification";
import { ReactSVG } from "react-svg";
import { getFeedDateTime, getNotesCardDateTime } from "../../utils/index";
import { IUser } from "../../api/models/user";
import { INoteWithKey } from "../../api/models/note";
import { UserState } from "../../store/reducers/userReducer";
import { useParams } from "react-router-dom";
import { OrNull } from "../../typings";
import { selectedUserSetAction } from "../../store/actions";

export type IFeedReminder = IReminderNotification;

interface IAppointmentProps {
	user: UserState,
	selectedUserSetAction: typeof selectedUserSetAction
}

// const userEngagementCardStyle: CSSProperties = {
// 	display: "flex",
// 	flex: 2,
//     backgroundColor: "white",
//     flexGrow: 1,
//     borderRadius: "16px",
//     flexBasis: "46%",
//     marginRight: "25%", 
// }

const Appointment: React.ComponentType<IAppointmentProps> = (props: IAppointmentProps): JSX.Element => {
    const [feed, setFeed] = useState<(INoteWithKey | IReminderNotification)[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<OrNull<IUser>>(props.user);
    const { userId } = useParams();

    const getData = (usr: IUser) => {
		api.generateFeed(usr.id).then(res => {
			setFeed(res);
		});
	}

    useEffect(() => {
		loadAppointments();
	}, []);

    const loadAppointments = () => {
        setLoading(true);

		// User might be null if user directly navigated to this url. Query user if that happens.
		if (!props.user) {
			api.getUser(userId).then((usr) => {
				setUser(usr);
				// Set the user in Redux too.
				props.selectedUserSetAction(usr);

				if (usr) {
					getData(usr);
					setLoading(false);
				}
			});
		} else {
			getData(props.user);
			setLoading(false);
		}
    };


    const getTitle = (t: IReminderNotification): string => {
		return i18n.t("Your appointment is coming up @") + getFeedDateTime(t.key);
	}

    const buildCard = (notification: IFeedReminder) => {
        if (notification.reminder.type === "appointment") {
            return (
            <div key={notification.reminder.id}>
                <Card id="userEngagement" body={true} className="userAppointmentCardStyle">
                    <Row>
                        <Col xs={"auto"}>
                            <Row>
                                <ReactSVG src={appointment} />
                            </Row>
                        </Col>
                        <Col className="align-self-center update-content notification-box">
                            <Row><h6>{getTitle(notification)}</h6></Row>
                            <Row><p>{getNotesCardDateTime(notification.key)}</p></Row>
                        </Col>
                    </Row>
                </Card>
            </div>
            );
        } else {
            return <div></div>
        }
        
    }

    return (
        <>
            
            <div className="feedStyles-notifications">
                <Card body={true} className="appointmentPageHeaderCardStyle">
                    <div className="modal-wrapper">
                        <div className="item">
                            <button className="btn btn-outline-primary rounded-sm" onClick={() => {
                                setModalShow(true);
                            }}>{i18n.t("Schedule Appointment")} </button>
                        </div>
                    </div>
                </Card>
                {loading && 
                <div id="userSpinner" hidden={!loading}>
                    <Spinner animation="border" role="status" />
                </div>
                }
                {feed.map((feedNotification, index) => {
                    if (isReminderNotification(feedNotification)) {
                        return (
                        <>
                            {buildCard(feedNotification)}
                        </>);
                        }
                        return <div key={index}> </div>;
                    })}
            </div>   
            { modalShow && <ManageAppointments userId={userId} show={modalShow} onHide={() => { setModalShow(false); loadAppointments();}}/> }
        </>
    );
}

// export default Groups;
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, {
	selectedUserSetAction
})(Appointment);


// const mapStateToProps = ({ publicUser }) => ({ publicUser });
// export default connect(mapStateToProps, {})(Appointment);


