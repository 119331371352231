import { Dispatch, FormEvent, SetStateAction } from "react";

export type StateSetter = Dispatch<SetStateAction<any>>;

/**
 * Wrapper for extracting input element values.
 * @param {Event} e
 * @returns {string | undefined}
 */
export const getInputVal = (e: FormEvent): string | undefined => {
	const target = e.target as HTMLInputElement;
	return target && target.value;
};

/**
 * Util function to handle form input changes.
 * @param {React.Dispatch<React.SetStateAction<string>>} updateFunc
 * @returns {(e: MementoEvent) => "" | void}
 */
export const handleFormInputChange = (updateFunc: StateSetter) => (e: any) => {
	const input: string | undefined = getInputVal(e);

	// Allow setting empty string for text inputs
	return (input || input === "") && updateFunc(input);
};

// take a comma delimited string and turn into a string array
export const tagify = (tags: string) => {
	const outArray: string [] = [];
	const tagsAndSpaces = tags.split(",");
	tagsAndSpaces.forEach(tag => {
		outArray.push(tag.trim())
	})
	return outArray;
}

export const toUint8Array = (file: File) => {
	file.arrayBuffer().then(buffer => {
		return new Uint8Array(buffer);
	}).catch(err => {
		console.error("Error encoding image::", err)
	})
	return new Uint8Array([])
}
