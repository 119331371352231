import { OrNull } from "../../typings";
import { IUrlRefSetAction } from "../actions";
import { URL_REF_SET } from "../actions/types";
import { createReducer } from "../index";


export type UrlRefState = OrNull<string>;
export const initialUrlRefState: string = "";

/**
 * Handle setting auth state.
 * @param {IAuthState} state
 * @param {IAuthPayload} action
 * @returns {IAuthState}
 */
const urlRefSetReducer = (state: UrlRefState, action: IUrlRefSetAction): UrlRefState => action.payload;

/**
 * Main reducer mapping.
 */
export default createReducer(initialUrlRefState, {
	[URL_REF_SET]: urlRefSetReducer
});