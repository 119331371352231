import React, { useState } from "react";
import { Button, Form, Spinner, Modal } from "react-bootstrap";
import {IUpdateClinic } from "../../../api/models/updates";
import { api } from "../../../api/prep";
import "./index.scss";
import { handleFormInputChange} from "../../../utils/form";
import { useEffect } from "react";
import i18n from "../../../i18n/config";
import { IClinic } from "../../../api/models/clinics";

interface ManageClinicProps {
	show: boolean
	onHide: () => void
	edit?: IUpdateClinic
	delete?: IUpdateClinic
	refClinicList?: IClinic[]
}

const ManageClinic: React.ComponentType<ManageClinicProps> = (props: ManageClinicProps): JSX.Element => {

	const [id, setId] = useState("")
	const [name, setName] = useState("");
	const [code, setCode] = useState("");

	const [errors, setErrors] = useState({
		name: "",
		code: ""
	});

	const [loading, setLoading] = useState(false);

	const handleNameInput = handleFormInputChange(setName);
	const handleCodeInput = handleFormInputChange(setCode);

	const verb = props.edit ? i18n.t("Edit"): props.delete ? i18n.t("Disable") : i18n.t("Create")

	const getTitle = (): string => {
		return (verb + " " + i18n.t("Clinic"))
	}

	useEffect(() => {
		if(props.delete){
			setId(props.delete.id || "")
		}else if(props.edit) {
			setId(props.edit.id || "");
			setName(props.edit.name);
			setCode(props.edit.code || "");
		}
	}, [props.delete, props.edit]);

	const validateFields = (): boolean => {
		const tempErrors = {
			name: "",
			code: ""
		}

		let isError = false;

		if(!name){
			tempErrors.name = i18n.t("Name is required");
			isError = true;
		}

		if(!code.match(/^[0-9]{6}/g)){
			tempErrors.code = i18n.t("Code must have 6 digits.");
			isError = true;
		}
		console.log("RefClinicList: ", props.refClinicList)
		if(props.refClinicList){
			const codeExist = props.refClinicList.find((clinic : IClinic)=>{
				return !props.edit ? clinic.code === code : clinic.code === code && clinic.id !== props.edit.id;
			})

			if(codeExist){
				tempErrors.code = i18n.t("Code already exist for another clinic.");
				isError = true;
			}
		}

		setErrors(tempErrors);
		return isError;
	}

	const onSubmit = () => {
		if(!props.delete){
			const isError = validateFields()
			if(!isError){
				setLoading(true);
				if(props.edit){
					api.editClinic(id, name, code).then(
						()=>{
							setLoading(false);
							props.onHide();
						}
					).catch(
						()=>{
							setLoading(false);
						}
					)
				}else{
					api.createClinic(name, code).then(
						()=>{
							setLoading(false);
							props.onHide();
					}).catch(
						()=>{
							setLoading(false);
						}
					)
				}
			}
		}else{
			api.disableClinic(id).then(
				()=>{
					setLoading(false);
					props.onHide();
				}
			)
		}
	}

	return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal">
				<Form >
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{getTitle()}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="discoverModal">
						{ !props.delete ?
							<>
								<Form.Group>
									<Form.Label> {i18n.t("Name")} </Form.Label>
									<Form.Control id="nameInput" defaultValue={props.edit ? props.edit.name : ""} onChange={handleNameInput}/>
									{ errors.name && <p className="error-message"> { errors.name } </p> }
								</Form.Group>
								<Form.Group>
									<Form.Label> {i18n.t("Code")} </Form.Label>
									<Form.Control id="codeInput" defaultValue={props.edit ? props.edit.code : ""} onChange={handleCodeInput}/>
									{ errors.code && <p className="error-message"> { errors.code } </p> }
								</Form.Group>
							</>
						 :  <p>{i18n.t("Are you sure you want to disable")} <b>{props.delete.name}</b>.</p>
						 }
							
					</Modal.Body>
					<Modal.Footer>
						{ loading ? <div id="resourceSpinner">
								<Spinner animation="border" role="status" />
							</div> : <>
								{ !props.delete ?
									<Button onClick={onSubmit}>{props.edit ? i18n.t("Save") : i18n.t("Create")}</Button>
									:
									<Button className="btn-danger" onClick={onSubmit}>{i18n.t("Disable")}</Button>
								}
								<Button onClick={props.onHide} className="cancelBtn">{i18n.t("Cancel")}</Button>
							</> }
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

export default ManageClinic;