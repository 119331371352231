export const UNENCRYPTED_FIELD_NAMES = [
    "badges",
    "devices",
    "reminders",
    "secure",
    "weights",

    "clinicID",
    "id",
    "isDeveloper",
    "photoUrl",
    "remindersEnabled",
    "updatedAt"
]

export const UNENCRYPTED_BADGE_NAMES = [
    "awardedAt"
]

export const UNENCRYPTED_WEIGHT_NAMES = [
    "updatedAt"
]
// For weights
export const UNENCRYPTED_MISC_NAMES = [
    "createdAt",
    "updatedAt"
]

export interface Badge {
    awardedAt: string
    key?: string
}

export interface Device {
    token: string
    platform: string
    timezone: string
    createdAt: string
    updatedAt: string
}

export interface Reminder {
    schedule?: string
    type: string | Object
    messages: Object // should be a List
    createdAt: Date
    updatedAt: Date
    stale: boolean
}

export interface Weight {
    createdAt: Date,
    weightInLbs?: number
}

export interface User {
    badges: Badge[],
    devices: Device[],
    reminders: Reminder[],
    secure: {
        admin: {
            isAdmin: boolean,
        }
        encryptedStorageKey: {
            key: string,
            question: string
        }
    },
    weights: Weight[],
    clinicID: string,
    id: string,
    isDeveloper: string,
    photoUrl: string | null,
    remindersEnabled: boolean,
    updatedAt: Date,

}

export interface EncodedUser extends User {
    "bQqLTOF60Fxp8t2F04u1"?: string,
    "6PF3qR3hE9zgmErEbMo9": string,
    "ulSejtstGzP1qwiq6tmh"?: number,
    "LcNGDTz66RpLLYm2/Jio": number,
    "L6ATzly0z320APokDdk6": number,
    "V1h+oKqKfR4+wCKpwGJr"?: boolean,
    "tLwAY8RYhvgX41eiJuVE"?: string,
    "LScJRHgF6e+be3c1cis/"?: string,
    "J8Ia4cbgHgyeFuGIJWGu"?: string,
    "XZY8+ieZEjm05jfdIPV+"?: string,
    "PlQvK/aTQgoXEHWQEwXE": string // [en/es]
}

export interface DecodedUser extends User {
    firstName?: string,
    lastName?: string, // shouldn't be optional
    age?: number,
    heightInCm?: number, // shouldn't be optional
    weightInKg?: number, // shouldn't be optional
    isMetric?: boolean,
    ethnicity?: string,
    race?: string,
    phone?: string,
    clinic?: string,
    gender?: string
	birthdate?: string
	email?: string
    locale?: string // [en/es] // shouldn't be optional
}
