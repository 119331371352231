import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Spinner, Col, Form, Button, ProgressBar, Modal, FormLabel} from "react-bootstrap";

import { connect } from "react-redux";
import { DocumentData } from "@firebase/firestore-types";
import { PublicUserState } from "../../../store/reducers/publicUserReducer";
import { handleFormInputChange } from "../../../utils/form";
import { api } from "../../../api/prep";
import { IUser } from "../../../api/models/user";
import i18n from "../../../i18n/config";
import "./index.scss";
import { UserState } from "../../../store/reducers/userReducer";



interface IAppointmentsProps {
    userId: string,
    show: boolean
	onHide: () => void
}

const spinnerStyles = {
	marginLeft: "24px",
    marginRight: "24px",
}

const ManageAppointments: React.ComponentType<IAppointmentsProps> = (props: IAppointmentsProps): JSX.Element => {
    const [user, setUser] = useState<any[]>([]);
    const [dateSelected, setDateSelected] = useState("");
    const [timeSelected, setTimeSelected] = useState("");
    const [medicName, setMedicName] = useState("");
    const [appointmentNotes, setAppointmentNotes] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState({
		date: "",
		time: "",
	});

    useEffect(() =>  {
        setLoading(true);

	}, []);

    const validateFields = (type: string): boolean => {
        // title and body are required, others not
        const tempErrors = {
            date: "",
            time: "",
        };
        let isError = false;

        if (!dateSelected) {
            tempErrors.date = i18n.t("Date is required");
            isError = true;
        } 

        if (!timeSelected) {
            tempErrors.time = i18n.t("Time is required");
            isError = true;
        }

        if (!isError) {
            tempErrors.date = "";
            tempErrors.time = "";
        }

        setErrors(tempErrors);
        return isError;
    };

    const onSend = () => {
        validateFields("");
        if (dateSelected && timeSelected) {
           api.createReminders(props.userId, dateSelected, timeSelected, medicName, appointmentNotes).then(
                ()=>{
                    setLoading(false);
                    props.onHide();
                }
            ); 
        }
	}

    return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal">
				<Form >
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
                            {i18n.t("Schedule Appointment")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>        
                        <Modal.Body>
                            {/* <DatePicker selected={dateSelected} onChange={(date:Date) => setDateSelected(date)} /> */}
                            <Form.Group>
                            <Row style={{marginBottom: "8px"}}>
                                    <Col style={{margin: "0"}}>
                                        <FormLabel style={{marginBottom: "0"}}>
                                            {i18n.t("Date")}
                                        </FormLabel>
                                    </Col>
                                    <Col style={{margin: "0"}}>
                                        <FormLabel style={{marginBottom: "0"}}>
                                            {i18n.t("Time")}
                                        </FormLabel>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control 
                                            type="date" 
                                            name='date_of_appointment'
                                            placeholder={i18n.t("Enter Appointment Date")}
                                            value={dateSelected}
                                            onChange={(value) => {
                                                // Clear if there's error
                                                let tempErrors = {
                                                    date: "",
                                                    time: errors.time,
                                                }
                                        
                                                setErrors(tempErrors);


                                                setDateSelected(value.target.value);
                                                console.log(value.target.value)
                                            }}
                                            />
                                            { errors.date && <p className="error-message"> { errors.date } </p> }
                                    </Col>
                                    <Col>
                                        <Form.Control 
                                            type="time" 
                                            name='time_of_appointment'
                                            placeholder={i18n.t("Enter Appointment Time")}
                                            value={timeSelected}
                                            onChange={(value) => {
                                                // Clear if there's error
                                                let tempErrors = {
                                                    date: errors.date,
                                                    time: "",
                                                }
                                        
                                                setErrors(tempErrors);
                                                setTimeSelected(value.target.value);
                                                console.log(value.target.value)
                                            }}
                                            />
                                            { errors.time && <p className="error-message"> { errors.time } </p> }
                                    </Col>  
                                </Row>
                                <div style={{marginBottom: "24px"}}></div>
                                <FormLabel>
                                    {i18n.t("Doctor's Name")}
                                </FormLabel>
                                <Row>
                                    <Col>
                                        <Form.Control 
                                            type="input" 
                                            name="name_of_medic" 
                                            placeholder={i18n.t("Enter Doctor's Name")}
                                            value={medicName}
                                            onChange={(value) => {
                                                setMedicName(value.target.value);
                                            }}
                                            />
                                    </Col>
                                </Row>
                                <div style={{marginBottom: "24px"}}></div>
                                <FormLabel>
                                    {i18n.t("Appointment Notes")}
                                </FormLabel>
                                <Row>
                                    <Col>
                                        <Form.Control 
                                            as="textarea" 
                                            name="appointment_note" 
                                            placeholder={i18n.t("Enter notes about this appointment")}
                                            value={appointmentNotes}
                                            onChange={(value) => {
                                                setAppointmentNotes(value.target.value);
                                            }}
                                            />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Button onClick={onSend}>{i18n.t("Schedule")}</Button>
                        </Modal.Body>
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

const mapStateToProps = ({ publicUser }) => ({ publicUser });
export default connect(mapStateToProps, {})(ManageAppointments);