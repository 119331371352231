import { action } from "typesafe-actions";
import { UrlRefState } from "../reducers/urlRefReducer";
import * as actionTypes from "./types";
import { IAuthState } from "../reducers/authReducer";
import { UserState } from "../reducers/userReducer";
import { PublicUserState } from "../reducers/publicUserReducer";
import {ILangState} from "../reducers/langReducer";
import i18n from "i18next";


/*                      Define TypeSafe Actions
 ===================================================================== */


export interface IActionBase {
	type: string;
}

export interface IAuthPayload extends IActionBase {
	payload: IAuthState;
}

export interface ILangPayload extends IActionBase {
	payload: ILangState;
}

export interface IUserSetAction extends IActionBase {
	payload: UserState;
}

export interface IPublicUserSetAction extends IActionBase {
	payload: PublicUserState;
}

export interface IUrlRefSetAction extends IActionBase {
	payload: UrlRefState;
}

/*          Auth
 ======================= */

export const authSet = (payload: IAuthState): IAuthPayload =>
	action(actionTypes.AUTH_SET, payload);

/*          Lang
 ======================= */

export const langSet = (payload: ILangState): ILangPayload => {

	console.log(payload.toString() + " from lang set");

	// let test = payload ? "" : ;

	i18n.changeLanguage(payload.toString()).then();

	return action(actionTypes.LANG_SET, payload);
}

/*          Url Ref
 ======================= */

export const urlRefSetAction = (payload: UrlRefState): IUrlRefSetAction =>
	action(actionTypes.URL_REF_SET, payload);

/*      User
 ======================= */

export const selectedUserSetAction = (payload: UserState): IUserSetAction =>
	action(actionTypes.SELECTED_USER_SET, payload);

export const publicUserEntitySetAction = (payload: PublicUserState): IPublicUserSetAction =>
	action(actionTypes.PUBLIC_USER_SET, payload);