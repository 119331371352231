import Chart from "chart.js/dist/chart";
import React, { ComponentType, CSSProperties, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { IAdherence } from "../../../api/models/adherence";
import { IUser } from "../../../api/models/user";
import { ListaColors } from "../../../styles/colors";
import { OrNull } from "../../../typings";
import { allTimeAdherence, chartColors, lastThreeWeeks, sliceChartData, sliceChartDates } from "../../../utils";
import i18n from "../../../i18n/config";

interface IProfileCardProps {
	// user_info: IUser[]
	user: OrNull<IUser>
	height?: string
    bigText?: boolean
}

const userEngagementCardStyle: CSSProperties = {
	display: "flex",
	flex: 1
}

const chartPagingStyle: CSSProperties = {
	display: "flex",
	flex: 1,
	justifyContent: "space-between"
}

const nameStyle: CSSProperties = {
	fontWeight: "bold",
    fontSize: 13
}

const spacingBottom: CSSProperties = {
    marginBottom: "12px"
}

const infoStyle: CSSProperties = {
	fontSize: 15,
    
}

const genderTranslation = (gender) => {
	if(gender === "Femenino" || gender === "Female" || gender === "Cis-Female" || gender === "Mujer Cis"){
		return i18n.t("Cis-Female");
	}

	if(gender === "Masculino" || gender === "Male" || gender === "Cis-Male" || gender === "Hombre Cis"){
		return i18n.t("Cis-Male");
	}

	if(gender === "Hombre Trans" || gender === "Transmale"){
		return i18n.t("Transmale");
	}

	if(gender === "Mujer Trans" || gender === "Transfemale"){
		return i18n.t("Transfemale");
	}

	if(gender === "No Binario" || gender === "Non-binary"){
		return i18n.t("Non-binary");
	} 

	if(gender === "Other" || gender === "Otro"){
		return i18n.t("Other");
	} 

	if(gender === "Prefer not to say" || gender === "Prefiero no decirlo"){
		return i18n.t("Prefer not to say");
	} 

	return "";
}


const ProfileCard: ComponentType<IProfileCardProps> = (props) => {

	// const canvasStyles: CSSProperties = {
	// 	textAlign: "initial",
    //     maxWidth: "1200px",
    //     display: "flex",
    //     width: "100%",
    //     margin: "auto auto -150px auto"
	// }

    const canvasStyles: CSSProperties = {
		position: "relative",
		width: "100%",
		height: props.height ?? "100%",
		overflow: "auto",
		marginBottom: -63
	}

    const bigText: CSSProperties = props.bigText? {
        fontSize: "28px",
        bottom: "50px"
        
    } : {fontSize: "18px"};

	// useEffect(() => {
		

	// 	console.log("User info: ", props.user)

		
	// }, [props.user]);

	// TODO: This needs to list the events off the user
	return (
		<Card id="userProfile" className={"shadow-sm"} body={true} style={userEngagementCardStyle}>
			<div style={spacingBottom}>
            {/* <span style={nameStyle}>Display name:  </span> */}
            <span style={{...bigText, marginRight: "8px", fontWeight: "bold"}}>{props.user?.firstName ? props.user?.firstName : i18n.t("Patient has not provided this data")}  {props.user?.lastName}</span>
			</div>
            <div style={spacingBottom}>
            <div><span style={nameStyle}>{i18n.t("Email")}  </span></div>
            <span style={infoStyle}>{props.user?.email ? props.user?.email : i18n.t("Patient has not provided this data")}</span>
			</div>
            <div style={spacingBottom}>
            <div><span style={nameStyle}>{i18n.t("Phone number")}  </span></div>
            <span style={infoStyle}>{props.user?.phone ? (props.user?.phone).substring(0,3).concat("-", (props.user?.phone).substring(3,6), "-", (props.user?.phone).substring(6)) : i18n.t("Patient has not provided this data")}</span>
			</div>
            <div style={spacingBottom}>
            <div><span style={nameStyle}>{i18n.t("Date of Birth")}  </span></div>
            <span style={infoStyle}>{props.user?.birthdate ? props.user?.birthdate : i18n.t("Patient has not provided this data")}</span>
			</div>
            <div style={spacingBottom}>
            <div><span style={nameStyle}>{i18n.t("Gender")} </span></div>
            <span style={infoStyle}>{props.user?.gender ? genderTranslation(props.user?.gender) : i18n.t("Patient has not provided this data")}</span>
			</div>
			<div style={canvasStyles}>
				<canvas id="userProfile" />
			</div>
		</Card>
	);
};

export default ProfileCard;