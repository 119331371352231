import React, { CSSProperties, useState } from "react";
import { Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { api } from "../../../api/prep";
import logo from "../../../assets/logo.png";
import app_logo from "../../../assets/app-logo.png";
import { OrNull } from "../../../typings";
import { getInputValue } from "../../../utils";
import { APP_NAME } from "../../../utils/globals";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import "./index.scss";
import i18n from "../../../i18n/config";


const cardChild: CSSProperties = {
	alignSelf: "center",
	marginLeft: "4rem",
	marginRight: "4rem",
	textAlign: "center"
};

const errorStyles: CSSProperties = {
	color: "red",
	fontSize: "12pt",
	textAlign: "center",
	marginTop: "24px",
	marginLeft: "24px",
	marginRight: "24px"
}

const ForgotPasswordCard: React.ComponentType = (): JSX.Element => { // eslint-disable-line no-undef
	const [userFacingMessage, setUserFacingMessage] = useState("");
	const [error, setError] = useState<OrNull<string>>(null);

	const handleSubmit = () => {
		const email = getInputValue("#forgotPasswordEmailField");

		setError(null);

		if (email) {
			api.forgotPassword(email)
				.then(() => {
					setUserFacingMessage(i18n.t("An email with instructions has been sent to") + email ?? "");
				}).catch((e) => {
					setError(e.message);
				});
		}
	};

	return (
		<>
			<Card body={true} className="loginCard">
				<img src={app_logo} alt="Logo" style={cardChild}/>
				<h2 style={cardChild}>{APP_NAME}</h2>

				<p style={cardChild}>{userFacingMessage}</p>

				<Form.Group className="loginField">
					<Form.Label>{i18n.t("Email address")}</Form.Label>
					<Form.Control id="forgotPasswordEmailField" type="email" placeholder={i18n.t("Enter email")} />
				</Form.Group>

				{error &&
					<p style={errorStyles}>{error}</p>
				}

				<PrimaryButton className="loginButton" onClick={handleSubmit}>
				{i18n.t("Submit")}
				</PrimaryButton>
			</Card>
		</>
	);

};

export default ForgotPasswordCard;